import React,{useState,useEffect} from 'react'

const TextArea = ({ name, label, error, ...rest }) => {

    useEffect(() => {
        error ? setShow(true) : setShow(false);
      }, [error]);
    
      const [show, setShow] = useState(false);
    
    return ( 
        <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <textarea name={name} {...rest} id={name} className="form-control"/>
        {show && (
          <div className="alert" style={{ marginTop: "5px" }}>
            {error}
          </div>
        )}
      </div>
     );
}
 
export default TextArea;