 
 //redondea numeros a decimales
 export const roundUp  = (number, decimals=2) => {
   
    if (String(number) === "NaN" || String(number) === 'Infinity'){
       
       return "0.00"
    }
    
    return ( 
        number.toFixed(decimals)
     );
}


