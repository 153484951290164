/**
 * AccountHistory is a component that displays the history of the schedule record.
 * @param {number} id - The id of the schedule record.
 * @author Arturo Perez
 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FaCheck } from "react-icons/fa";
import axiosClient from "../../../config/axios";

const STableCell = styled(TableCell)`
  background-color: #c3c3c3;
  font-weight: bold !important;
  font-size: 10px !important;
  padding: 10px !important;
`;
const STableRow = styled(TableRow)`
  & > td {
    font-size: 10px !important;
  }
  &:hover {
    background-color: #e6e6e6;
  }
`;
const AccountHistory = ({ id }) => {
  const [rows, setRows] = useState([]);

  /**
   * It fetches data from the API to get the history.
   */
  const fetchData = async () => {
    const data = await axiosClient.get(
      `agenda/gabinete/schedule_cabinet/?id=${id}`
    );
    setRows(data.data.results);
  };
  /* A hook that is called after every render. */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * It takes a string as an argument and returns a string
   * @returns the value of the key in the TYPE object.
   */
  const historyType = (type) => {
    const TYPE = {
      "+": "Agregada",
      "-": "Eliminada",
      "~": "Modificada",
    };
    return TYPE[type];
  };
  return (
    <TableContainer component={Paper} style={{ width: "100%" }}>
      <Table size="small" style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <STableCell align="center">Fecha Modificacion</STableCell>
            <STableCell align="center">Usuario</STableCell>
            <STableCell align="center">Estatus</STableCell>
            <STableCell align="center">Servicio</STableCell>
            <STableCell align="center">Accion</STableCell>
            <STableCell align="center">Folio</STableCell>
            <STableCell align="center">Fecha Modificacion</STableCell>
            <STableCell align="center">Usuario</STableCell>
            <STableCell align="center">Cuenta Verificada</STableCell>
            <STableCell align="center">Fecha Verificacion</STableCell>
            <STableCell align="center">Usuario</STableCell>
            <STableCell align="center">Categoria de Incidencia</STableCell>
            <STableCell align="center">Observaciones</STableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) =>
              row.history.map((item, index) => (
                <STableRow key={index}>
                  <TableCell align="center">
                    {item.history_date.split("T")[0]}
                  </TableCell>
                  <TableCell align="center">{item.username}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">{item.service_type}</TableCell>
                  <TableCell align="center">
                    {historyType(item.history_type)}
                  </TableCell>
                  <TableCell align="center">{item.folio_service}</TableCell>
                  <TableCell align="center">
                    {item.folio_assign_date
                      ? item.folio_assign_date.split("T")[0]
                      : ""}
                  </TableCell>
                  <TableCell align="center">{item.folio_username}</TableCell>
                  <TableCell align="center">
                    {item.is_verified_account ? <FaCheck /> : ""}
                  </TableCell>
                  <TableCell align="center">
                    {item.verified_account_date
                      ? item.verified_account_date.split("T")[0]
                      : ""}
                  </TableCell>
                  <TableCell align="center">{item.verified_username}</TableCell>
                  <TableCell align="center">
                    {item.observation_type_detail ? item.observation_type_detail : ""}
                  </TableCell>
                  <TableCell align="center">
                    {item.account_observation}
                  </TableCell>
                </STableRow>
              ))
            )
          ) : (
            <STableRow>
              <TableCell align="center" colSpan={12}>
                No se encontraron registros
              </TableCell>
            </STableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountHistory;
