import React, { useEffect, useState, useContext } from "react";
import PlanNutricional2Child from "./PlanNutricional2Child";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const PlanNutricional2 = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_plan_nutricional_parte2,
    setNotaPlanNutricionalParte2,
    nota_detalle
  } = notaState;

  const nutrimiento = ["Proteina", "Hidratos de carbono", "Lipidos"];

  const addFormula = (product) => {
    let exist = false;
    let products_temp = [];
    nota_plan_nutricional_parte2.forEach((item, index) => {
      if (product.product === item.product) {
        exist = true;
        products_temp = [...nota_plan_nutricional_parte2];
        products_temp[index] = product;
      }
    });

    if (exist) {
      setNotaPlanNutricionalParte2(products_temp);
    } else {
      setNotaPlanNutricionalParte2([...nota_plan_nutricional_parte2, product]);
    }
  };

  useEffect(() => {
    if (!nota_detalle && nota_plan_nutricional_parte2.length === 0) {
      let products = [];
      nutrimiento.forEach((item) => {
        let tmp = {
          product: item,
          kcal: null,
          gramos: null,
          gr_kg: null,
          porcentaje_vet: null,
        };
        products.push(tmp);
      });
      setNotaPlanNutricionalParte2(products);
    }
  }, [nota_detalle]);

  return (
    <div className="plan-nutricial-formulas">
      <h1>Plan Nutricional (Parte 2)</h1>
      <div>
        <div>
          <legend>Nutrimientos</legend>
          <legend>%VET *</legend>
          <legend>Kcal</legend>
          <legend>Gramos</legend>
          <legend>Gr/Kg</legend>
        </div>

        {nota_plan_nutricional_parte2.map((nutriment, index) => {
          return (
            <PlanNutricional2Child
              key={index}
              nutriment={nutriment}
              addFormula={addFormula}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PlanNutricional2;
