import React, {useContext, useEffect, useState} from 'react';
import Input from '../../common/Input';
import useSelect from '../../../hooks/useSelect';
import Button from '@mui/material/Button';
import {FormControl} from '@material-ui/core';
import { Grid } from '@mui/material';
import userCreationContext from '../../../context/users_creation/userCreationContext';
import {FaRegEyeSlash, FaRegEye} from 'react-icons/fa';

import {generatePassword} from '../../../utils/generators';
const Form = ({roles}) => {

    const userCreationState = useContext(userCreationContext);
    const {form_data,form_data_error,setFormData} = userCreationState;
    const [password_type, setPasswordType] = useState('password');
    const [password_icon, setPasswordIcon] = useState(<FaRegEye />);
    // eslint-disable-next-line
    const [select_state, SelectSearch] = useSelect(0);

    
    
    const handleChange = (e) => {
        setFormData({
            ...form_data,
            [e.target.name]: e.target.value
        });
    };
   
    const newPassword = () => {
        const new_password = generatePassword(8);
        setFormData({
            ...form_data,
            'password': new_password,
            'password_confirmation': new_password
        });
        setPasswordType('text');
        setPasswordIcon(<FaRegEye />);
    }


    useEffect(() => {
        setFormData({
            ...form_data,
            'group_permissions': select_state
        });
    },[select_state])

    const showPassword = () => {
        setPasswordType(password_type === 'password' ? 'text' : 'password');
        setPasswordIcon(password_type === 'password' ? <FaRegEyeSlash /> : <FaRegEye />);
    }

    useEffect(()=>{
        let extra_data = `${localStorage.getItem('extra_data')}`;
        try{
            extra_data = extra_data.replace(/'/g, "\"");
            const obj = JSON.parse(extra_data);
            const userRequestValue = obj.user_request;
            setFormData({
                ...form_data,
                'user_request': userRequestValue
            });
            localStorage.removeItem('extra_data');

        } catch (error) {
            console.error("Error", error)
        }
    }, [])

    return ( 
        <FormControl >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Input error={form_data_error.first_name} required name="first_name" label="Nombre*" onChange={handleChange} value={form_data.first_name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input error={form_data_error.middle_name} name="middle_name" label="Segundo Nombre" onChange={handleChange} value={form_data.middle_name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input error={form_data_error.first_last_name} required name="first_last_name" label="Apellido Paterno*" onChange={handleChange} value={form_data.first_last_name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input error={form_data_error.second_last_name} name="second_last_name" label="Apellido Materno" onChange={handleChange} value={form_data.second_last_name} />
                </Grid>
                <Grid item xs={12}>
                <Button onClick={() => newPassword()} >Generar Contraseña</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input 
                        action={()=>showPassword()}
                        icon={password_icon}
                        color={"#66aaff"}
                        error={form_data_error.password}
                        required 
                        type={password_type}
                        name="password"
                        label="Contraseña*"
                        value={form_data.password}
                        onChange={handleChange} 
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        action={()=>showPassword()}
                        icon={password_icon}
                        color={"#66aaff"}
                        error={form_data_error.password_confirmation}
                        required
                        type={password_type}
                        name="password_confirmation"
                        label="Confirmar Contraseña*"
                        value={form_data.password_confirmation}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                <SelectSearch 
                    error={form_data_error.group_permissions}
                    required
                    name="group_permissions"
                    label="Permisos*"
                    value={form_data.group_permissions}
                    id={'group_permissions'}
                    options={roles}
                />
                </Grid>
                <Grid item xs={12}>
                    <Input error={form_data_error.email} type="email" name="email" label="Correo Electrónico" onChange={handleChange} value={form_data.email} />
                </Grid>
            </Grid>
        </FormControl>
     );
}
 
export default Form;