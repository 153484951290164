import React, { useState } from "react";
import styled from "styled-components";
import Joi from "joi";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { device } from "../../common/ScreenSizes";

const AuthLayout = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  justify-items: center;
  background: #3b6ca6 !important;
`;

const AuthLayoutInner = styled.div`
  width: 500px;
  margin: auto;
  background: #ffffff;
  padding: 40px 50px 40px 50px;
  border-radius: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  ${device.max_tablet} {
    width: 500px;
    padding: 30px 40px 30px 40px;
  }

  ${device.max_mobileL} {
    width: 350px;
    padding: 10px 15px 10px 15px;
  }

  ${device.max_mobileM} {
    width: 300px;
    padding: 10px 15px 10px 15px;
  }

  ${device.max_mobileS} {
    width: 250px;
    padding: 10px 15px 10px 15px;
  }
`;
const AuthTitle = styled.h1`
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  font-size: 2rem;
  word-wrap: break-word;
  ${device.max_mobileL} {
    font-size: 2rem;
  }
  ${device.max_mobileM} {
    font-size: 1.75rem;
  }
  ${device.max_mobileS} {
    font-size: 1.5rem;
  }
`;
const Message = styled.p`
  margin-bottom: 10px;
  text-align: justify;
  font-weight: 600;
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: ["com"] } })
      .required()
      .label("Email"),
  });
  const doSubmit = () => {
    window.location.replace("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    if (errors) return;
    doSubmit();
  };

  const handleChange = ({ currentTarget: input }) => {
    const errorMessage = validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    setEmail(input.value);
  };

  const validate = () => {
    const options = { abortEarly: false };

    const forgotPasswordData = { email };
    const { error } = schema.validate(forgotPasswordData, options);

    if (!error) return null;
    console.log(error);

    const _errors = {};
    for (let index in error.details) {
      let item = error.details[index];
      _errors[item.path[0]] = item.message;
    }
    return _errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema_ = Joi.object({
      [name]: schema.extract(name),
    });
    const { error } = schema_.validate(obj);
    return error ? error.details[0].message : null;
  };
  return (
    <AuthLayout>
      <AuthLayoutInner>
        <AuthTitle>Olvidaste tu contraseña?</AuthTitle>
        <Message>
          Ingresa la direccion de correo asociada a tu cuenta y te enviaremos un
          correo con el link para cambiar la contraseña
        </Message>
        <form onSubmit={handleSubmit}>
          <Input
            autoFocus={true}
            name="email"
            label="Correo"
            placeholder="Correo electronico"
            type="email"
            value={email}
            onChange={handleChange}
            error={errors["email"]}
          />
          <Button label="Enviar" styleName="btn-main btn-block" />
        </form>
      </AuthLayoutInner>
    </AuthLayout>
  );
};

export default ForgotPassword;
