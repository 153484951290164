import React, {useContext, Fragment} from "react";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logoexcel from '../../../assets/grupo_excel_fondo_blanco.jpg';
import PdfCall from "./PdfCall";

//Styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 20,
        flexGrow: 1,
    },
    subsection: {
        flexGrow:2 ,
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Times-Roman',
    },
    text: {
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    text1: {
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        color: 'white',
        fontWeight: 'bold',
    },
    subtext:{
        fontSize: 11,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    subtext2:{
        marginLeft:40,
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    header:{
        padding:10,
        fontSize:12,
        textAling:'center',
        fontFamily: 'Times-Roman',
    },
    image: {
      marginVertical: 25,
      marginHorizontal: 200,
    },
    table:{
        backgroundColor: '#4682b4',
        border: 1,
        height: 23,
        fontSize: 13,
        textAlign: 'center',
        fontFamily: 'Times-Roman',
        color:'white',
        marginLeft: 40,
        width: 383,
    },
    content: {
        padding: 0,
        '@media max-width: 100': {
          flexDirection: 'column',
        },
        '@media min-width: 100': {
          flexDirection: 'row',
        },
    },
    block: {
        padding: 2,
        height: 20,
        width: 133,
        backgroundColor: '#00bfff',
        border: 0.8,
        marginLeft: 40,
    },
    block1: {
        padding: 2,
        height: 20,
        width: 250,
        backgroundColor: 'white',
        border: 0.8,
        marginRight:40,
    },
    block2: {
        height: 20,
        width: 383,
        marginRight:40,
        backgroundColor: '#4682b4',
        border: 1,
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Times-Roman',
        color:'white',
    },
    blockHeader:{
        height: 80,
        width: 106,
        border: 0.8,
        backgroundColor:'white',
        textAlign: 'center',
    },
    blockHeader1:{
        height: 80,
        width: 60,
        border: 0.8,
        backgroundColor: 'white',
    },
    headerblock: {
        height: 50,
        width: 150,
        backgroundColor: 'white',
        border: 0.8,
        fontSize: 12,
        textAlign: 'center',
     },
    headerblock1: {
        height: 30,
        width: 150,
        backgroundColor: 'white',
        border: 0.8,
        fontSize: 12,
        textAlign: 'center',
    },
    images:{
        width:75,
        height:40,
        margin:15,
    }
});

// Componente para el encabezado del PDF logo, nomenclatura
const HeadComponent = () => (
    <Document style={styles.content}>
        <View>
            <View style={styles.blockHeader} ><Image style={styles.images} src={logoexcel}/></View>
         </View>
        <View>
            <View style={styles.headerblock} ><Text style={{ width:'85%', margin:'auto' }}>Formato de Entrega de Cuenta en el Sistema Excel</Text></View>
            <View style={styles.headerblock1} ><Text style={{ width:'75%', margin:'auto' }}>Departamento de Desarrollo</Text></View>
        </View>
        <View>
            <View style={styles.headerblock} ><Text style={{ width:'90%', margin:'auto', fontSize:12}}>DES_FOR_FEC_MCI_  26022019</Text></View>
            <View style={styles.headerblock1} ><Text style={{ width:'75%', margin:'auto' }}>Versión 2</Text></View>
        </View>
        <View>
            <View style={styles.blockHeader1} ><Text style={{ margin:'auto', fontSize:12 }}>Página 1</Text></View>
        </View>
    </Document>
);

// Contenido de la tabla Datos de la cuenta
const MediaComponent = ({name, department, area, account, password}) => {
    return(
          <Document style={[styles.content]}>
            <View>
                <View style={styles.block} ><Text style={styles.text1}>Nombre:</Text></View>
                <View style={styles.block} ><Text style={styles.text1}>Departamento:</Text></View>
                <View style={styles.block} ><Text style={styles.text1}>Cargo/Tipo de Usuario:</Text></View>
                <View style={styles.block} ><Text style={styles.text1}>Usuario:</Text></View>
                <View style={styles.block} ><Text style={styles.text1}>Contraseña:</Text></View>
            </View>
            <View>
                <View style={styles.block1} ><Text style={styles.text}>{name}</Text></View>
                <View style={styles.block1} ><Text style={styles.text}>{department}</Text></View>
                <View style={styles.block1} ><Text style={styles.text}>{area}</Text></View>
                <View style={styles.block1} ><Text style={styles.text}>{account}</Text></View>
                <View style={styles.block1} ><Text style={styles.text}>{password}</Text></View>
            </View>
          </Document>
)};

// Contenido del PDF
const Pdf = ({data}) => {
    return(
        <Document style={styles.content}>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                     <HeadComponent/>
                     <Text style={styles.text}>
                         {"\n"}{"\n"}
                        Previo diligenciamiento y radicación de la solicitud de cuenta en el Sistema Excel, el
                        DEPARTAMENTO DE DESARROLLO, hace entrega formal de usuario y contraseña en el
                        Sistema de Información Excel así:{"\n"}{"\n"}
                    </Text>
                    <table style={styles.table}>
                        <thead>
                          <tr>
                              <th><Text>Datos del Titular de la Cuenta</Text></th>
                          </tr>
                        </thead>
                    </table>
                    <MediaComponent
                            name={data.name}
                            department={data.department}
                            password={data.password}
                            area={data.area}
                            account={data.account}
                    />
                    <Text style={styles.subtext} >
                        {"\n"}Nota: La contraseña inicial es una cadena de caracteres aleatorios, la cual debe cambiarse la primera vez que
                        inicia sesión.{"\n"} {"\n"}
                    </Text>
                    <Text style={styles.text}>
                        Se informa de Igual manera, al titular de la cuenta que debe tener en consideración las
                        siguientes recomendaciones respecto al Sistema citado:{"\n"}{"\n"}
                    </Text>
                    <Text style={styles.subtext2}>
                        1.   Su   cuenta   de   Usuario   y   Clave   es   SECRETA,   PERSONAL,   UNICA   E
                        INTRANSFERIBLE, siendo, quien recibe el usuario, el Único responsable del manejo y
                        uso del SISTEMA EXCEL, así como de la veracidad de la información que registra,
                        consolida y consulta. El uso indebido del sistema acarreara la sanción disciplinaria
                        correspondiente.{"\n"}{"\n"}

                        2. Toda la información registrada en el Sistema, tiene carácter de declaración jurada,
                        es   decir   que  cualquier  acción   efectuada   en   el  Sistema,   permitirá  determinar   la
                        identidad del usuario y/o las acciones realizadas, cuando un órgano de control o
                        entidad de vigilancia lo requiera.{"\n"}{"\n"}

                        3. Una vez el usuario tiene su contraseña, deberá cambiarla periódicamente a través
                        de la opción que le proporcione el sistema por otra que considere conveniente y tenga
                        las características de seguridad adecuadas.{"\n"}{"\n"}
                    </Text>
                    <Text style={styles.text}>
                        Para constancia de su recibo, se firma el día: ________________________.
                    </Text>
                </View>
            </Page>
        </Document>
    );
}
export default Pdf;
