/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";
import styled from "styled-components";
import {getInfoRequests, updateInfoRequest} from '../../../data_services/patients/patients_service.js';
import {DataGrid} from '@material-ui/data-grid';
import Dialog from "../../../../node_modules/@material-ui/core/Dialog/Dialog";
import DialogTitle from "../../../../node_modules/@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "../../../../node_modules/@material-ui/core/DialogContent/DialogContent";
import {CheckCircle, Cancel, Visibility, Close, Search} from "@material-ui/icons";
import {Button, ButtonGroup, Grid, TextField} from "@material-ui/core";

const NoteCard = styled.blockquote`
    border: 1px solid darkgray;
    border-left: 5px solid darkgray !important;
    margin: 9px 18px; padding: 6px; font-style: italic;
    text-align: justify;
`;


let InfoRequestTable = () => {
    let columns = [
        {
            field: 'date_requested',
            headerName: 'Date  Requested',
            flex: 1.5
        },
        {
            field: 'patient_name',
            headerName: 'Patient Name',
            flex: 2
        },
        {
            field: 'member_id',
            headerName: 'ID',
            flex: 1.5
        },
        {
            field: 'updated_name',
            headerName: 'Attended By',
            flex: 2
        },
        {
            field: 'updated_date',
            headerName: 'Response Date',
            flex: 1.5
        },
        {
            field: 'status_name',
            headerName: 'Status',
            flex: 1
        },
        {
            field: '',
            headerName: 'Actions',
            renderCell: instance => <span>
                <Button onClick={() => showRow(instance.row)}><Visibility /></Button>
            </span>,
            flex: 1
        },
    ];
    const [loadingTable, setLoading] = useState(false)
    const [viewDetail, setViewDetail] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [requestInstance, setRequestInstance] = useState({})
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        patient: ''
    });

    let showRow = (row) => {
        setViewDetail(true);
        console.log(row)
        setRequestInstance(row);
    }

    async function fetchData() {
        setLoading(true)
        const {data: disablities} = await getInfoRequests(filters);
        setData(disablities);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = ({target: input}) => {
        const data = {...filters};
        data[input.name] = input.value;
        setFilters(data);
    }

    let updateRequest = async (e, value) => {
        setButtonDisabled(true);
        let response = await updateInfoRequest(requestInstance.id,
            {"status": value});
        if (response.status === 200){
            setViewDetail(false);
            fetchData();
            toast.success("Status Updated");
            setButtonDisabled(false)
        }
    }

    return (
        <div className="container__content-body">
        <h3>User Request for Info Upate</h3>
            <Grid container spacing={3} style={{ "marginBottom": "12px" }}>
                <Grid item md={4} xs={12}>
                    <TextField id="patient" label="Patient Name" name="patient" value={filters.patient}
                               className="form-control" onChange={handleChange}
                    />
                </Grid>
                <Grid item md={3}>
                    <ButtonGroup variant="contained" color="primary" aria-label="Filtrar o Descargar">
                        <Button onClick={fetchData}><Search/> &nbsp;Filter</Button>
                    </ButtonGroup>
                </Grid>
            </Grid><br/><br/>
                <DataGrid rows={data} columns={columns} pageSize={10}
                      rowsPerPageOptions={[10, 25, 50, 100]} loading={loadingTable}/>
            
            <Dialog fullWidth maxWidth="md" open={viewDetail} onClose={()=>fetchData()}
                    aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">Request Detail
                    <Close style={{float: "right"}} onClick={()=>setViewDetail(false)} />
                </DialogTitle>
                <DialogContent>
                    <NoteCard>{ requestInstance.request_body }</NoteCard>
                    {requestInstance.status === 'pending' && <div style={{display: "flex", alignItems: "center",
                justifyContent: "space-between"}}>
                        <Button disabled={buttonDisabled}
                        onClick={(e) => updateRequest(e, "completed")} color="primary">
                            <CheckCircle></CheckCircle> Accept
                        </Button>
                        <Button disabled={buttonDisabled}
                        onClick={(e) => updateRequest(e, "rejected")} color="secondary">
                            <Cancel></Cancel> Reject
                        </Button>
                    </div>}
                </DialogContent>
            </Dialog>

        </div>
    )
};

export default InfoRequestTable;