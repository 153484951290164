import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../../../common/Input";
import Select from "../../../common/Select";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import TextArea from "../../../common/TextArea";

const EstiloVida = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_estilo_vida,
    setNotaEstiloVida,
  } = notaState;

  const validations = () => {
    let error = false;
    if (!nota_estilo_vida.activity) {
      error = true;
    }
    if (!nota_estilo_vida.exercise) {
      error = true;
    }
    if (!nota_estilo_vida.kind) {
      error = true;
    }
    if (!nota_estilo_vida.frequency) {
      error = true;
    }
    if (!nota_estilo_vida.duration) {
      error = true;
    }
    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_estilo_vida: error,
    });
  };

  useEffect(() => {
    if (!nota_detalle) {
      validations();
    }
  }, [nota_estilo_vida]);

  useEffect(() => {
    if (nota_detalle) {
      validations();
    }
  }, [nota_detalle]);

  const handleChange = (e) => {
    setNotaEstiloVida({
      ...nota_estilo_vida,
      [e.target.name]: e.target.value,
    });
  };

  const option = [
    {
      id: 0,
      name: "Ligera",
    },
    {
      id: 1,
      name: "Muy ligera",
    },
    {
      id: 2,
      name: "Moderada",
    },
    {
      id: 3,
      name: "No Realizada",
    },
  ];

  let { activity, exercise, kind, frequency, duration } = nota_estilo_vida;

  let error = nota_nutricional_error.nota_estilo_vida;
  
  return (
    <div className="estilo-vida">
      <h1>ESTILO DE VIDA {error && <b>*</b>}</h1>
      <div>
        <div>
          <Select
            type="select"
            name="activity"
            onChange={handleChange}
            value={activity}
            label="Actividad *"
            options={option}
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <TextArea
            type="text"
            name="exercise"
            onChange={handleChange}
            value={exercise}
            cols="50"
            rows="10"
            label="Ejercicio"
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <Input
            type="text"
            name="kind"
            onChange={handleChange}
            value={kind}
            label="Tipo"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="frequency"
            onChange={handleChange}
            value={frequency}
            label="Frecuencia"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="duration"
            onChange={handleChange}
            value={duration}
            label="Duración"
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default EstiloVida;
