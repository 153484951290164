import React from 'react';


const PdfContainer = (props) => {

  

    const bodyRef = React.createRef();
    const createPdf = () => props.createPdf(bodyRef.current);

    return (  <section className="pdf-container">
    <section className="pdf-toolbar">
      <span>
        <button className="nota-button pdf-button" onClick={createPdf}>Generar PDF</button>
      </span>
    </section>
    <br/>
    <section className="pdf-body" ref={bodyRef}>
      {props.children}
    </section>
  </section> );
}
 
export default PdfContainer;

 