/**
 * Account Integration Page
 * @author Arturo Perez
 * @author Emanuel Rodriguez
 */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { FormControl } from "@material-ui/core";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Pagination from "@mui/material/Pagination";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { FaSearch, FaTrash, FaHistory } from "react-icons/fa";
import AccountHistory from "./AccountHistory";
import Select from "../../common/Select";
import Modal from "../../common/Modal";
import axiosClient from "../../../config/axios";
import authContext from "../../../context/auth/authContext";
import useFolio from "../../../hooks/usefolioChange";
import Loader from "../../common/Loader";

const Title = styled.h1`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  text-align: center;
  text-decoration-line: ${(props) => (props.decoration ? "underline" : "none")};
  text-underline-offset: ${(props) => (props.decoration ? "0.5em" : "0")};
`;

const STableCell = styled(TableCell)`
  background-color: #c3c3c3;
  font-weight: bold !important;
  font-size: 12px !important;
  padding: 10px !important;
`;

const STableRow = styled(TableRow)`
  & > td {
    font-size: 12px !important;
  }
  &:hover {
    background-color: #e6e6e6;
  }
`;

const Badge = styled.span`
  background-color: ${(props) => props.status};
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  border: 1px solid #c3c3c3;
`;

const Folio = styled.span`
  color: ${(props) => props.status};
`;

const Index = () => {
  const [accounts, setAccounts] = useState([]);
  const [account_page, setAccountPage] = useState(1);
  const [tab_index, setTabIndex] = useState("1");
  const [search_patient, setSearchPatient] = useState("");
  const [search_folio, setSearchFolio] = useState("");
  const [page, setPage] = useState(1);
  const [observation_type, setObservationType] = useState([]);
  const [id_verificado, setIdVeritificado] = useState("");
  const [row_id, setRowId] = useState("");
  const [loading, setLoading] = useState(false);

  const authState = useContext(authContext);
  const { user } = authState;
  const [
    folio_service,
    account_observation,
    account_observation_type,
    ModalContent,
    setForm,
  ] = useFolio("");

  /* A hook that is called after the component is rendered. It is used to filter the accounts based on
   the search_patient variable. */
  /**
   * It's a function that takes a text parameter, makes a request to the server, return a patient and then sets the
   * state of the component
   */
  const onSearchPatient = (text) => {
    setLoading(true);
    axiosClient
      .get(
        `agenda/gabinete/schedule_cabinet/?patient=${text.toLowerCase()}&page=${page}&folio=`
      )
      .then((res) => {
        setAccounts(res.data.results);
        setAccountPage(Math.ceil(res.data.count / 25));
        setLoading(false);
      });
  };

  /**
   * It's a function that takes a text parameter, makes a request to the server, return a folio and then sets the
   * state of the component
   */
  const onSearchFolio = (text) => {
    setLoading(true);
    axiosClient
      .get(
        `agenda/gabinete/schedule_cabinet/?folio=${text.toLowerCase()}&page=${page}&patient=`
      )
      .then((res) => {
        setAccounts(res.data.results);
        setAccountPage(Math.ceil(res.data.count / 25));
        setLoading(false);
      });
  };

  /* A useEffect hook that is checking if the search_patient is not empty, then it will call the
   onSearchPatient function with the search_patient as the parameter. If the search_patient is empty,
   then it will call the getCabs function with 1 as the parameter. */
  useEffect(() => {
    if (search_patient !== "") {
      onSearchPatient(search_patient);
    } else {
      getCabs(1);
    }
  }, [search_patient]);

  /* Checking if the search_folio is not empty, then it will call the onSearchFolio function with the
   search_folio as the parameter. If the search_folio is empty, then it will call the getCabs
   function with 1 as the parameter. */
  useEffect(() => {
    if (search_folio !== "") {
      onSearchFolio(search_folio);
    } else {
      getCabs(1);
    }
  }, [search_folio]);

  /* An array of objects that are used to populate the select input. */
  const SEARCH_TYPES = [
    {
      id: 1,
      name: "Paciente",
    },
    {
      id: 2,
      name: "Folio de cuenta",
    },
  ];
  const [searchType, setSearchType] = useState("1");

  /**
   * It takes the value of the event target and sets the state of the searchType variable to that value
   */
  const ChangeSearchType = (e) => {
    setSearchType(e.target.value);
  };

  /**
   * It gets the data from the API and sets the state of the component
   */
  const getCabs = (page) => {
    setLoading(true);
    axiosClient
      .get(
        `/agenda/gabinete/schedule_cabinet/?patient=${search_patient}&folio=${search_folio}&page=${page}`
      )
      .then((res) => {
        setAccounts(res.data.results);
        setAccountPage(Math.ceil(res.data.count / 25));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * It gets the observation types from the API and sets the state of the observation types
   */
  const getObservationTypes = () => {
    axiosClient
      .get(`/agenda/gabinete/observation_type/`)
      .then((res) => {
        setObservationType(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* Calling the getCabs and getObservationTypes function when the page loads. */
  useEffect(() => {
    getCabs(page);
    getObservationTypes(observation_type);
  }, []);

  /* Calling the getCabs function when the page variable changes. */
  useEffect(() => {
    getCabs(page);
  }, [page]);

  /**
   * The function takes in an event and a value, and sets the page to the value
   */
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  /**
   * It takes a string as an argument and returns a color based on the string
   * @returns The color of the status.
   */
  const setStatusColor = (status) => {
    const COLOR = {
      INTERPRETADO: "#5cb85c",
      REALIZADO: "#337ab7",
      VALIDADO: "#777",
      ENTREGADO: "#17a2b8",
    };
    return COLOR[status];
  };

  /**
   * If the status is greater than 0, return green, otherwise return red
   * @returns The status of the folio.
   */
  const setStatusFolio = (status) => {
    return status.length > 0 ? "green" : "red";
  };

  /**
   * It takes an id as an argument, request the API to get the verify the schedule
   * then refresh the table.
   */
  const handleVerifyPharmacyChange = () => {
    axiosClient
      .patch(
        `/agenda/gabinete/schedule_cabinet/${id_verificado}/?patient=${search_patient}&folio=${search_folio}&page=${page}`,
        {
          is_verified_account: true,
          verified_account_by: user.id,
          verified_account_date: new Date(),
        }
      )
      .then((res) => {
        const list = [...accounts];
        const element = list.findIndex((item) => item.id === id_verificado);
        list[element].is_verified_account = !list[element].is_verified_account;
        setAccounts(list);
        childRef.current.handleClickClose();
      })
      .catch((error) => {
        console.error(error);
        childRef.current.handleClickClose();
      });
  };

  /**
   * It takes an id as an argument, request the API to update the folio and observations for the schedule
   * and refresh the table
   */
  const handleEditFolio = () => {
    axiosClient
      .patch(
        `/agenda/gabinete/schedule_cabinet/${id_verificado}/?patient=${search_patient}&folio=${search_folio}&page=${page}`,
        {
          folio_service: folio_service,
          folio_created_by: user.id,
          folio_assign_date: new Date(),
          account_observation: account_observation,
          observation_type: account_observation_type
            ? account_observation_type
            : "",
        }
      )
      .then((res) => {
        const resp = res.data;
        const list = [...accounts];
        const element = list.findIndex((item) => item.id === id_verificado);
        list[element].folio_service = resp.folio_service;
        list[element].account_observation = resp.account_observation;
        list[element].observation_type = resp.observation_type;
        list[element].observation_type_detail = resp.observation_type_detail;
        setAccounts(list);
        folioRef.current.handleClickClose();
      })
      .catch((error) => {
        console.error(error);
        if (!error.request) folioRef.current.handleClickClose();
      });
  };

  /**
   * The function takes in a newValue, and sets the tabIndex to that newValue
   */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /**
   * It clears the searchPatient and searchFolio variables and calls the getCabs function.
   */
  const cleanSearch = () => {
    setSearchPatient("");
    setSearchFolio("");
    setPage(1);
  };

  const childRef = useRef();
  const historyRef = useRef();
  const folioRef = useRef();

  return (
    <div className="container__content-body">
      <Box sx={{ width: "100%" }}>
        <TabContext value={tab_index}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} indicatorColor="primary">
              <Tab label="Gabinete" value="1" />
              <Tab label="Laboratorio" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Title> Departamento Farmacia Intrahospitalaria</Title>
            <Subtitle> Cargo a cuentas de pacientes</Subtitle>

            <FormControl>
              <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={12} md={3}>
                  <Select
                    required
                    name="search_type"
                    label="Buscar por"
                    id={"search_type"}
                    value={searchType}
                    onChange={ChangeSearchType}
                    options={SEARCH_TYPES}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  {searchType === "1" ? (
                    <TextField
                      size="small"
                      autoComplete="off"
                      onChange={(e) => {
                        setSearchPatient(e.target.value);
                      }}
                      id="patient"
                      name="patient"
                      placeholder="Nombre/Apellido"
                      autoFocus={searchType === "1"}
                      variant="outlined"
                      value={search_patient}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FaSearch />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      size="small"
                      autoComplete="off"
                      onChange={(e) => {
                        setSearchFolio(e.target.value);
                      }}
                      id="account_number"
                      name="folio"
                      placeholder="Folio de cuenta"
                      autoFocus={searchType === "2"}
                      variant="outlined"
                      value={search_folio}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FaSearch />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    disableElevation
                    variant="contained"
                    color="warning"
                    startIcon={<FaTrash />}
                    onClick={() => cleanSearch()}
                  >
                    Limpiar
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
            <TableContainer component={Paper} style={{ width: "100%" }}>
              <Table style={{ tableLayout: "fixed" }} size="small">
                <TableHead>
                  <TableRow>
                    <STableCell align="center">Fecha de Realización</STableCell>
                    <STableCell align="center">Fecha/Hora de Cita</STableCell>
                    <STableCell align="center">Nombre del Paciente</STableCell>
                    <STableCell align="center">Fecha de Nacimiento</STableCell>
                    <STableCell align="center">Aseguranza</STableCell>
                    <STableCell align="center">Tipo de Servicio</STableCell>
                    <STableCell align="center">
                      Descripción del Estudio
                    </STableCell>
                    <STableCell align="center">Folio Solicitud</STableCell>
                    <STableCell align="center">
                      Médico o Técnico que Realiza
                    </STableCell>
                    <STableCell align="center">Estatus</STableCell>
                    <STableCell align="center">
                      Médico que Interpreta
                    </STableCell>
                    <STableCell align="center">
                      Folio Cuenta de Paciente
                    </STableCell>
                    <STableCell align="center">Verificado Farmacia</STableCell>
                    <STableCell align="center">Observaciones</STableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.length > 0 ? (
                    loading ? (
                      <STableRow>
                        <TableCell colSpan={14} align="center">
                          <Loader />
                        </TableCell>
                      </STableRow>
                    ) : (
                      accounts.map((account, index) => (
                        <STableRow key={index}>
                          <TableCell align="center">
                            <span>
                              {account.start_date} {account.end_hour}
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  historyRef.current.handleClickOpen();
                                  setRowId(account.id);
                                }}
                              >
                                <FaHistory size={12} />
                              </IconButton>
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            {account.start_date} {account.start_hour}
                          </TableCell>
                          <TableCell align="center">
                            {account.patient.person.full_name}
                          </TableCell>
                          <TableCell align="center">
                            {account.patient_dob}
                          </TableCell>
                          <TableCell align="center">
                            {account.service_provider.name}
                          </TableCell>
                          <TableCell align="center">
                            {account.service_type}
                          </TableCell>
                          <TableCell align="center">
                            {account.study.description}
                          </TableCell>
                          <TableCell align="center">
                            {account.requeststudiesimg ? (
                              <Link
                                to={{
                                  pathname: `${axiosClient.defaults.baseURL}/imagenologia/pdf/${account.requeststudiesimg.request_imagenologia}/`,
                                }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {account.requeststudiesimg.request_imagenologia}
                              </Link>
                            ) : (
                              "Sin solicitud"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {account.physician.employee.person.full_name}
                          </TableCell>
                          <TableCell align="center">
                            <Badge status={setStatusColor(account.status)}>
                              {account.status}
                            </Badge>
                          </TableCell>
                          <TableCell align="center">
                            {account.interpreta}
                          </TableCell>
                          <TableCell align="center">
                            {account.folio_service ? (
                              <a
                                href="#"
                                onClick={() => {
                                  folioRef.current.handleClickOpen();
                                  setIdVeritificado(account.id);
                                  setForm(
                                    observation_type,
                                    account.folio_service,
                                    account.account_observation
                                      ? account.account_observation
                                      : "",
                                    account.observation_type
                                      ? account.observation_type
                                      : ""
                                  );
                                }}
                              >
                                <Folio
                                  status={setStatusFolio(account.folio_service)}
                                >
                                  {account.folio_service}
                                </Folio>
                              </a>
                            ) : (
                              <a
                                href="#"
                                onClick={() => {
                                  folioRef.current.handleClickOpen();
                                  setIdVeritificado(account.id);
                                  setForm(
                                    observation_type,
                                    account.folio_service,
                                    account.account_observation
                                      ? account.account_observation
                                      : "",
                                    account.observation_type
                                      ? account.observation_type
                                      : ""
                                  );
                                }}
                              >
                                <Folio
                                  status={setStatusFolio(account.folio_service)}
                                >
                                  Sin folio
                                </Folio>
                              </a>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Checkbox
                              type="checkbox"
                              value={account.is_verified_account}
                              onClick={() => {
                                childRef.current.handleClickOpen();
                                setIdVeritificado(account.id);
                              }}
                              checked={
                                account.is_verified_account ? "checked" : ""
                              }
                              disabled={account.is_verified_account ? true : ""}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {Object.keys(account.observation_type_detail)
                              .length > 0
                              ? `${
                                  account.observation_type_detail.name +
                                  ": " +
                                  account.account_observation
                                }`
                              : account.account_observation
                              ? account.account_observation
                              : ""}
                          </TableCell>
                        </STableRow>
                      ))
                    )
                  ) : (
                    <STableRow>
                      <TableCell align="center" colSpan={14}>
                        No se encontraron registros
                      </TableCell>
                    </STableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {account_page > 1 && (
              <Pagination
                className="pagination"
                count={account_page}
                page={page}
                onChange={handleChangePage}
              />
            )}
          </TabPanel>
          <TabPanel value="2"></TabPanel>
        </TabContext>
      </Box>
      <Modal
        ref={childRef}
        title="Confirmar Verificación"
        content={
          <>¿Estas seguro que quieres validar que los datos son correctos?</>
        }
        action={handleVerifyPharmacyChange}
        post_finallized={true}
      />
      <Modal
        size="xl"
        ref={historyRef}
        title="Historial de Cambios"
        content={<AccountHistory id={row_id} />}
        show_save={false}
        post_finallized={true}
      />
      <Modal
        ref={folioRef}
        title="Agregar Folio"
        content={ModalContent}
        action={handleEditFolio}
        post_finallized={true}
      />
    </div>
  );
};

export default Index;
