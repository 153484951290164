import React, { Fragment, useState, useEffect, useContext } from "react";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const PlanNutricionalNotas = () =>{
    const notaState = useContext(notaContext);
    const {
        nota_detalle,
        nota_plan_nutricional_nota,
        setNotaPlanNutricionalNota,
    } = notaState;

    /**
     * A function that handles the change of the input value.
     */
    const handleChange = (e) => {
        setNotaPlanNutricionalNota(e.target.value);
    };

    const{nutritional_plan_note_comments} = nota_plan_nutricional_nota;

    return(
        <Fragment>
            <h1>Plan Nutricional </h1>
                <Fragment>
                    <TextArea 
                    name="nutritional_plan_note_comments" 
                    label="Plan Nutricional"
                    onChange={handleChange}
                    value={nutritional_plan_note_comments}
                    cols="50"
                    rows="10"
                    disabled={nota_detalle ? true : false}
                    />
                </Fragment>
        </Fragment>
    );
};

export default PlanNutricionalNotas;