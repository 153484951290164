// eslint-disable-next-line
import {
  SET_NOTA_INICIAL,
  SET_NOTA_DIAGNOSTICO,
  SET_NOTA_MOTIVO_CONSULTA,
  SET_NOTA_ANTROPOMETRICOS,
  SET_NOTA_INDICADORES_BIOQUIMICOS,
  SET_NOTA_INDICADORES_CLINICOS,
  SET_NOTA_ANTECEDENTES_FAMILIARES,
  SET_NOTA_ESTILO_VIDA,
  SET_NOTA_INDICADORES_DIETETICOS,
  SET_NOTA_DATOS_DIETETICOS,
  SET_NOTA_RECORDATORIO_24,
  SET_NOTA_DIETA_HABITUAL,
  SET_NOTA_DIETA_HABITUAL_DETALLE,
  SET_NOTA_FRECUENCIA_CONSUMO,
  SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES,
  SET_NOTA_DIAGNOSTICO_NUTRICIONAL,
  SET_NOTA_DIAGONOSTICO_CIE,
  SET_NOTA_PLAN_NUTRICIONAL,
  SET_NOTA_PLAN_NUTRICIONAL_FORMULAS,
  SET_NOTA_PLAN_NUTRICIONAL2,
  SET_NOTA_PLAN_NUTRICIONAL3,
  SET_NOTA_PLAN_NUTRICIONAL4,
  SET_FORMULAS,
  SET_ID_NOTA,
  SET_DETALLE_NOTA,
  SET_NOTA_PLAN_NUTRICIONAL_NOTA,
  SET_NOTA_PLAN_TRATAMIENTO,
  SET_NOTA_PLAN_NUTRICIONAL_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL2_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL3_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL4_DETALLE,
  SET_NOTA_RECORDATORIO_24_DETALLE,
  SET_NOTA_FRECUENCIA_CONSUMO_DETALLE,
  SET_NOTA_ERROR,
  SET_FETCH_DATA
} from "../../types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, action) => {
  switch (action.type) {
    case SET_NOTA_INICIAL:
      return {
        ...state,
        nota_inicial: action.payload,
      };
    case SET_NOTA_DIAGNOSTICO:
      return {
        ...state,
        nota_diagnostico: action.payload,
      };
    case SET_NOTA_MOTIVO_CONSULTA:
      return {
        ...state,
        nota_motivo_consulta: action.payload,
      };
    case SET_NOTA_ANTROPOMETRICOS:
      return {
        ...state,
        nota_antropometricos: action.payload,
      };
    case SET_NOTA_INDICADORES_BIOQUIMICOS:
      return {
        ...state,
        nota_indicadores_bioquimicos: action.payload,
      };
    case SET_NOTA_INDICADORES_CLINICOS:
      return {
        ...state,
        nota_indicadores_clinicos: action.payload,
      };
    case SET_NOTA_ANTECEDENTES_FAMILIARES:
      return {
        ...state,
        nota_antecedentes_familiares: action.payload,
      };
    case SET_NOTA_ESTILO_VIDA:
      return {
        ...state,
        nota_estilo_vida: action.payload,
      };
    case SET_NOTA_INDICADORES_DIETETICOS:
      return {
        ...state,
        nota_indicadores_dieteticos: action.payload,
      };
      case SET_NOTA_DATOS_DIETETICOS:
        return {
          ...state,
          nota_datos_dieteticos: action.payload,
        };
    case SET_NOTA_RECORDATORIO_24:
      return {
        ...state,
        nota_recordatorio24: action.payload,
      };
    case SET_NOTA_RECORDATORIO_24_DETALLE:
      return {
        ...state,
        nota_recordatorio24_detalle: action.payload,
      };
    case SET_NOTA_DIETA_HABITUAL:
      return {
        ...state,
        nota_dieta_habitual: action.payload,
      };
    case SET_NOTA_DIETA_HABITUAL_DETALLE:
      return {
        ...state,
        nota_dieta_habitual_detalle: action.payload,
      };
    case SET_NOTA_FRECUENCIA_CONSUMO:
      return {
        ...state,
        nota_frecuencia_consumo: action.payload,
      };
    case SET_NOTA_FRECUENCIA_CONSUMO_DETALLE:
      return {
        ...state,
        nota_frecuencia_consumo_detalle: action.payload,
      };
      /* Setting the state of the nota_plan_nutricional_nota to the payload. */
      case SET_NOTA_PLAN_NUTRICIONAL_NOTA:
        return{
          ...state,
          nota_plan_nutricional_nota: action.payload,
        };
      /* Setting the state of the nota_plan_tratamiento to the payload. */
        case SET_NOTA_PLAN_TRATAMIENTO:
      return{
        ...state,
        nota_plan_tratamiento: action.payload,
      };        
    case SET_NOTA_DIAGNOSTICO_NUTRICIONAL:
      return {
        ...state,
        nota_diagnostico_nutricional: action.payload,
      };
    /* Setting the state of the cie_result to the payload. */
    case SET_NOTA_DIAGONOSTICO_CIE:
      return {
        ...state,
        cie_result: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL:
      return {
        ...state,
        nota_plan_nutricional: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL_FORMULAS:
      return {
        ...state,
        nota_plan_nutricional_formulas: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL_DETALLE:
      return {
        ...state,
        nota_plan_nutricional_detalle: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL2:
      return {
        ...state,
        nota_plan_nutricional_parte2: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL2_DETALLE:
      return {
        ...state,
        nota_plan_nutricional_parte2_detalle: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL3:
      return {
        ...state,
        nota_plan_nutricional_parte3: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL4:
      return {
        ...state,
        nota_plan_nutricional_parte4: action.payload,
      };
    case SET_NOTA_PLAN_NUTRICIONAL4_DETALLE:
      return {
        ...state,
        nota_plan_nutricional_parte4_detalle: action.payload,
      };
      case SET_FORMULAS:
        return {
          ...state,
          nota_nutricional_formulas: action.payload,
        };
      case SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES:
        return{
          ...state,
          nota_frecuencia_consumo_observaciones: action.payload
        }
      case SET_ID_NOTA:
        return{
          ...state,
          id_nota: action.payload
        }
        case SET_DETALLE_NOTA:
          return{
            ...state,
            nota_detalle:action.payload
          }
        case SET_NOTA_ERROR:
          return{
            ...state,
            nota_nutricional_error:action.payload
          }
        case SET_FETCH_DATA:
          return{
            ...state,
            fetch_data:action.payload
          }
          
    default:
      return state;
  }
};
