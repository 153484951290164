import React, {useContext, useEffect, useState} from 'react';
import {Button, ButtonGroup, darken, Grid, IconButton, lighten, TextField} from '@material-ui/core';
import {
    AssignmentTurnedIn,
    Block,
    Cancel, CheckCircle, Edit,
    ExitToApp, Favorite,
    FlashOn,
    Help,
    IndeterminateCheckBox, ListAlt, MonetizationOn, Notifications,
    Phone, Remove, Search,
    VideoCall, Warning
} from '@material-ui/icons';
import axiosClient from "../../../config/axios";
import {DataGrid, esES, GridToolbar, GridOverlay} from "@material-ui/data-grid";
import ControlledSelect from "../../common/ControlledSelect";
import PatientSearch from "../../common/PatientSearch";
import PhysicianSearch from "../../common/PhysicianSearch";
import styled from "styled-components";
import {makeStyles, createTheme, ThemeProvider} from "@material-ui/core/styles";
import Chip from "../../../../node_modules/@material-ui/core/Chip/Chip";
import {CancelAppDialog, ChangeAppTypeDialog} from "./ChangeAppDialogs";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from "../../../../node_modules/@material-ui/core/LinearProgress/LinearProgress";
import authContext from "../../../context/auth/authContext";
import SpecialtySearch from "../../common/SpecialtySearch";

const InnerCellDiv = styled.div`
   margin-bottom: -24px;
   margin-top: -24px;
   text-align: center;
`;
const theme = createTheme(
    {
        palette: {
            primary: {main: '#1976d2'},
        },
    },
    esES,
);

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{
                position: 'absolute', top: 0, width: '100%', height: '100%',
                backgroundColor: 'rgba(200,200,200,0.7)', zIndex: 9999
            }}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}

const useStyles = makeStyles((theme) => {
    const getBackgroundColor = (color) => lighten(color, 0.6);

    const getHoverBackgroundColor = (color) => lighten(color, 0.6);

    return {
        root: {
            '& .alert-Past': {
                backgroundColor: getBackgroundColor(theme.palette.warning.main),
                color: '#8a6d3b',
                '&:hover': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                },
            },
            '& .alert-Scheduled': {
                backgroundColor: getBackgroundColor('#f7f4f4'),
                '&:hover': {
                    backgroundColor: getHoverBackgroundColor('#e8e5e5'),
                },
            },
            '& .alert-Attended': {
                backgroundColor: getBackgroundColor(theme.palette.success.main),
                color: '#3c763d',
                '&:hover': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                },
            },
            '& .alert-Cancel': {
                backgroundColor: getBackgroundColor(theme.palette.error.main),
                color: '#a94442',
                '&:hover': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                },
            },
            '& .inter_app': {
                boxShadow: '6px 6px 9px #6b85d2 inset',
                position: 'relative',
                '&:before': {
                    backgroundColor: 'rgba(107, 133, 210, 0.5)',
                    color: 'black',
                    content: '"Interconsulta por medico"',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    minWidth: '110px',
                    fontSize: '0.7em'
                },
            },
            '& .quick_app': {},
        },
    };
});

function ScheduleTable() {
    //USUARIO
    const authState = useContext(authContext);
    const {user} = authState;
    const classes = useStyles();
    const [loadingTable, setLoading] = useState(false)
    const [menuAnchor, setMenuAnchor] = useState(null)
    const [editingApp, setEditingApp] = useState({})
    const [typeOpen, setTypeOpen] = useState(false)
    const [cancelOpen, setCancelOpen] = useState(false)
    const [allowCancel, setAllowCancel] = useState(false)
    const [dateHidden, setDateHidden] = useState(false)
    const [rowIndex, setRowIndex] = useState(-1)
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        start_date: sessionStorage.getItem("start_date") !== null ? sessionStorage.getItem("start_date") : new Date().toISOString().substr(0, 10),
        end_date: sessionStorage.getItem("end_date") !== null ? sessionStorage.getItem("end_date") : new Date().toISOString().substr(0, 10),
        filter_by: sessionStorage.getItem("filter_by") !== null ? sessionStorage.getItem("filter_by") : 'all',
        searcher_id: sessionStorage.getItem("searcher_id") !== null ? sessionStorage.getItem("searcher_id") : ''
    })
    const [searcherInitial, setSearcherInitial] = useState(sessionStorage.getItem('initial_text'));
    const CONSULTATION_TYPES = {
        '': '',
        '0': 'Primera Vez',
        '1': 'Subsecuente',
        '2': 'Procedimiento',
        '3': 'Regular',
    }
    const CONSULTATION_SUBTYPES = {
        '': '',
        '0': 'Presencial',
        '1': 'Procedimiento',
        '2': 'Preventivo',
        '3': 'Prenatal',
        '4': 'Administrativa',
        '5': 'Telemedicina',
        '6': 'Triage',
        '7': 'Rapida',
        '8': 'Doctor Line',
        '9': 'Llamada',
    }

    const FILTER_BY = [
        {value: 'all', label: 'Ver Citas (Todos)'},
        {value: 'patient', label: 'Paciente'},
        {value: 'physicianV2', label: 'Medico'},
        {value: 'specialty', label: 'Especialidad'}
    ]

    const calculateCancel = () => {
        const allowed_lock_cancel = ['kcarmona', 'emendoza', 'mrosales', 'yreyes']
        return (editingApp.status === 'Locked' && allowed_lock_cancel.indexOf(user.username) !== -1) || (editingApp.status !== 'Cancel' && editingApp.status !== 'Past')
    }

    let columns = [
        {
            field: 'patient_id',
            headerName: 'Registro Unico',
            valueGetter: instance => `Excel-${instance.row.patient_id}`,
            flex: 1.2
        },
        {
            field: 'paying_provider_name',
            headerName: 'Seguro',
            flex: 1
        },
        {
            field: 'credential',
            headerName: 'Miembro',
            flex: 1.2
        },
        {
            field: 'patient_first_name',
            headerName: 'Paciente',
            valueGetter: instance => `${instance.row.patient_first_name} ${instance.row.patient_last_name}`,
            renderCell: instance => {
                return (
                    <>{instance.getValue('patient_first_name')}
                      {instance.getValue('is_confirmed') && <Notifications/>}</>)
            },
            flex: 2.1
        },
        {
            field: 'patient_date_of_birth',
            headerName: 'Fecha Nac.',
            type: 'date',
            flex: 1
        },
        {
            field: 'physician_first_name',
            headerName: 'Medico',
            valueGetter: instance => `${instance.row.physician_first_name} ${instance.row.physician_last_name}`,
            flex: 2
        },
        {
            field: 'consultation_type',
            headerName: 'Tipo Consulta',
            valueGetter: instance => `${instance.row.consultation_type===null?'':CONSULTATION_TYPES[instance.row.consultation_type]}`,
            renderCell: instance => {
                return (<div>
                    <InnerCellDiv>{instance.getValue('consultation_type')}</InnerCellDiv>
                    <InnerCellDiv>
                        {instance.getValue('consultation_subtype') === 5 && <VideoCall fontSize="small"/>}
                        {instance.getValue('consultation_subtype') === 9 && <Phone fontSize="small"/>}
                        {instance.getValue('consultation_subtype')===null?'':CONSULTATION_SUBTYPES[instance.getValue('consultation_subtype')]}
                    </InnerCellDiv>
                </div>)
            },
            flex: 1.2
        },
        {
            field: 'start_date',
            headerName: 'Fecha',
            type: 'date',
            hide: dateHidden,
            flex: 1
        },
        {
            field: 'start_hour',
            headerName: 'Hora Inicio',
            flex: 1
        },
        {
            field: 'specialty_name',
            headerName: 'Especialidad',
            flex: 1.5
        },
        {
            field: 'active_plan',
            headerName: 'Plan',
            renderCell: instance => {
                let plan = instance.getValue('active_plan')
                return plan !== '' && <Chip size="small" style={{backgroundColor: plan === 'Privado' ? 'red' : 'green'}}
                                            label={plan}/>
            },
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Estado',
            renderCell: instance => {
                let status = instance.getValue('status')
                let payment_required = []
                if (status === 'Past') {
                    payment_required = [<Block style={{color: "#998e2c", fontSize: "larger"}} titleAccess="No Asistio"
                                               title="No Asistio" key="past"/>]
                }
                else if (status === 'Scheduled') {
                    payment_required = [
                        <Help style={{color: "#a11e97", fontSize: "larger"}} titleAccess="Agendada"
                              title="Agendada" key="scheduled"/>];
                    if (instance.getValue('monthly_covered') === true) {
                        payment_required.push(<FlashOn style={{color: "#31a17a", fontSize: "larger"}}
                                                       titleAccess="Coaseguro Cubierto"
                                                       title="Coaseguro Cubierto" key="covered"/>);
                    }
                }
                else if (status === 'Attended') {
                    payment_required = [<ExitToApp style={{color: "#31a17a", fontSize: "larger"}}
                                                   titleAccess="Arribaron"
                                                   title="Arribaron" key="arrived"/>];
                }
                else if (status === 'not_attended') {
                    payment_required = [<IndeterminateCheckBox style={{color: "#998E2C", fontSize: "larger"}}
                                                               titleAccess="No Atendida"
                                                               title="No Atendida" key="not_attended"/>];
                }
                else if (status === 'Cancel') {
                    payment_required = [<Cancel style={{color: "#c4310a", fontSize: "larger"}} titleAccess="Cancelada"
                                                title="Cancelada" key="cancelled"/>];
                }
                if (instance.getValue('appointment_paid') > 0) {
                    payment_required.push(<MonetizationOn style={{color: "#31a17a", fontSize: "larger"}}
                                                          titleAccess="Coaseguro Pagado"
                                                          title="Coaseguro Pagado" key="paid"/>);
                } else if ((instance.getValue('specialty_name') === 'Cirujano Dentista') && (instance.getValue('arrival'))) {
                    payment_required.push(<span key="dental">D</span>);
                } else if (instance.getValue('arrival')) {
                    payment_required.push(<Warning style={{color: "#fdaa09", fontSize: " x-large"}}
                                                   titleAccess="Pago Pendiente"
                                                   title="Pago Pendiente" key="pending_pay"/>);
                }
                if ((instance.getValue('arrival')) && (instance.getValue('vitals'))) {
                    payment_required.push(<Favorite style={{color: "#f43e0a", fontSize: " larger"}}
                                                    titleAccess="Signos Vitales"
                                                    title="Signos Vitales" key="vitals"/>);
                }
                if (instance.getValue('time_of_consultation')) {
                    payment_required.push(<CheckCircle style={{color: "#9bc6ff", fontSize: " larger"}}
                                                       titleAccess="Consulta Iniciada"
                                                       title="Consulta Iniciada" key="started"/>);
                }
                if (instance.getValue('consultation_end')) {
                    payment_required.push(<AssignmentTurnedIn style={{color: "#182aff", fontSize: " larger"}}
                                                              titleAccess="Consulta Terminada"
                                                              title="Consulta Terminada" key="finished"/>);
                }
                return <>{status.substr(0, 1)}{payment_required}</>
            },
            flex: 1.2
        },
        {
            field: 'city_id',
            headerName: 'Reg',
            valueGetter: instance => instance.row.city_id === 1 ? 'TJ' : 'MX',
            flex: 0.7
        },
        {
            field: 'id',
            headerName: 'Opciones',
            renderCell: instance => {
                let status = instance.getValue('status')
                let color = "blue"
                if (status === 'Cancel')
                    color = 'red'
                if (status === 'Attended')
                    color = 'green'
                return <a href={`${axiosClient.defaults.baseURL}/payments/detalle/pago/${instance.getValue('id')}/`}
                          target='_blank' onClick={(e) => e.stopPropagation()} rel="noreferrer">
                    <IconButton size="small" style={{color: color}}><ListAlt/></IconButton>
                </a>
            },
            flex: 1
        },
    ];

    const fetchData = async () => {
        if (formValid()) {
            setLoading(true)
            const {data: apps} = await axiosClient.post('/schedule/calendario/historial/', filters);
            setData(apps.search_result);
            console.log(apps.search_result)
            setLoading(false)
            setDateHidden(filters.start_date===filters.end_date)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = ({target: input}) => {
        const _filters = {...filters};
        _filters[input.name] = input.value;
        if (input.name === 'filter_by'){
            _filters['searcher_id'] = ''
            sessionStorage.setItem('searcher_id', '')
            sessionStorage.setItem('initial_text', '')
            setSearcherInitial('')
        }
        setFilters(_filters);
        sessionStorage.setItem(input.name, input.value)
    }

    const setFilter = (input) => {
        const data = {...filters};
        data[input.name] = input.value;
        setFilters(data);
        sessionStorage.setItem(input.name, input.value)
        sessionStorage.setItem('initial_text', input.initial)
        setSearcherInitial('')
    }
    const formValid = () => {
        return filters.filter_by === 'all' || filters.searcher_id !== ''
    }

    const handleRowClick = (params, event) => {
        setMenuAnchor(event.target)
        setEditingApp(params.row)
        setRowIndex(params.rowIndex)
    };

    const closeDialog = (saved, newApp) => {
        if (saved) {
        console.log(saved, newApp, rowIndex)
            let _data = [...data]
            _data[rowIndex] = newApp
            setData(_data)
        }
        setTypeOpen(false)
        setCancelOpen(false)
    }
    useEffect(() => {
        setAllowCancel(calculateCancel())
    }, [editingApp])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`container__content-body ${classes.root}`}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <ControlledSelect value={filters.filter_by} options={FILTER_BY} onChange={handleChange}
                                      name="filter_by"
                                      id="filter_by" label="BUSCAR POR:"/>
                </Grid>
                {filters.filter_by !== 'all' &&
                <Grid item md={3}>
                    {filters.filter_by === 'patient' && <PatientSearch label={'Buscar Paciente'}
                                                                       onChangePatient={(patient) => setFilter({
                                                                           name: 'searcher_id',
                                                                           value: patient ? patient.id : '',
                                                                           initial: patient ?`${patient.first_name} ${patient.last_name}` : ''
                                                                       })}
                                                                       initial_text={searcherInitial}
                                                                       search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                                                       empty_label={'No se encontraron resultados'}/>}
                    {filters.filter_by === 'physicianV2' && <PhysicianSearch label={'Buscar Medico'}
                                                                           onChangePhysician={(physician) => setFilter({
                                                                               name: 'searcher_id',
                                                                               value: physician ? physician.id : '',
                                                                               initial: physician ? `${physician.first_name} ${physician.last_name}` : ''
                                                                           })}
                                                                           initial_text={searcherInitial}
                                                                           search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                                                           empty_label={'No se encontraron resultados'}/>}
                    {filters.filter_by === 'specialty' && <SpecialtySearch label={'Buscar Especialidad'}
                                                                           onChangeSpecialty={(specialty) => setFilter({
                                                                               name: 'searcher_id',
                                                                               value: specialty ? specialty.id : '',
                                                                               initial: specialty ? specialty.name : ''
                                                                           })}
                                                                           initial_text={searcherInitial}
                                                                           search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                                                           empty_label={'No se encontraron resultados'}/>}
                </Grid>
                }
                <Grid item md={2}>
                    <TextField id="start_date" label="Fecha Inicial" type="date" name="start_date"
                               value={filters.start_date}
                               className="form-control" onChange={handleChange}
                    />
                </Grid>
                <Grid item md={2}>
                    <TextField id="end_date" label="Fecha Final" type="date" name="end_date" value={filters.end_date}
                               className="form-control" onChange={handleChange}
                               inputProps={{
                                   min: filters.start_date
                               }}
                    />
                </Grid>
                <Grid item md={2}>
                    <ButtonGroup variant="contained" color="primary" aria-label="Filtrar o Descargar">
                        <Button onClick={fetchData}
                                disabled={!formValid() || loadingTable}><Search/> &nbsp;Filtrar</Button>
                        {/*<Button onClick={() => downloadDisabilities(filters)}><CloudDownload/> &nbsp;Descargar</Button>*/}
                    </ButtonGroup>
                </Grid>
            </Grid><br/>
            <ThemeProvider theme={theme}>
                <DataGrid rows={data} columns={columns} pageSize={10} onRowClick={handleRowClick}
                          rowsPerPageOptions={[10, 25, 50, 100]} loading={loadingTable}
                          getRowClassName={(params) => `alert-${params.getValue('status')} ${params.row.origin_class}`}
                          components={{
                              Toolbar: GridToolbar, LoadingOverlay: CustomLoadingOverlay,
                          }}
                          disableSelectionOnClick
                />
            </ThemeProvider>
            <Menu id="change-app" anchorEl={menuAnchor} open={Boolean(menuAnchor)}
                  onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={() => {
                    setTypeOpen(true);
                    setMenuAnchor(null)
                }}><Edit color="primary"/>Cambiar Tipo de Cita</MenuItem>
                {allowCancel && <MenuItem onClick={() => {
                    setCancelOpen(true);
                    setMenuAnchor(null)
                }}><Cancel/>Marcar como Cancelada</MenuItem>}
                <MenuItem onClick={() => setMenuAnchor(null)}><Remove/> Cerrar</MenuItem>
            </Menu>
            <ChangeAppTypeDialog isOpen={typeOpen} handleClose={(saved, newApp) => closeDialog(saved, newApp)}
                                 appointment={editingApp}/>
            <CancelAppDialog isOpen={cancelOpen} handleClose={(saved, newApp) => closeDialog(saved, newApp)}
                             appointment={editingApp} user={user}/>
        </div>
    );
}

export default ScheduleTable;