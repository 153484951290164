import React, { useEffect, useState, useContext } from "react";
import axiosClient from "../../../config/axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FaCheckCircle, FaTimesCircle, FaSearch } from "react-icons/fa";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteIcon from "@material-ui/icons/Delete";
import authContext from "../../../context/auth/authContext";
import {array} from "joi";

/***
 * StyleComponents de Iconos, Encabezados de la tabla, Titulo
 * @type {StyledComponent<(props: IconBaseProps) => JSX.Element, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const NegatedIcon = styled(FaTimesCircle)`
  color: red;
  font-size: 1rem;
`;
const CheckIcon = styled(FaCheckCircle)`
  color: green;
  font-size: 1rem;
`;
const STableCell = styled(TableCell)`
  background-color: #c3c3c3;
  font-weight: bold !important;
  font-size: 1rem !important;
`;
const STableRow = styled(TableRow)`
  &:hover {
    background-color: #e6e6e6;
  }
  background-color: ${(props) => (props.color ? "#e6e6e6" : " 	#FA8072")};
`;
const SearchField = styled(TextField)`
  margin: 10px !important;
  width: 50%;
`;
const Title = styled.h1`
  text-align: center;
`;
/***
 * Funcion que muestra listado de usuarios de Mediexcel
 * @returns {*row,  setRows(response.data)}
 * @constructor
 */
const PatientPortalDetail = () => {
  const authState = useContext(authContext);

  const { user } = authState;
  const user_groups = user["groups"];
  /***
   *Se crea el state del context
   */
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [user_delete, setUserDelete] = useState({
    user_id: null,
    id: null,
  });
  const [originRows, setOriginRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);
  const [userUpdate, setUserUpdate] = useState(null);
  const [userStatus, setUserStatus] = useState(true);
  const [busqueda, guardarBusqueda] = useState("");

  const handleClickOpen = (user_id, status) => {
    setOpen(true);
    setUserUpdate(user_id);
    setUserStatus(status);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
  };

  /**Funcion que trae el valor del input
   * @param e
   */
  const obtenerDatosPaciente = (e) => {
    guardarBusqueda(
      e.target.value
    );
  };

  /***
   * Funcion de busqueda por nombre, segundo nombre, apellido paterno y apellido materno de usuario apartir del segundo
   * caracter ingresado por el usuario.
   */
  useEffect(() => {
    if (originRows.length > 0){
      let busquedaSplit = busqueda.toLowerCase().split(' ')
      let filterRows = originRows.filter(item => busquedaSplit.every(itera => item.patient.toLowerCase().includes(itera)))
      setDisplayRows(filterRows)
    }
  }, [busqueda]);

  /***
   * Actualiza los datos al cargar la pagina y los guarda en la variable setRows,
   */
  useEffect(() => {
    getPortalData();
  }, []);
  /***
   * Trae la informacion de la Api de portal_patient
   */
  const getPortalData = () => {
    axiosClient
      .get("/patients/portal_patient/")
      .then((response) => {
        console.log("datas", response.data);
        setOriginRows(response.data);
        setDisplayRows(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  /***
   * Funcion que actualiza el status del usuario("activo", "inactivo")
   * @returns {Promise<void>}
   */
  const changeStatus = async () => {
    await axiosClient
      .patch(`/patients/user/${userUpdate}/`, { is_active: userStatus })
      .then((response) => {
        setOpen(false);
        getPortalData();
      })
      .catch((error) => {});
  };

  const deleteUser = (user_id, id) => {
    setOpenDelete(true);
    setUserDelete({
      user_id: user_id,
      id: id,
    });
  };

  const userDelete = async () => {
    await axiosClient
      .delete(`/patients/portal_patient/${user_delete.id}/`)
      .then((response) => {})
      .catch((error) => {});
    setOpenDelete(false);
    getPortalData();
  };

  const { patient } = busqueda;
  /***
   * Muestra lista de usuarios y sistema de busqueda por usuario, status, opcion de habilitar y deshabilitar y eliminar usuario
   */
  return (
    <div className="container__content-body">
      <Title>
        {user_groups.includes("Chula Vista")
          ? "Portal de Miembros"
          : "Portal de Pacientes"}
      </Title>
      <SearchField
        onChange={obtenerDatosPaciente}
        id="standard-basic"
        label="Patient Names"
        name="patient"
        value={patient}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <FaSearch />
            </InputAdornment>
          ),
        }}
      />
      <Table style={{ margin: "10px" }}>
        <TableHead>
          <TableRow>
            <STableCell align="center">MediExcel</STableCell>
            <STableCell align="center">Excel ID</STableCell>
            <STableCell align="center">Name</STableCell>
            <STableCell align="center">Date of Birth</STableCell>
            <STableCell align="center">Email (Validated?)</STableCell>
            <STableCell align="center">Date Joined</STableCell>
            <STableCell align="center">Status</STableCell>
            <STableCell align="center"></STableCell>
            {/*<STableCell align="center"></STableCell>*/}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayRows.map((row) => (
            <STableRow
              color={
                user_groups.includes("Chula Vista") ? row.cov_active : true
              }
              key={row.credential}
            >
              {row.credential ? (
                <TableCell color="red">{row.credential}</TableCell>
              ) : (
                <TableCell align="center">{row.user_id}</TableCell>
              )}

              <TableCell component="th" scope="row">
                {`EXCEL-${row.member_number}`}
              </TableCell>
              <TableCell align="center">{row.patient}</TableCell>
              <TableCell align="center">{row.date_of_birth}</TableCell>
              <TableCell align="center">
                {row.email} &nbsp;{row.email_confirmed}
                {row.email_confirmed === true ? <CheckIcon /> : <NegatedIcon />}
              </TableCell>
              <TableCell align="center">{row.date_joined}</TableCell>
              <TableCell align="center">
                {row.is_active}
                {row.is_active === true ? (
                  <span style={{ color: "green" }}>ACTIVE</span>
                ) : (
                  <span style={{ color: "red" }}>DISABLE</span>
                )}
              </TableCell>
              <TableCell>
                {row.is_active === true ? (
                  <Button
                    onClick={() => handleClickOpen(row.user_id, false)}
                    variant="contained"
                    color="secondary"
                  >
                    Disable User
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleClickOpen(row.user_id, true)}
                    variant="contained"
                    color="primary"
                  >
                    Enable User
                  </Button>
                )}
              </TableCell>
            </STableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Portal Patient"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change user status?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={changeStatus} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Portal Patient"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this user??
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={userDelete} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PatientPortalDetail;
