import React, { useReducer } from "react";
import notaContext from "./notaContext.jsx";
import notaReducer from "./notaReducer.jsx";
import {
  SET_NOTA_INICIAL,
  SET_NOTA_DIAGNOSTICO,
  SET_NOTA_MOTIVO_CONSULTA,
  SET_NOTA_ANTROPOMETRICOS,
  SET_NOTA_INDICADORES_BIOQUIMICOS,
  SET_NOTA_INDICADORES_CLINICOS,
  SET_NOTA_ANTECEDENTES_FAMILIARES,
  SET_NOTA_ESTILO_VIDA,
  SET_NOTA_DIAGONOSTICO_CIE,
  SET_NOTA_ERROR,
  SET_NOTA_INDICADORES_DIETETICOS,
  SET_NOTA_DATOS_DIETETICOS,
  SET_NOTA_RECORDATORIO_24,
  SET_NOTA_DIETA_HABITUAL,
  SET_NOTA_FRECUENCIA_CONSUMO,
  SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES,
  SET_NOTA_DIAGNOSTICO_NUTRICIONAL,
  SET_NOTA_PLAN_NUTRICIONAL_NOTA,
  SET_NOTA_PLAN_TRATAMIENTO,
  SET_NOTA_PLAN_NUTRICIONAL,
  SET_NOTA_PLAN_NUTRICIONAL2,
  SET_NOTA_PLAN_NUTRICIONAL3,
  SET_NOTA_PLAN_NUTRICIONAL4,
  SET_FORMULAS,
  SET_ID_NOTA,
  SET_DETALLE_NOTA,
  SET_NOTA_PLAN_NUTRICIONAL_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL2_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL3_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL4_DETALLE,
  SET_NOTA_RECORDATORIO_24_DETALLE,
  SET_NOTA_DIETA_HABITUAL_DETALLE,
  SET_NOTA_FRECUENCIA_CONSUMO_DETALLE,
  SET_NOTA_PLAN_NUTRICIONAL_FORMULAS,
  SET_FETCH_DATA
} from "../../types";

// import axios from "axios";
import axiosClient from "../../config/axios.js";
// import tokenAuth from "../../config/tokenAuth.js";

const NotaProvider = (props) => {
  const initialState = {
    id_nota: null,
    nota_detalle: false,
    nota_inicial: {},
    nota_diagnostico: {},
    nota_motivo_consulta: {},
    nota_antropometricos: {},
    nota_indicadores_bioquimicos: {},
    nota_indicadores_clinicos: {},
    nota_antecedentes_familiares: {},
    nota_estilo_vida: {},
    nota_indicadores_dieteticos: {},
    nota_datos_dieteticos: {},
    nota_recordatorio24: {},
    nota_recordatorio24_detalle: {},
    nota_dieta_habitual: {},
    nota_dieta_habitual_detalle: {},
    nota_frecuencia_consumo: [],
    nota_frecuencia_consumo_detalle: [],
    nota_frecuencia_consumo_observaciones: "",
    nota_diagnostico_nutricional: {},
    cie_result: [],
    nota_plan_nutricional_nota: "",
    nota_plan_tratamiento: "",
    nota_plan_nutricional: {},
    nota_plan_nutricional_formulas: {},
    nota_plan_nutricional_detalle: {},
    nota_plan_nutricional_parte2: [],
    nota_plan_nutricional_parte2_detalle: [],
    nota_plan_nutricional_parte3: [],
    nota_plan_nutricional_parte4: [],
    nota_plan_nutricional_parte4_detalle: [],
    nota_nutricional_formulas: {},
    nota_nutricional_error: {
      nota_inicial: false,
      nota_diagnostico: true,
      nota_motivo_consulta: true,
      nota_antropometricos: true,
      nota_indicadores_bioquimicos: false,
      nota_indicadores_clinicos: true,
      nota_antecedentes_familiares: false,
      nota_estilo_vida: true,
      nota_indicadores_dieteticos: true,
      nota_datos_dieteticos: false,
      nota_recordatorio24: false,
      nota_dieta_habitual: false,
      nota_frecuencia_consumo: false,
      nota_diagnostico_nutricional: false,
      cie_result: false,
      nota_plan_nutricional_nota: false,
      nota_plan_tratamiento: false,
      //nota_plan_nutricional: true,
      nota_plan_nutricional_parte2: false,
      //nota_plan_nutricional_parte3: true,
      //nota_plan_nutricional_parte4: true,
      nota_nutricional_formulas: false,
      nota_frecuencia_consumo_observaciones: false,
      fetch_data:false
    },
  };

  const [state, dispatch] = useReducer(notaReducer, initialState);

  const setNotaInicial = (data) => {
    dispatch({
      type: SET_NOTA_INICIAL,
      payload: data,
    });
  };

  const setNotaDiagnostico = (data) => {
    dispatch({
      type: SET_NOTA_DIAGNOSTICO,
      payload: data,
    });
  };

  const setNotaMotivoMonsulta = (data) => {
    dispatch({
      type: SET_NOTA_MOTIVO_CONSULTA,
      payload: data,
    });
  };

  const setNotaAntropometricos = (data) => {
    dispatch({
      type: SET_NOTA_ANTROPOMETRICOS,
      payload: data,
    });
  };

  const setNotaIndicadoresBioquimicos = (data) => {
    dispatch({
      type: SET_NOTA_INDICADORES_BIOQUIMICOS,
      payload: data,
    });
  };

  const setNotaIndicadoresClinicos = (data) => {
    dispatch({
      type: SET_NOTA_INDICADORES_CLINICOS,
      payload: data,
    });
  };

  const setNotaAntecedentesFamiliares = (data) => {
    dispatch({
      type: SET_NOTA_ANTECEDENTES_FAMILIARES,
      payload: data,
    });
  };

  const setNotaEstiloVida = (data) => {
    dispatch({
      type: SET_NOTA_ESTILO_VIDA,
      payload: data,
    });
  };

  /* Dispatching the setCIEResult action to the reducer. */
  const setCIEResult = (data) => {
    dispatch({
      type: SET_NOTA_DIAGONOSTICO_CIE,
      payload: data,
    });
  }

  const setNotaIndicadoresDieteticos = (data) => {
    dispatch({
      type: SET_NOTA_INDICADORES_DIETETICOS,
      payload: data,
    });
  };

  const setNotaDatosDieteticos = (data) => {
    dispatch({
      type: SET_NOTA_DATOS_DIETETICOS,
      payload: data,
    });
  };

  const setNotaRecordatorio24 = (data) => {
    dispatch({
      type: SET_NOTA_RECORDATORIO_24,
      payload: data,
    });
  };
  const setNotaRecordatorio24Detalle = (data) => {
    dispatch({
      type: SET_NOTA_RECORDATORIO_24_DETALLE,
      payload: data,
    });
  };

  const setNotaDietaHabitual = (data) => {
    dispatch({
      type: SET_NOTA_DIETA_HABITUAL,
      payload: data,
    });
  };
  const setNotaDietaHabitualDetalle = (data) => {
    dispatch({
      type: SET_NOTA_DIETA_HABITUAL_DETALLE,
      payload: data,
    });
  };

  const setNotaFrecuenciaConsumo = (data) => {
    dispatch({
      type: SET_NOTA_FRECUENCIA_CONSUMO,
      payload: data,
    });
  };
  const setNotaFrecuenciaConsumoDetalle = (data) => {
    dispatch({
      type: SET_NOTA_FRECUENCIA_CONSUMO_DETALLE,
      payload: data,
    });
  };

  const setNotaDiagnosticoNutricional = (data) => {
    dispatch({
      type: SET_NOTA_DIAGNOSTICO_NUTRICIONAL,
      payload: data,
    });
  };

  /* Dispatching the setNotaPlanNutricionalNota action to the reducer. */
  const setNotaPlanNutricionalNota = (data) => {    
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL_NOTA,
      payload: data,
    });
  };

  /* Dispatching the setNotaPlanNutricionalNota action to the reducer. */
  const setNotaPlanNutricionalTratamiento = (data) => {    
    dispatch({
      type: SET_NOTA_PLAN_TRATAMIENTO,
      payload: data,
    });
  };

  const setNotaPlanNutricional = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL,
      payload: data,
    });
  };

  const setNotaPlanNutricional2 = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL_FORMULAS,
      payload: data,
    });
  };

  const setNotaPlanNutricionalDetalle = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL_DETALLE,
      payload: data,
    });
  };

  const setNotaPlanNutricionalParte2 = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL2,
      payload: data,
    });
  };
  const setNotaPlanNutricionalParte2Detalle = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL2_DETALLE,
      payload: data,
    });
  };

  const setNotaPlanNutricionalParte3 = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL3,
      payload: data,
    });
  };
  const setNotaPlanNutricionalParte3Detalle = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL3_DETALLE,
      payload: data,
    });
  };

  const setNotaPlanNutricionalParte4 = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL4,
      payload: data,
    });
  };
  const setNotaPlanNutricionalParte4Detalle = (data) => {
    dispatch({
      type: SET_NOTA_PLAN_NUTRICIONAL4_DETALLE,
      payload: data,
    });
  };

  const setNotaNutricionalFormulas = (data) => {
    dispatch({
      type: SET_FORMULAS,
      payload: data,
    });
  };

  const setNotaFrecuenciaConsumoObservaciones = (data) => {
    dispatch({
      type: SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES,
      payload: data,
    });
  };

  const setIdNota = (data) => {
    dispatch({
      type: SET_ID_NOTA,
      payload: data,
    });
  };

  const setDetalle = (data) => {
    dispatch({
      type: SET_DETALLE_NOTA,
      payload: data,
    });
  };

  const setNotaNutricionalError = (data) => {
    dispatch({
      type: SET_NOTA_ERROR,
      payload: data,
    });
  };

  const setFetchData = (data) => {
    dispatch({
      type: SET_FETCH_DATA,
      payload: data,
    });
  }

  const resetData = () => {
    setNotaInicial([]);
    setNotaDiagnostico([]);
    setNotaMotivoMonsulta([]);
    setNotaAntropometricos([]);
    setNotaIndicadoresBioquimicos([]);
    setNotaIndicadoresClinicos([]);
    setNotaAntecedentesFamiliares([]);
    setNotaEstiloVida([]);
    setCIEResult([]);
    setNotaIndicadoresDieteticos([]);
    setNotaRecordatorio24([]);
    setNotaDietaHabitual([]);
    setNotaFrecuenciaConsumo([]);
    setNotaFrecuenciaConsumoObservaciones([])
    setNotaDiagnosticoNutricional([]);
    setNotaPlanNutricionalNota([]);
    setNotaPlanNutricionalTratamiento([]);
    setNotaPlanNutricional([]);
    setNotaPlanNutricionalParte2([]);
    setNotaPlanNutricionalParte3([]);
    setNotaPlanNutricionalParte4([]);
  }

  const setLoadDetalleNota = async (id) => {
    
    let api_detalle = await axiosClient.get(`/api/nutrition_note/${id}/`);
    api_detalle = await api_detalle.data;
    let nota_inicial = {
      patient: api_detalle.patient_name,
      fecha: api_detalle.created_date,
      person_id: api_detalle.data_patient.id,
      age: api_detalle.data_patient.person.age,
      birth_date: api_detalle.data_patient.person.birth_date,
    };
    let diagnostico = {
      observations: api_detalle.observations,
    };

    let motivo_consulta = {
      reason_for_consultation: api_detalle.reason_for_consultation,
    };

    let antoprometicos = {
      date: api_detalle.created_date.split(" - ")[0],
      height: api_detalle.height,
      ideal_weight: api_detalle.ideal_weight,
      size: api_detalle.size,
      waist_circumference: api_detalle.waist_circumference,
      hip_circumference: api_detalle.hip_circumference,
      fat_percentage: api_detalle.fat_percentage,
      muscle_percentage: api_detalle.muscle_percentage,
      visceral_fat_percentage: api_detalle.visceral_fat_percentage,
      utility_height: api_detalle.utility_height,
      
    };
    let indicadorea_biomedicos = {
      biochemical_indicators: api_detalle.biochemical_indicators,
    };

    let indicadores_clinicos = {
      actual_problems: api_detalle.actual_problems,
      diarrhea: api_detalle.diarrhea,
      constipation: api_detalle.constipation,
      gastritis: api_detalle.gastritis,
      ulcers: api_detalle.ulcers,
      sickness: api_detalle.sickness,
      threw_up: api_detalle.threw_up,
      colitis: api_detalle.colitis,
      gastroesophageal_reflux: api_detalle.gastroesophageal_reflux,
      observations: api_detalle.observations,
    };

    let antecedentes_familiares = {
      obesity: api_detalle.obesity,
      diabetes: api_detalle.diabetes,
      aht: api_detalle.aht,
      cancer: api_detalle.cancer,
      hypercolesterolemia: api_detalle.hypercolesterolemia,
      hypertriglyceridaemia: api_detalle.hypertriglyceridaemia,
      cardiopatia_isquemica: api_detalle.cardiopatia_isquemica,
    };

    let estilo_vida = {
      activity: api_detalle.activity,
      exercise: api_detalle.exercise,
      kind: api_detalle.kind,
      frequency: api_detalle.frequency,
      duration: api_detalle.duration,
    };

    let indicadores_dieteticos = {
      meals_aday: api_detalle.meals_aday,
      supplementation: api_detalle.supplementation,
      dislikes: api_detalle.dislikes,
      preferences: api_detalle.preferences,
      allergy_or_intolerance_to_any_food:
        api_detalle.allergy_or_intolerance_to_any_food,
    };

    //setNotaDatosDieteticos
    let result_datos_dieteticos = {};
    if (api_detalle.data_dietary_data === "frecuncy_of_24hrs") {
      //recordatorio24hrs
      result_datos_dieteticos = {
        dieta_option: "recordatorio24hrs",
        dieta_habitual: false,
        recordatorio24hrs: true
      };
    } else {
      result_datos_dieteticos = {
        dieta_option: "dieta_habitual",
        dieta_habitual: true,
        recordatorio24hrs: false
      };
    }
    setNotaDatosDieteticos(result_datos_dieteticos);

    let recordatorio_24hrs = api_detalle.data_frecuency_24hrs;
    let dieta_habitual = api_detalle.data_dietary_diet;

    let diagnostico_nutricional = {
      nutritional_diagnosis: api_detalle.nutritional_diagnosis,
      // cie: api_detalle.data_cie10,
    };

    let activity_options = {
      0: "1.0",
      1: "1.1",
      2: "1.2",
      3: "1.3",
      4: "1.4",
      5: "1.5",
      6: "1.6",
    };

    let formula_options = {
      0: "requerimientoMifflinstJeor",
      1: "harrisBennedict",
      2: "pediatricaInstituteMedicine",
      3: "formulaPredictiva1",
      4: "formulaPredictiva2",
      5: "formulaPredictiva3",
      6: "formulaPredictiva4",
    };

    let plan_nutricional = {
      gender:
        api_detalle.data_plan_nutri.gender === 0 ? "femenino" : "masculino",
      physical_activity: parseFloat(
        activity_options[api_detalle.data_plan_nutri.physical_activity]
      ),
      requirement_other: api_detalle.data_plan_nutri.other_kcal,
      requirement_type: api_detalle.data_plan_nutri.other_kcal
        ? "kcal_otro"
        : "resultado_kcal",
      formula: formula_options[api_detalle.data_plan_nutri.formula],
    };

    let plan_nutri2 = [
      
      {
        product: "Proteina",
        kcal:null,
        gramos:null,
        gr_kg:null,
        porcentaje_vet:parseInt(api_detalle.data_plan_nutri.percentage_protein)
      },
      {
        product: "Hidratos de carbono",
        kcal:null,
        gramos:null,
        gr_kg:null,
        porcentaje_vet: parseInt(api_detalle.data_plan_nutri.percentage_hydratescr),
      },
      {
        product: "Lipidos",
        kcal:null,
        gramos:null,
        gr_kg:null,
        porcentaje_vet: parseInt(api_detalle.data_plan_nutri.percentage_lipids)
        
      },
    ];

    //frecuencia de consumo

    let plan_frecuencia_consumo = api_detalle.data_frecuency_food;

    let plan_frecuencia_consumo_observations;
    try {
      plan_frecuencia_consumo_observations = plan_frecuencia_consumo[0].observations
    } catch (error) {
      
    }

    // plan nutri 3
    let plan_nutri3 = api_detalle.data_nutri_plan_food;

    //plan nutri4
    let plan_nutri4 = api_detalle.data_nutri_plan_quantity;

   
    let plan_nutri3_detalle = [];
    plan_nutri3.forEach((item) => {
      plan_nutri3_detalle.push({
        equivalents: item.equivalents_name,
        amount: item.amount,
        kcal: item.const_kcal,
        protein: item.const_protein,
        lipid: item.const_lipid,
        hydrates_of_carbon: item.const_hdc,
      });
    });

    plan_nutri3 = plan_nutri3_detalle;

    setFetchData(true)

  
    setNotaInicial(nota_inicial);
    setNotaDiagnostico(diagnostico);
    setNotaMotivoMonsulta(motivo_consulta);
    setNotaAntropometricos(antoprometicos);
    setNotaIndicadoresBioquimicos(indicadorea_biomedicos);
    setNotaIndicadoresClinicos(indicadores_clinicos);
    setNotaAntecedentesFamiliares(antecedentes_familiares);
    setNotaEstiloVida(estilo_vida);
    setNotaIndicadoresDieteticos(indicadores_dieteticos);
    setNotaRecordatorio24(recordatorio_24hrs);
    setNotaDietaHabitual(dieta_habitual);
    setNotaFrecuenciaConsumo(plan_frecuencia_consumo);
    setNotaFrecuenciaConsumoObservaciones(plan_frecuencia_consumo_observations)
    setNotaDiagnosticoNutricional(diagnostico_nutricional);
    setNotaPlanNutricional(plan_nutricional);
    setNotaPlanNutricionalParte2(plan_nutri2);
    setNotaPlanNutricionalParte3(plan_nutri3);
    setNotaPlanNutricionalParte4(plan_nutri4);
    setTimeout(function(){
      setFetchData(false) },
     10000);
  };

  return (
    <notaContext.Provider
      value={{
        nota_inicial: state.nota_inicial,
        nota_diagnostico: state.nota_diagnostico,
        nota_motivo_consulta: state.nota_motivo_consulta,
        nota_antropometricos: state.nota_antropometricos,
        nota_indicadores_bioquimicos: state.nota_indicadores_bioquimicos,
        nota_indicadores_clinicos: state.nota_indicadores_clinicos,
        nota_antecedentes_familiares: state.nota_antecedentes_familiares,
        nota_estilo_vida: state.nota_estilo_vida,
        nota_indicadores_dieteticos: state.nota_indicadores_dieteticos,
        nota_datos_dieteticos: state.nota_datos_dieteticos,
        nota_recordatorio24: state.nota_recordatorio24,
        nota_dieta_habitual: state.nota_dieta_habitual,
        nota_frecuencia_consumo: state.nota_frecuencia_consumo,
        nota_diagnostico_nutricional: state.nota_diagnostico_nutricional,
        nota_plan_nutricional_nota: state.nota_plan_nutricional_nota,
        nota_plan_tratamiento: state.nota_plan_tratamiento,
        nota_plan_nutricional: state.nota_plan_nutricional,
        nota_plan_nutricional_parte2: state.nota_plan_nutricional_parte2,
        nota_plan_nutricional_parte3: state.nota_plan_nutricional_parte3,
        nota_plan_nutricional_parte4: state.nota_plan_nutricional_parte4,
        nota_nutricional_formulas: state.nota_nutricional_formulas,
        cie_result: state.cie_result,
        nota_frecuencia_consumo_observaciones:
          state.nota_frecuencia_consumo_observaciones,
        id_nota: state.id_nota,
        nota_detalle: state.nota_detalle,
        nota_plan_nutricional_parte2_detalle:
          state.nota_plan_nutricional_parte2_detalle,
        nota_plan_nutricional_parte4_detalle:
          state.nota_plan_nutricional_parte4_detalle,
        nota_plan_nutricional_detalle: state.nota_plan_nutricional_detalle,
        nota_recordatorio24_detalle: state.nota_recordatorio24_detalle,
        nota_dieta_habitual_detalle: state.nota_dieta_habitual_detalle,
        nota_frecuencia_consumo_detalle: state.nota_frecuencia_consumo_detalle,
        nota_nutricional_error: state.nota_nutricional_error,
        nota_plan_nutricional_formulas: state.nota_plan_nutricional_formulas,
        fetch_data:state.fetch_data,
        setNotaInicial,
        setNotaDiagnostico,
        setNotaMotivoMonsulta,
        setNotaAntropometricos,
        setNotaIndicadoresBioquimicos,
        setNotaIndicadoresClinicos,
        setNotaAntecedentesFamiliares,
        setNotaEstiloVida,
        setNotaIndicadoresDieteticos,
        setNotaDatosDieteticos,
        setNotaRecordatorio24,
        setNotaDietaHabitual,
        setNotaFrecuenciaConsumo,
        setNotaDiagnosticoNutricional,
        setCIEResult,
        setNotaPlanNutricional,
        setNotaPlanNutricional2,
        setNotaPlanNutricionalParte2,
        setNotaPlanNutricionalParte3,
        setNotaPlanNutricionalParte4,
        setNotaNutricionalFormulas,
        setNotaFrecuenciaConsumoObservaciones,
        setIdNota,
        setLoadDetalleNota,
        setDetalle,
        setNotaPlanNutricionalNota,
        setNotaPlanNutricionalTratamiento,
        setNotaPlanNutricionalParte2Detalle,
        setNotaPlanNutricionalParte3Detalle,
        setNotaPlanNutricionalParte4Detalle,
        setNotaPlanNutricionalDetalle,
        setNotaRecordatorio24Detalle,
        setNotaDietaHabitualDetalle,
        setNotaFrecuenciaConsumoDetalle,
        setNotaNutricionalError,
        resetData,
        setFetchData
      }}
    >
      {props.children}
    </notaContext.Provider>
  );
};

export default NotaProvider;
