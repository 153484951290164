import React, { useContext, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import NotaInicial from "./NotaInicial";
import Diagnostico from "./Diagnostico";
import MotivoConsulta from "./MotivoConsulta";
import Antropometricos from "./Antropometricos";
import IndicadoresBioquimicos from "./IndicadoresBioquimicos";
import IndicadoresClinicos from "./IndicadoresClinicos";
import AntecedentesHeredoFamiliares from "./AntecedentesHeredoFamiliares";
import DatosDieteticos from "./DatosDieteticos";
import IndicadoresDieteticos from "./IndicadoresDieteticos";
import EstiloVida from "./EstiloVida";
import DiagnosticoNutricional from "./DiagnosticoNutricional";
import Recordatorio24hrs from "./Recordatorio24hrs";
import FrecuenciaConsumo from "./FrecuenciaConsumo";
import PlanNutricional from "./PlanNutricional";
import PlanNutricional2 from "./PlanNutricional2";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

import Doc from "../../../layout/pdf/DocService";
import PdfContainer from "../../../layout/pdf/PdfContainer";
import { useHistory, Link } from "react-router-dom";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 14,
    top: 30,
    left: 0,
    right: 50,
    textAlign: 'right',
    color: "blue",
    backgroundColor: "#7e8084",
  },
  header: {
    fontSize: 14,
    marginBottom: 100,
    textAlign: "justify",
    color: "black",
    fontFamily: "Arial",
    backgroundColor: "#7e8084",
  },
});



const NotaNutricionPdf = () => {
  const notaState = useContext(notaContext);
  const {
    nota_plan_nutricional,
    nota_nutricional_formulas,
    setLoadDetalleNota,
    setDetalle,
  } = notaState;
  const [note_id, setNoteId] = useState(null);
  const history = useHistory();

  const extra_data = JSON.parse(localStorage.getItem("extra_data"));

  const Pdf = async (id) => {
    //setNota(id);
    localStorage.setItem("nota", id);
    setLoadDetalleNota(id);
    setDetalle(true);
    history.push("/nota-nutriologia/pdf");
  };

  useEffect(() => {
    Pdf(note_id);
  }, [note_id]);

  useEffect(() => {
    setNoteId(Object.values(extra_data)[3]);
  });

  let resultado_plan1 =
    nota_plan_nutricional.requirement_type === "kcal_otro"
      ? nota_plan_nutricional.requirement_other
      : nota_nutricional_formulas.requirement_value;

  const createPdf = (html) => Doc.createPdf(html);

  return (
    <div className="container__content-body">
      <PdfContainer createPdf={createPdf}>
        <View style={styles.NotaInicial}>
          <Text style={styles.header} fixed>
            <h1>
              <b>Valoracion inicial de Nutrición</b>
            </h1>{'\n'}
            <h2>Nutrción</h2>
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, Page }) =>
              `${pageNumber} / ${Page}`
            }
            fixed
          />
          <h1>HISTORIA CLÍNICA NUTRIOLÓGICA</h1>

          <NotaInicial />
          <Diagnostico />
          <MotivoConsulta />
          <Antropometricos />
          <IndicadoresBioquimicos />
          <IndicadoresClinicos />
          <AntecedentesHeredoFamiliares />
          <EstiloVida />
          <IndicadoresDieteticos />
          <DatosDieteticos />
          <Recordatorio24hrs />
          {/* <FrecuenciaConsumo /> */}
          <DiagnosticoNutricional />
          <Text>
            <h1>Plan Nutricional (Parte 1)</h1>
            <b> Resultado </b> = &nbsp; {resultado_plan1} Kcal
          </Text>
          <div className="plan_nutri1-pdf">
            <PlanNutricional />
          </div>

          <PlanNutricional2 />
        </View>
        <Text>
          <div>
            Paseo de los Heroes 2507, Zona Rio, 22010 Tijuana BC
            <h1>HOSPITAL EXCEL</h1>
          </div>
        </Text>
      </PdfContainer>
    </div>
  );
};

export default NotaNutricionPdf;
