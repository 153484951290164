import React, {useContext} from 'react';
import navigationContext from '../../context/navigation/navigationContext';
import styled from "styled-components";
import { Link } from "react-router-dom";

const BreadcumbContent = styled.div`
  display:flex;
  width:100%;
  height:3rem;
  padding:1rem;
  margin-bottom:0;
`;

export const BreadcumItem = styled(Link)`
  color: blue;
  font-size: 1rem;
  text-decoration:none;

  &:before {
      content:"|";
      margin: 0 0.5rem;
      color:black;
  }
`;

const BreadcumbItemNoLink = styled.span`
  color: black;
  font-size: 1rem;
  text-decoration:none;

  &:before {
      content:"|";
      margin: 0 0.5rem;
      color:black;
  }
`;

const Breadcumb = () => {
    const navigationState = useContext(navigationContext);
    const { breadcumbs } = navigationState;

    if (breadcumbs.length === 0 ) return null;
    return ( 
        <BreadcumbContent>
            {
                breadcumbs.map( (item, index) => {
                    if (!item.link){
                        return <BreadcumbItemNoLink key={index} to={item.path}>{item.title}</BreadcumbItemNoLink>
                    }
                    return <BreadcumItem key={index} to={item.path}>{item.title}</BreadcumItem> 
                })
            }
        </BreadcumbContent>
     );
}
 
export default Breadcumb;