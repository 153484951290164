import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html) => {
    savePDF(html, { 
      paperSize: ['11in','17in'],
      fileName: 'nota_nutricional.pdf',
      margin: "1cm",
      landscape:false,
      scale:0.45,
      repeatHeaders:true,
      author:"he",
      title:"Nota nutricional"
    })
  }
}

const Doc = new DocService();
export default Doc;