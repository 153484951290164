import {
    SET_FORM_DATA,
    SET_FORM_DATA_ERROR,
    SET_POST_STATUS,
    SET_PATCH_STATUS,
    SET_USER_LIST,
    SET_PASSWORD_DATA,
    SET_PDF_DATA,
    SET_PASSWORD_ERRORS,
    SET_USER_LIST_PAGES
} from '../../types';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, action) => {
    switch(action.type){
        case SET_FORM_DATA:
            return {
                ...state,
                form_data: action.payload
            }
        case SET_FORM_DATA_ERROR:
            return {
                ...state,
                form_data_error: action.payload
            }
        case SET_POST_STATUS:
            return {
                ...state,
                post_finallized: action.payload
            }
        case SET_PATCH_STATUS:
            return {
                ...state,
                patch_finallized: action.payload
            }
        case SET_USER_LIST:
            return {
                ...state,
                user_list: action.payload
            }
        case SET_PASSWORD_DATA:
            return {
                ...state,
                password_data: action.payload
            }
        case SET_PASSWORD_ERRORS:
            return {
                ...state,
                password_errors: action.payload
            }
        case SET_PDF_DATA:
            return {
                ...state,
                pdf_data: action.payload
            }
        case SET_USER_LIST_PAGES:
            return {
                ...state,
                user_list_pages: action.payload
            }
        default:
            return state;
    }
}