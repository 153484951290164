import React, {
    Fragment,
    useState,
    useContext,
    useEffect,
    useRef,
} from "react";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const Diagnostico = () => {
    const notaState = useContext(notaContext);
    const {
        setNotaNutricionalError,
        nota_nutricional_error,
        nota_detalle,
        nota_diagnostico,
        setNotaDiagnostico,
    } = notaState;

    const handleChange = (e) => {
        setNotaDiagnostico({
            ...nota_diagnostico,
            [e.target.name]: e.target.value,
        });
    };

    const validations = () => {
        if (!nota_diagnostico.observations) {
            setNotaNutricionalError({
                ...nota_nutricional_error,
                nota_diagnostico: true,
            });
        } else {
            setNotaNutricionalError({
                ...nota_nutricional_error,
                nota_diagnostico: false,
            });
        }
    };

    useEffect(() => {
        if (!nota_detalle) {
            validations();
        }
    }, [nota_diagnostico]);

    useEffect(() => {
        if (nota_detalle) {
            validations();
        }
    }, [nota_detalle]);

    let { observations } = nota_diagnostico;

    let error = nota_nutricional_error.nota_diagnostico;

    return (
        <div className="diagnostico-nota">
            <h1>Diagnostico {error && <b>*</b>}</h1>
            <div>
                <TextArea
                    // className="texarea"
                    name="observations"
                    label="Diagnostico *"
                    value={observations}
                    onChange={handleChange}
                    disabled={nota_detalle ? true : false}
                    cols="50"
                    rows="10"
                />
            </div>
        </div>
    );
};

export default Diagnostico;