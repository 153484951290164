import React, { useEffect, useContext } from "react";
import { Switch, Route, useLocation, withRouter } from "react-router-dom";

import Login from "../pages/Auth/Login";
import Navbar from "./Navbar";
import SubNavbar from "./SubNavbar";
import Sidebar from "./Menu/Sidebar";
import Content from "./Content";
// import Footer from "./Footer";
import Overlay from "./Overlay";
import Breadcumb from "./Breadcumb";
import navigationContext from "../../context/navigation/navigationContext";
import authContext from "../../context/auth/authContext";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import axiosClient from '../../config/axios';


const Page = () => {
  const navigationState = useContext(navigationContext);
  const { updatePage } = navigationState;

  const authState = useContext(authContext);
  const { user, setUser, setExtraData } = authState;


  const location = useLocation();

  useEffect(() => {
    updateUser()
  }, []);

  useEffect(() => {
    updatePage(location);
  }, [location]);

  const updateUser = async () => {
 
    const token = localStorage.getItem('token');
      await axiosClient.get(`/accounts/auth/login/?token=${token}`)
      .then(response => {
        setUser(response.data.result)
        let extra_data = localStorage.getItem('extra_data');
        extra_data = JSON.parse(extra_data)
        setExtraData(extra_data)
      }).catch(err => {
        localStorage.removeItem('token')
      })
  }

  if (!user) return null;

  return (
    <>
      
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        
      </Switch>
      <div className="flex-container">
        {/* <Sidebar /> */}
        <div className="container">
          <Navbar />
          <SubNavbar />
          <div className="container__content">
            <Breadcumb />
            <authContext.Provider value={user}>
            <Content />
            </authContext.Provider>
            {/* <Footer /> */}
          </div>
        </div>
        <Overlay />
      </div>
    </>
  );
};

export default withRouter(Page);
