// eslint-disable-next-line
import {
    GET_USER,
    SUCCESSFULL_LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    SET_USER,
    SET_EXTRADATA
} from '../../types';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state,action) => {
    switch(action.type){
        case SUCCESSFULL_LOGIN:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                authenticated:true,
                msg:null,
                loading:false
            }
        case LOGOUT:
        case ERROR_LOGIN:
            localStorage.removeItem('token');
            return {
                ...state,
                token:null,
                user:null,
                authenticated:null,
                msg:action.payload,
                loading:false
            }
        case GET_USER:
            return {
                ...state,
                authenticated:true,
                user: action.payload,
                loading:false
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case SET_EXTRADATA:
            return {
                ...state,
                extra_data: action.payload,
            }
        default:
            return state;
    }
}