/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {DataGrid} from '@material-ui/data-grid';

let DisabilityTrackHistoryTable = ({history}) => {
    let columns = [
        {
            field: 'id',
            headerName: '',
            valueGetter: instace => instace.row.history_id,
            flex: 1,
            hide: true
        },
        {
            field: 'history_date',
            headerName: 'Fecha del Cambio',
            valueGetter: i => {return i.row.history_date.replace('T', ' ')},
            flex: 1.5
        },
        {
            field: 'username',
            headerName: 'Usuario Que Modifico',
            flex: 1
        },
        {
            field: 'coordinator_date',
            headerName: 'Fecha de Entrega a Coordinador',
            flex: 1
        },
        {
            field: 'physician_deliver',
            headerName: 'Fecha de entrega a medico tratante',
            flex: 1
        },
        {
            field: 'physician_received',
            headerName: 'Fecha de recepcion por medico tratante',
            flex: 1
        },
        {
            field: 'admin_date',
            headerName: 'Fecha de Entrega a Archivo',
            flex: 1
        },
        {
            field: 'notify_date',
            headerName: 'Fecha de Aviso a miembro',
            flex: 1
        },
        {
            field: 'deliver_date',
            headerName: 'Fecha de Entrega a Miembro',
            flex: 1
        },
    ];

    return (
        <DataGrid rows={history} columns={columns} pageSize={10}
                      rowsPerPageOptions={[10, 25, 50, 100]}/>
    )
};

export default DisabilityTrackHistoryTable;