import axiosClient from '../../config/axios.js';

const singleEndPoint = '/disabilities/api/disabilities/create/';
const ApiEndPoint =  'disabilities/api/disabilities/detail/';

function disabilityUrl(id, endpoint=singleEndPoint){
  return `${endpoint}${id}/`
}

export async function getDisabilities(params={}) {
  return axiosClient.get(singleEndPoint, {params:params})
}

export function getDisability(id) {
  return axiosClient.get(disabilityUrl(id))
}


export function getDisabilityDetail(id) {
  return axiosClient.get(disabilityUrl(id, ApiEndPoint))
}

export function saveDisability(disability) {
  try{
  if (disability.id){
    const updateDisability = {...disability};
    delete updateDisability.id;
    return axiosClient.put(disabilityUrl(disability.id), updateDisability)
  }
  return axiosClient.post(ApiEndPoint, disability)
  }
  catch (excep){
    console.log(excep.message)
  }
}

export function updateDisabilityDates(disability_id, dates) {
     if (disability_id){
       return axiosClient.patch(disabilityUrl(disability_id, ApiEndPoint), dates)
     }
}

export function deleteDisability(id) {
  return axiosClient.patch(disabilityUrl(id), {deleted: true})
}

export function downloadDisabilities(params) {
    window.open(axiosClient.defaults.baseURL + '/disabilities/lista_incapacidades/download/csv?'+new URLSearchParams(params))
}

export async function getCategories() {
  return axiosClient.get('/disabilities/api/disabilities/categories/')
}

export async function getDisabilityFiles(params) {
  return axiosClient.get('/disabilities/api/disabilities/files/', params)
}

export function saveDisabilityFile(file) {
  try{
  if (file.id){
    const updateDisability = {...file};
    delete updateDisability.id;
    return axiosClient.put(`/disabilities/api/disabilities/files/${file.id}/`, updateDisability)
  }
  return axiosClient.post('/disabilities/api/disabilities/files/', file)
  }
  catch (excep){
    console.log(excep.message)
  }
}