/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "../../../../node_modules/@material-ui/core/Grid/Grid";
import ControlledSelect from "../../common/ControlledSelect";
import axiosClient from "../../../config/axios";
import { toast } from 'react-toastify';
import FormControlLabel from "../../../../node_modules/@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "../../../../node_modules/@material-ui/core/Switch/Switch";

export function ChangeAppTypeDialog({isOpen, handleClose, appointment}) {
    const [typeUpdating, setTypeUpdating] = useState(false)
    const CONSULTATION_TYPES = [
        {value: 0, label:'Primera Vez'},
        {value: 1, label:'Subsecuente'},
        {value: 2, label:'Procedimiento'},
    ]
    const CONSULTATION_SUBTYPES = [
        {value: 0, label: 'Presencial'},
        {value: 1, label: 'Procedimiento'},
        {value: 2, label: 'Preventivo'},
        {value: 3, label: 'Prenatal'},
        {value: 4, label: 'Administrativa'},
        {value: 5, label: 'Telemedicina'},
        {value: 6, label: 'Triage'},
        {value: 7, label: 'Rapida'},
        {value: 8, label: 'Doctor Line'},
        {value: 9, label: 'Llamada'},
    ]
    const [internal_app, setInternalApp] = useState(appointment)

    const handleChange = ({target: input}) => {
        const data = {...internal_app};
        data[input.name] = input.value;
        setInternalApp(data);
    }

    useEffect(() => {
        setInternalApp(appointment)
    }, [appointment])

    async function changeAppType(evt){
        setTypeUpdating(true)
        let app_data = {
            appointment_id: internal_app.id,
            consultation_type: internal_app.consultation_type,
            consultation_subtype: internal_app.consultation_subtype,
            start_hour: internal_app.start_hour
        }
        const {data} = await axiosClient.post('/schedule/calendario/tipo-consulta', app_data);
        let success = data.success
        if(success)
            toast.success('Tipo De Consulta Actualizado Correctamente')
        else
            toast.error('Error Al Editar Tipo De Consulta')
        handleClose(success, internal_app)
        setTypeUpdating(false)
    }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown maxWidth="md" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Cambiar Tipo de Cita</DialogTitle>
        <DialogContent>
          <DialogContentText>
              <b>Paciente: </b>{internal_app.patient_first_name} {internal_app.patient_last_name}&nbsp;-&nbsp;
              <b>Fecha: </b>{internal_app.start_date}<br/>
            Seleccione la informacion que desea  editar.
          </DialogContentText>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <ControlledSelect value={internal_app.consultation_type} options={CONSULTATION_TYPES} onChange={handleChange} name="consultation_type"
                id="filter_by" label="Tipo de Cita:" disabled={true}/>
                </Grid>
                <Grid item md={4}>
                    <ControlledSelect value={internal_app.consultation_subtype} options={CONSULTATION_SUBTYPES} onChange={handleChange} name="consultation_subtype"
                id="filter_by" label="Modalidad de Consulta:"/>
                </Grid>
                <Grid item md={3}>
                    <TextField style={{width: '100%'}} id="time" label="Hora de la Cita" type="time" defaultValue={internal_app.start_hour}
                               InputLabelProps={{ shrink: true, }} name="start_hour" disabled
                               inputProps={{ step: 300, }} // 5 min
                     />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={changeAppType} color="primary" disabled={typeUpdating}>
            Guardar
          </Button>
          <Button onClick={() => handleClose(false, {})} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export function CancelAppDialog({isOpen, handleClose, appointment, user}) {
    const [typeUpdating, setTypeUpdating] = useState(false)
    const [reason, setReason] = useState('')
    const [penalization, setPenalization] = useState(false)
    const [internal_app, setInternalApp] = useState(appointment)

    const CANCELATION_REASONS = [
        {value: '', label: ''},
        {value: 'Paciente no puede asistir', label: 'Paciente no puede asistir'},
        {value: 'Cita se va cambiar con otro doctor', label: 'Cita se va cambiar con otro doctor'},
        {value: 'Medico ausente', label: 'Medico ausente'},
        {value: 'Motivos administrativos', label: 'Motivos administrativos'},
        {value: 'Paciente llega tarde', label: 'Paciente llega tarde'},
        {value: 'Por COVID-19', label: 'Por COVID-19'},
    ]

    const handleChange = ({target: input}) => {
        if(input.name === 'reason') {
            const _reason = input.value;
            setReason(_reason);
        }
        if(input.name === 'penalization'){
            console.log(input.checked)
            const _penalization = input.checked;
            setPenalization(_penalization);
        }
    }

    useEffect(() => {
        setInternalApp(appointment)
    }, [appointment])

    async function cancelApp(evt){
        setTypeUpdating(true)
        let app_data = {
            appointment_id: internal_app.id,
            status: 'Cancel',
            reason: reason,
            has_penalization: penalization,
            user: user.id
        }
        const {data} = await axiosClient.post('/schedule/calendario/cancelar', app_data);
        let success = data.success
        if(success)
            toast.success('Cita Marcada Como Cancelada Exitosamente')
            if (penalization)
                toast.info('Se ha generado el cargo por cancelacion, pasar al detalle de procedimientos para realizar el cobro')
        else
            toast.error('Error Al Editar Consulta')
        let internal = {...internal_app}
        internal['status'] = 'Cancel'
        setInternalApp(internal)
        setReason('')
        setPenalization(false)
        console.log(internal_app, success)
        handleClose(success, internal)
        setTypeUpdating(false)
    }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown maxWidth="md" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Cancelar Cita</DialogTitle>
        <DialogContent>
          <DialogContentText>
              <b>Paciente: </b>{internal_app.patient_first_name} {internal_app.patient_last_name}&nbsp;-&nbsp;
              <b>Fecha: </b>{internal_app.start_date}&nbsp;-&nbsp;
              <b>Hora: </b>{internal_app.start_hour}
          </DialogContentText>
            <Grid container spacing={3}>
                <Grid item md={9}>
                    <ControlledSelect value={reason} options={CANCELATION_REASONS} onChange={handleChange}
                                      name="reason"
                                      label="Por favor seleccione el motivo de la cancelacion."/>
                </Grid>
                {appointment.specialty === 55 &&
                <FormControlLabel
                    control={
                        <Switch checked={penalization} onChange={handleChange} name="penalization" color="primary" />
                    }
                    label="Generar cargo por cancelacion D9999?." />
                }
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelApp} color="primary" disabled={typeUpdating || reason===''}>
            Cancelar
          </Button>
          <Button onClick={() => handleClose(false, {})} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}