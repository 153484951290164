import React, {useContext } from 'react'
import styled from "styled-components";
import navigationContext from '../../context/navigation/navigationContext'

export const OverlayComponent = styled.div`
    width:100vw;
    height:100vh;
    background-color: #000b;
    position:absolute;
    transition:1;

    @media only screen and (min-width:1048px){
        display:none;
    }
`;

const Overlay = () => {
    const navigationState = useContext(navigationContext);
    const {sidebar, hideSidebar} = navigationState;

    if (!sidebar) return null;
    return ( 
      <OverlayComponent  onClick={hideSidebar}/>
        
    );
}
 
export default Overlay;