import React, {useEffect} from "react";

const Home = () => {
    useEffect(() => {
    window.location.href = "https://excel.network"
    },[]);
    return (
        <div className="container__content-body">
            <h1>REDIRECT</h1>
        </div>
    );
}

export default Home;

