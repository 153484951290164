import React from "react";
import styled from "styled-components";

const IconButton = styled.button`
  position: absolute;
  height: calc(1.5em + 0.75rem + 2px);
  top: 0;
  border-radius: 0.25rem;
  right: 0px;
  z-index: 2;
  border: none;
  cursor: pointer;
  color: ${props => props.color};
  background-color: transparent;
  transform: translateX(2px);

  .icon {
    padding: 15px;
    text-align: center;
  }
`;

const Input = ({ name, label, error, className, icon, color, action, ...rest }) => {
  return (
    <div className={`${className} form-group`}>
      <label htmlFor={name}>{label}</label>
      <div className="input-icon">
        <input
          {...rest}
          name={name}
          id={name}
          className={error ? "input-error form-control" : "form-control"}
        />
        {icon && <IconButton type="button" onClick={action} color={`${color}`}>{icon}</IconButton>}
      </div>
      {typeof error === "string" && (
        <div className="alert alert-danger no-padding">{error}</div>
      )}
    </div>
  );
};

export default Input;
