import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../../../common/Input";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import { roundUp } from "../../../common/utils";
import Select from "../../../common/Select";

const PlanNutricional = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_inicial,
    nota_detalle,
    nota_plan_nutricional,
    nota_antropometricos,
    setNotaPlanNutricional,
    setNotaNutricionalFormulas,
    setNotaPlanNutricional2,
  } = notaState;

  const [value_formulas, setValueFormulas] = useState({
    requirement_value: "",
    requirement_other: "",
    predictive_value: "",
    mifflin_value: "-",
    harris_value: "-",
    pediatric_value: "-",
  });
  
  
  useEffect(() => {
    setNotaNutricionalFormulas(value_formulas);
  }, [value_formulas]);

  useEffect(() => {
    if (!nota_detalle){
      validations();
      setNotaPlanNutricional(nota_plan_nutricional);
      formulasGeneral();
    }
  }, [
    nota_plan_nutricional,
    nota_antropometricos
  ]);

  
  useEffect(() => {
    if (nota_detalle){
      validations();
      setNotaPlanNutricional(nota_plan_nutricional);
      formulasGeneral();
    }
  }, []);

  const handleChange = (e) => {
    setNotaPlanNutricional({
      ...nota_plan_nutricional,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSelect = (e) => {
    let formula_predictiva = predictive_formulas_values[e.target.value].id;
    let formula_name = "formulaPredictiva";
    switch (formula_predictiva) {
      case 0:
        formula_name = "formulaPredictiva1";
        break;
      case 1:
        formula_name = "formulaPredictiva2";
        break;
      case 2:
        formula_name = "formulaPredictiva3";
        break;
      case 3:
        formula_name = "formulaPredictiva4";
        break;
      default:
        formula_name = "formulaPredictiva";
        break;
    }
    setNotaPlanNutricional({
      ...nota_plan_nutricional,
      formula: formula_name,
    });
  };

  const predictive_formulas_values = [
    {
      id: 0,
      name: ` * 20  =  ${20 * nota_antropometricos.utility_height}`,
      value: 20 * nota_antropometricos.utility_height,
    },
    {
      id: 1,
      name: `* 25 =  ${25 * nota_antropometricos.utility_height}`,
      value: 25 * nota_antropometricos.utility_height,
    },
    {
      id: 2,
      name: `* 30 =  ${30 * nota_antropometricos.utility_height}`,
      value: 30 * nota_antropometricos.utility_height,
    },
    {
      id: 3,
      name: `* 35 =  ${35 * nota_antropometricos.utility_height}`,
      value: 35 * nota_antropometricos.utility_height,
    },
  ];

  const actividad_fisica_opcion = [0, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6];

  const validations = () => {
    let error = false;
    if (!nota_plan_nutricional.gender) {
      error = true;
    }
    if (!nota_plan_nutricional.physical_activity) {
      error = true;
    }
    if (!nota_plan_nutricional.requirement_type) {
      error = true;
    }

    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_plan_nutricional: error,
    });
  };

  const formulasGeneral = () => {
    let requerimientoMifflinstJeor =
      10 * nota_antropometricos.utility_height +
      6.25 * nota_antropometricos.size -
      5 * nota_inicial.age;
    requerimientoMifflinstJeor =
      "masculino" === nota_plan_nutricional.gender
        ? requerimientoMifflinstJeor + 5
        : requerimientoMifflinstJeor - 161;

    let value =
      66.5 +
      13.75 * nota_antropometricos.utility_height +
      5.003 * nota_antropometricos.size -
      6.775 * nota_inicial.age;
    let value2 =
      655.1 +
      9.563 * nota_antropometricos.utility_height +
      1.85 * nota_antropometricos.size -
      4.676 * nota_inicial.age;
    let harrisBennedict =
      "masculino" === nota_plan_nutricional.gender ? value : value2;

    let formula_niña =
      322 -
      26 * nota_inicial.age +
      (504 * nota_antropometricos.size) / 100 +
      11.6 * nota_antropometricos.utility_height;
    let formula_niño =
      79 -
      34.2 * nota_inicial.age +
      (730 * nota_antropometricos.size) / 100 +
      15.3 * nota_antropometricos.utility_height;
    let pediatricaInstituteMedicine =
      "masculino" === nota_plan_nutricional.gender && nota_inicial.age < 18
        ? formula_niño
        : formula_niña;

    let value_actividad = parseFloat(nota_plan_nutricional.physical_activity);
    let value_formula = nota_plan_nutricional.formula;

    let formulas_predictiva1 = predictive_formulas_values[0].value;
    let formulas_predictiva2 = predictive_formulas_values[1].value;
    let formulas_predictiva3 = predictive_formulas_values[2].value;
    let formulas_predictiva4 = predictive_formulas_values[3].value;

    let tmp_predictive_values = {
      formulaPredictiva1: predictive_formulas_values[0].name,
      formulaPredictiva2: predictive_formulas_values[1].name,
      formulaPredictiva3: predictive_formulas_values[2].name,
      formulaPredictiva4: predictive_formulas_values[3].name,
    };

    let predictive_value = "";
    try {
      predictive_value = tmp_predictive_values[value_formula];
    } catch (error) {
      predictive_value = "";
    }

    let tmp_results = {
      requerimientoMifflinstJeor: roundUp(requerimientoMifflinstJeor),
      harrisBennedict: roundUp(harrisBennedict),
      formulaPredictiva1: roundUp(formulas_predictiva1),
      formulaPredictiva2: roundUp(formulas_predictiva2),
      formulaPredictiva3: roundUp(formulas_predictiva3),
      formulaPredictiva4: roundUp(formulas_predictiva4),
      pediatricaInstituteMedicine: roundUp(pediatricaInstituteMedicine),
    };

    let result = tmp_results[value_formula];
    //setPlanNutricional2
    setValueFormulas({
      ...value_formulas,
      formula_result: result,
      requirement_value: roundUp(result * value_actividad),
      mifflin_value:
        value_formula === "requerimientoMifflinstJeor"
          ? roundUp(requerimientoMifflinstJeor)
          : "-",
      harris_value:
        value_formula === "harrisBennedict" ? roundUp(harrisBennedict) : "-",
      pediatric_value:
        value_formula === "pediatricaInstituteMedicine"
          ? roundUp(pediatricaInstituteMedicine)
          : "-",
      predictive_value: predictive_value,
    });
  };

  let {
    requirement_type,
    gender,
    formula,
    physical_activity,
    requirement_other,
  } = nota_plan_nutricional;

  let {
    requirement_value,
    predictive_value,
    mifflin_value,
    harris_value,
    pediatric_value,
  } = value_formulas;

  let error = nota_nutricional_error.nota_plan_nutricional;
  
  return (
    <div className="plan-nutricional">
      <h1>Plan Nutricional(Parte1) {error && <b>*</b>}</h1>

      <div>
        <div>
          <div>
            <b> Sexo *</b>{" "}
          </div>
          <div>
            <label>Masculino </label>
            <input
              className="radio-button"
              type="radio"
              value="masculino"
              onChange={handleChange}
              name="gender"
              disabled={nota_detalle ? true : false}
              checked={gender === "masculino" ? true : false}
            />
          </div>
          <div>
            <label>Femenino </label>
            <input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="femenino"
              name="gender"
              disabled={nota_detalle ? true : false}
              checked={gender === "femenino" ? true : false}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Requerimiento Mifflinst Jeor &nbsp; </label>
            <input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="requerimientoMifflinstJeor"
              name="formula"
              disabled={nota_detalle ? true : false}
              checked={formula === "requerimientoMifflinstJeor" ? true : false}
            />
          </div>
          <div>
            <label>Harris Bennedict &nbsp;</label>
            <input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="harrisBennedict"
              name="formula"
              disabled={nota_detalle ? true : false}
              checked={formula === "harrisBennedict" ? true : false}
            />
          </div>
          <div>
            <label>Fórmulas Predictivas &nbsp;</label>
            <input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="formulaPredictiva"
              name="formula"
              disabled={nota_detalle ? true : false}
              checked={
                formula === "formulaPredictiva" ||
                formula === "formulaPredictiva1" ||
                formula === "formulaPredictiva2" ||
                formula === "formulaPredictiva3" ||
                formula === "formulaPredictiva4"
                  ? true
                  : false
              }
            />
          </div>
          <div>
            <label>Pediátrica/institute of Medicine &nbsp;</label>
            <input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="pediatricaInstituteMedicine"
              name="formula"
              disabled={nota_detalle ? true : false}
              checked={formula === "pediatricaInstituteMedicine" ? true : false}
            />
          </div>
        </div>
        <div>
          <Input
            type="text"
            className="formulas"
            name="formula_mifflin_resultado"
            value={mifflin_value || "-"}
            label="Fórmula *"
            disabled={nota_detalle ? true : false}
            readOnly
          />
          <Input
            disabled
            type="text"
            className="formulas"
            name="formula_bennedict_resultado"
            value={harris_value || "-"}
            label="Fórmula *"
            disabled={nota_detalle ? true : false}
            readOnly
          />

          {nota_detalle ? (
            <Input
              disabled
              type="text"
              className="formulas"
              name="formula_bennedict_resultado"
              value={predictive_value || "-"}
              label="Fórmula *"
              disabled={nota_detalle ? true : false}
              readOnly
            />
          ) : (
            <Select
              type="select"
              className="formulas"
              name="formula_faooms_resultado"
              onChange={handleChangeSelect}
              label="Fórmula *"
              options={
                formula === "formulaPredictiva" ||
                formula === "formulaPredictiva1" ||
                formula === "formulaPredictiva2" ||
                formula === "formulaPredictiva3" ||
                formula === "formulaPredictiva4"
                  ? predictive_formulas_values
                  : []
              }
              disabled={
                formula === "formulaPredictiva" ||
                formula === "formulaPredictiva1" ||
                formula === "formulaPredictiva2" ||
                formula === "formulaPredictiva3" ||
                formula === "formulaPredictiva4"
                  ? false
                  : true
              }
            />
          )}

          <Input
            disabled
            type="text"
            className="formulas"
            name="formula_pediatric_resultado"
            value={pediatric_value || "-"}
            label="Fórmula *"
            disabled={nota_detalle ? true : false}
            readOnly
          />
        </div>
        <div>
          <h2>Requerimiento Total </h2>
        </div>
        <div>
          <div>
            <label>Actividad fisica * &nbsp;</label>
            <select
              name="physical_activity"
              onChange={handleChange}
              value={physical_activity}
              disabled={nota_detalle ? true : false}
            >
              {actividad_fisica_opcion.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div>
          <div>
            <div>Resultado </div>
            <Input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="resultado_kcal"
              name="requirement_type"
              disabled={nota_detalle ? true : false}
              checked={requirement_type === "resultado_kcal" ? true : false}
            />
            <Input
              disabled
              type="text"
              name="resultadoPlanNutricional1"
              value={
                requirement_type === "resultado_kcal" ? requirement_value : ""
              }
              disabled={nota_detalle ? true : false}
              checked={formula === "resultado_kcal" ? true : false}
              readOnly
            />
            <span>kcal</span>
          </div>
          <div>
            <Input
              className="radio-button"
              type="radio"
              onChange={handleChange}
              value="kcal_otro"
              name="requirement_type"
              disabled={nota_detalle ? true : false}
              checked={requirement_type === "kcal_otro" ? true : false}
            />
            <span>otro *</span>

            <Input
              type="number"
              onChange={handleChange}
              name="requirement_other"
              disabled={requirement_type === "kcal_otro" ? false : true}
              value={requirement_type === "kcal_otro" ? requirement_other : ""}
              disabled={nota_detalle ? true : false}
              checked={formula === "other_kcal" ? true : false}
            />
            <div className="algo">kcal</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanNutricional;
