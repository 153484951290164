const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  min_mobileS: ` @media (min-width: ${size.mobileS})`,
  min_mobileM: ` @media (min-width: ${size.mobileM})`,
  min_mobileL: ` @media (min-width: ${size.mobileL})`,
  min_tablet: ` @media (min-width: ${size.tablet})`,
  min_laptop: ` @media (min-width: ${size.laptop})`,
  min_laptopL: ` @media (min-width: ${size.laptopL})`,
  min_desktop: ` @media (min-width: ${size.desktop})`,
  min_desktopL: ` @media (min-width: ${size.desktop})`,

  max_mobileS: ` @media (max-width: ${size.mobileS})`,
  max_mobileM: ` @media (max-width: ${size.mobileM})`,
  max_mobileL: ` @media (max-width: ${size.mobileL})`,
  max_tablet: ` @media (max-width: ${size.tablet})`,
  max_laptop: ` @media (max-width: ${size.laptop})`,
  max_laptopL: ` @media (max-width: ${size.laptopL})`,
  max_desktop: ` @media (max-width: ${size.desktop})`,
  max_desktopL: ` @media (max-width: ${size.desktop})`,
};
