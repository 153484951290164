import axiosClient from '../../config/axios.js';

const ApiEndPoint =  'patients/api/update_info_requests/';

function info_requestUrl(id, endpoint=ApiEndPoint){
  return `${endpoint}${id}/`
}

export async function getInfoRequests(params={}) {
  return axiosClient.get(ApiEndPoint, {params:params})
}

export function updateInfoRequest(info_request_id, data) {
     if (info_request_id){
       return axiosClient.patch(info_requestUrl(info_request_id, ApiEndPoint), data)
     }
}
