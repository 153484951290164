import React, { Fragment, useState, useEffect, useContext } from "react";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import Input from "../../../common/Input";
import { roundUp } from "../../../common/utils";

const PlanNutricional2Child = ({ nutriment, addFormula }) => {
  const notaState = useContext(notaContext);
  const {
    nota_antropometricos,
    nota_plan_nutricional,
    nota_nutricional_formulas,
    nota_detalle,
  } = notaState;

  const [local_product, setLocalProduct] = useState({
    product: nutriment.product,
    kcal: nutriment.kcal,
    gramos: nutriment.gramos,
    gr_kg: nutriment.gr_kg,
    porcentaje_vet: nutriment.porcentaje_vet,
  });

  useEffect(() => {
    if (!nota_detalle) {
      calculos(local_product.porcentaje_vet)
    };
  }, [nota_antropometricos,
    nota_plan_nutricional,
    nota_nutricional_formulas
  ]);

  useEffect(() => {
    if (nota_detalle) {
      calculos(local_product.porcentaje_vet)
    };
  }, []);

  useEffect(() => {
    addFormula(local_product);
  }, [local_product]);

  const handleChangeInput = (e) => {
    const re = /^[0-9\b]+$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      let porcentaje = 0;
      porcentaje = parseInt(e.target.value) || 0;
      calculos(porcentaje);
    }
  };

  const calculos = (porcentaje) => {
    let gramos_resultado_kg = 0;
    let kcal = 0;
    let gramos_resultado = 0;

    if (nota_plan_nutricional.requirement_type === "kcal_otro") {
      console.log(nota_plan_nutricional);
      kcal = (porcentaje / 100) * nota_plan_nutricional.requirement_other ;
    } else {
      kcal =
        (porcentaje / 100) * nota_nutricional_formulas.requirement_value ;
    }

    if (product !== "Lipidos") {
      gramos_resultado = kcal / 4;
    } else {
      gramos_resultado = kcal / 9;
    }
    gramos_resultado_kg = gramos_resultado / nota_antropometricos.utility_height;

    setLocalProduct({
      ...local_product,
      product: nutriment.product,
      kcal: roundUp(kcal),
      gramos: roundUp(gramos_resultado),
      gr_kg: roundUp(gramos_resultado_kg),
      porcentaje_vet: porcentaje,
    });
  };

  let { product, porcentaje_vet, kcal, gramos, gr_kg } = local_product;

  return (
    <div className="plan-nutricional-2">
      <div>
        <div>
          <label>{product}</label>
          <Input
            type="text"
            name="porcentaje_vet"
            value={porcentaje_vet}
            onChange={!nota_detalle ? handleChangeInput : undefined}
            disabled={nota_detalle ? true : false}
          />
          <Input disabled type="text" name="kcal" value={kcal} readOnly />
          <Input disabled type="text" name="gramos" value={gramos} readOnly />
          <Input disabled type="text" name="gr_kg" value={gr_kg} readOnly />
        </div>
      </div>
    </div>
  );
};

export default PlanNutricional2Child;
