import React, { useContext } from "react";
import {
    FaBars,
    FaAngleLeft, FaUser,
} from "react-icons/fa";
//import SearchBar from "../common/SearchBar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import navigationContext from "../../context/navigation/navigationContext";
import authContext from "../../context/auth/authContext";
import {device} from "../common/ScreenSizes";
import logo from "../../assets/excel_white.png";
import logo_medi from "../../assets/mediexcel_logo_white.png";
//import { device } from "../common/ScreenSizes";

export const MenuIconsResponsive = styled(Link)`
  color: #fff;
  font-size: 1.5rem;

  @media only screen and (min-width: 1048px) {
    display: none;
  }
`;

export const MenuIcons = styled(Link)`
  color: #fff;
  font-size: 1.5rem;

  @media only screen and (max-width: 1048px) {
    display: none;
  }
`;

const MenuUserIcon = styled.div`
  color: #fff;
  font-size: 1.5rem;

  &:hover {
    & > div {
      display: block;
    }
  }
`;
/*
const FloatMenu = styled.div`
  width: 200px;
  height: 300px;
  background: #0000;
  position: absolute;
  right: 25px;
  display: none;
  &:hover {
    cursor: pointer;
  }
`;

const FloatMenuTriangle = styled.div`
  width: 0;
  height: 0;
  margin-left: 80%;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eee;
  border-left: 15px solid transparent;
`;

const FloatContent = styled.div`
  width: 200px;
  background: #eee;
  position: absolute;
  box-shadow: 2px 2px 5px #999;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const FloatContentItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.7rem 1.3rem;
  width: 100%;
  color: #484848;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    background: #ccc;
    border-left: 4px solid #3b6ca6;
    font-weight: bold;
  }

  & > svg {
    margin-right: 1rem;
  }
`;

const FloatLastContentItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.7rem 1.3rem;
  width: 100%;
  color: #484848;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 1.2rem;

  &:hover {
    background: #ccc;
    border-left: 4px solid #3b6ca6;
    font-weight: bold;
  }

  & > svg {
    margin-right: 1rem;
  }
`;

const Separator = styled.div`
  content: "";
  position: absolute;
  left: 8%;
  width: 84%;
  height: 1px;
  z-index: 1;
  background: #ccc;
  margin-top: 0.7rem;
`;
 */
const UserName = styled.p`
  display: none;
  ${device.min_laptop} {
    display: inline-block;
    font-size: 1rem;
    margin-right: 1rem;
  }
`;

const LogoContainer = styled.div`
  height: 10% !important;
  object-fit: cover;
`;

const Logo = styled.img`
	height: 7rem;
	&:hover {
		cursor: pointer;
	}
`;

const Navbar = () => {
  const navigationState = useContext(navigationContext);
  const { sidebar, showSidebar, hideSidebar } = navigationState;
  const authState = useContext(authContext);
  const { user, setUser } = authState;
  const user_groups = user["user"]["groups"];

  return (
    <div className={user_groups.includes("Chula Vista") ? 'container__navbar_medi' : 'container__navbar'}>
      <div>
      <a href="https://excel.network">
              <Logo
                src={user_groups.includes("Chula Vista") ? logo_medi : logo}
                alt="MediExcel"
              />
            </a>
        {/* {!sidebar ? (
          <MenuIconsResponsive to="#" className="container__navbar-icons">
            <FaBars onClick={showSidebar} />
          </MenuIconsResponsive>
        ) : (
          <MenuIconsResponsive to="#" className="container__navbar-icons">
            <FaBars onClick={hideSidebar} />
          </MenuIconsResponsive>
        )}

        {sidebar ? (
          <MenuIcons to="#" className="container__navbar-icons">
            <FaAngleLeft onClick={hideSidebar} />
          </MenuIcons>
        ) : (
          <MenuIcons to="#" className="container__navbar-icons">
            <FaBars onClick={showSidebar} />
          </MenuIcons>
        )} */}
      </div>
      {/*<SearchBar />*/}

      {user && (<MenuUserIcon className="container__navbar-icons">
        <UserName>Bienvenido: { user.user.name }</UserName>
        <FaUser />
        {/*<FaEllipsisV />*/}

        {/*<FloatMenu>*/}
          {/*<FloatMenuTriangle></FloatMenuTriangle>*/}
          {/*<FloatContent>*/}
            {/*<nav>*/}
              {/*<div>*/}
                {/*<FloatContentItem>*/}
                  {/*<FaUserCircle />*/}
                  {/*Perfil*/}
                {/*</FloatContentItem>*/}
                {/*<FloatContentItem>*/}
                  {/*<FaQuestionCircle />*/}
                  {/*Ayuda*/}
                {/*</FloatContentItem>*/}
                {/*<Separator />*/}
                {/*<FloatLastContentItem to="/login">*/}
                  {/*<FaSignOutAlt />*/}
                  {/*Cerrar Sesion*/}
                {/*</FloatLastContentItem>*/}
              {/*</div>*/}
            {/*</nav>*/}
          {/*</FloatContent>*/}
        {/*</FloatMenu>*/}
      </MenuUserIcon>)}
    </div>
  );
};

export default Navbar;
