import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axiosClient from "../../../config/axios";
import Table from "./components/table";

const Title = styled.h1`
  text-align: center;
`;

const RegionContainer = styled.div`
  float: right;
`;

const RegionContainerForm = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  label:first-child {
    margin-right: 5px;
    font-weight: bold;
  }
`;

const RegionContainerFormInput = styled.div`
  margin-right: 5px;
`;

const SubtitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  Link{
    margin-left:10px;
  }
`;

const CircleBtn = styled(Link)`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: #7e8084;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid #7e8084;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 50%;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;


const Index = () => {
  //const [test, setTest] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosClient.get("/api/patient_training/");
      //setTest(result.data);
    };
    fetchData();
  }, []);

  let columns = [
    { label: "Folio de solicitud" },
    { label: "Fecha y Hora de Solicitud" },
    { label: "Categoría" },
    { label: "Fecha y Hora Inicial de Permiso" },
    { label: "Fecha y Hora Final de Permiso" },
    { label: "Estatus" },
    { label: "Descripción del Permiso" },
    { label: "Region" },
  ];

  return (
    <div className="container__content-body">
      <Title>Permisos Médicos</Title>
      <RegionContainer>
        <RegionContainerForm>
          <label>Región</label>
          <RegionContainerFormInput>
            <input type="radio" id="tj" name="tj" value="tj" />
            <label htmlFor="tj">Tijuana</label>
          </RegionContainerFormInput>
          <RegionContainerFormInput>
            <input type="radio" id="mxl" name="mxl" value="mxl" />
            <label htmlFor="mxl">Mexicali</label>
          </RegionContainerFormInput>
        </RegionContainerForm>
      </RegionContainer>
      <SubtitleContainer>
        <h2>Listado de Permisos Médicos</h2>
        <CircleBtn to='/permisos/medicos/crear'>+</CircleBtn>
      </SubtitleContainer>
      <Table columns={columns} />
    </div>
  );
};

export default Index;
