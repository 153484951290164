import React, { useState } from 'react';
import Joi from 'joi';
import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import {
FormHelperText
} from '@material-ui/core';
import BaseForm from '../../common/BaseForm';
import { FaFileUpload } from 'react-icons/fa';
import { getCategories, saveDisabilityFile } from '../../../data_services/disabilities/disability_service';
import { toast } from 'react-toastify';
import authContext from "../../../context/auth/authContext";
import { Cancel } from '@material-ui/icons';


class DisabilityFileForm extends BaseForm {
    static contextType = authContext
    state = {
        data: {disability: this.props.disability_id, category: "", title: "", attachment: undefined, uploaded_by: undefined},
        errors: {},
        categories: [],
    }

    validators = {
        id: Joi.number(),
        disability: Joi.number().required().label('Seguimiento'),
        category: Joi.string().required().label('Tipo de Archivo').messages({
            'any.required': 'Debe seleccionar una opción',
          }),
        title: Joi.string().required().label('Titulo del Archivo'),
        attachment: Joi.required().label('Archivo a subir'),
        uploaded_by: Joi.required().label('Usuario que guarda'),
    }


    async populateDisability(){
        let _data = {...this.state.data}
        _data['uploaded_by'] = this.context ? parseInt(this.context.user.id):null
        this.setState({data: _data})
    }

    async componentDidMount(){
        await this.populateDisability()
        const response = await getCategories()
        this.setState({categories: response.data})
    }

    mapToViewModel(disability){
        return {
            id: disability.id, 
            disability: disability.disability,
            category: disability.category,
            title: disability.title,
            attachment: disability.attachment,
            uploaded_by: disability.uploaded_by
        }
    }

    submitForm = async () => {
        const formData = new FormData()
        for (const key in this.state.data) {
            formData.append(key, this.state.data[key]);
          }
        try {
            const response = await saveDisabilityFile(formData)
            if (response.status === 200 || response.status === 201){
                toast.success('Guardado con exito!')
                this.setState({data: {
                    disability: this.props.disability_id, category: "", title: "", 
                    attachment: undefined, uploaded_by: this.context ? parseInt(this.context.user.id):null
                }})
                if (typeof this.props.onSave === 'function') {
                    this.props.onSave();
                }
                // this.props.history.push('/disabilities/tracking/history/')
            }     
        } catch (error) {
            toast.error(error.message)
        }
          
    }

    setSelectedFile = (file) =>{
        let _data = {...this.state.data}
        _data['attachment'] = file
        this.setState({data: _data})
        console.log(this.state.errors)
    }

    shorted = (fullStr, length) => {
        let str_len = fullStr.length
        if (str_len <= length) return fullStr
        else {
            let chunk = Math.floor(length/2) - 3
            return `${fullStr.substr(0, chunk)}...${fullStr.substr(str_len-chunk)}`
        }
    }

    render() {
        return ( <React.Fragment>
            <form onSubmit={this.handleSubmit} className={'container__content-body'}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.renderSelect('category', 'Tipo de Archivo', this.state.categories)}
                </Grid>
                <Grid item xs={12}>
                    {this.renderInput('title', 'Titulo')}
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="select-file">
                        <input name="attachment"
                            type="file"
                            id="select-file"
                            style={{ display: "none" }}
                            onChange={(e) => this.setSelectedFile(e.target.files[0])}
                        />
                        <Button variant="contained" color="primary" component="span">
                         <FaFileUpload /> Seleccionar archivo
                        </Button> {this.state.data.attachment && (<span>{this.shorted(this.state.data.attachment.name, 30)}
                            <IconButton color="secondary" onClick={() => this.setSelectedFile(null)}><Cancel/></IconButton>
                         </span>)}
                    </label>
                    {this.state.errors['attachment'] && <FormHelperText>{this.state.errors['attachment']}</FormHelperText>}
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    {this.renderButton('Guardar', 'primary')}
                </Grid>
            </Grid>
            </form>
        </React.Fragment> );
    }
}
 
export default  DisabilityFileForm;