import React, { useReducer } from "react";
import userCreationContext from "./userCreationContext.jsx";
import userCreationReducer from "./userCreationReducer.jsx";
import axiosClient from "../../config/axios";

import {
  SET_FORM_DATA,
  SET_FORM_DATA_ERROR,
  SET_POST_STATUS,
  SET_PATCH_STATUS,
  SET_USER_LIST,
  SET_PASSWORD_DATA,
  SET_PASSWORD_ERRORS,
  SET_PDF_DATA,
  SET_USER_LIST_PAGES
} from "../../types";

const UserCreationProvider = (props) => {
  const initialState = {
    form_data: {
      first_name: "",
      middle_name: "",
      first_last_name: "",
      second_last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      group_permissions: [],
    },
    form_data_error: {
      first_name: false,
      middle_name: false,
      first_last_name: false,
      second_last_name: false,
      email: false,
      password: false,
      password_confirmation: false,
      group_permissions: false,
    },
    post_finallized: false,
    patch_finallized: false,
    user_list: [],
    password_data: {
      password: "",
      password_confirmation: "",
      secret_key: "",
    },
    password_errors: {
      password: false,
      password_confirmation: false,
      secret_key: false,
    },
    pdf_data: {
      name: '',
      department:'',
      area: '',
      account: '',
      password: '',
    },
    user_list_pages: 1,
  };

  const [state, dispatch] = useReducer(userCreationReducer, initialState);

  const setFormData = async (data) => {
    await dispatch({
      type: SET_FORM_DATA,
      payload: data,
    });
  };

  const setPostStatus = async (data) => {
    await dispatch({
      type: SET_POST_STATUS,
      payload: data,
    });
  };

  const setPatchStatus = async (data) => {
    await dispatch({
      type: SET_PATCH_STATUS,
      payload: data,
    });
  };

  const setUserList = async (data) => {
    await dispatch({
      type: SET_USER_LIST,
      payload: data,
    });
  };

  const setUserListPages = async (data) => {
    await dispatch({
      type: SET_USER_LIST_PAGES,
      payload: data,
    });
  };

  const setPasswordData = async (data) => {
    await dispatch({
      type: SET_PASSWORD_DATA,
      payload: data,
    });
  }

  const setPasswordErrors = async (data) => {
    await dispatch({
      type: SET_PASSWORD_ERRORS,
      payload: data,
    });
  }

  const setPDFData = async (data) => {
    await dispatch({
      type: SET_PDF_DATA,
      payload: data,
    });
  }

  const cleanPasswordData = async () => {
    
    setPasswordErrors({
      password: false,
      password_confirmation: false,
      secret_key: false,
    });
    setPasswordData({
      password: "",
      password_confirmation: "",
      secret_key: "",
    });
  };

  const cleanFormData = async () => {
    setPostStatus(false);
    setFormData({
      first_name: "",
      middle_name: "",
      first_last_name: "",
      second_last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      group_permissions: [],
    });
    setFormDataError({
      first_name: false,
      middle_name: false,
      first_last_name: false,
      second_last_name: false,
      email: false,
      password: false,
      password_confirmation: false,
      group_permissions: false,
    });
  };

  const setFormDataError = async (data) => {
    await dispatch({
      type: SET_FORM_DATA_ERROR,
      payload: data,
    });
  };

  const postFormData = async (data) => {
    let errors = {
      first_name: !state.form_data.first_name
        ? "Primer nombre no puede estar vacio"
        : false,
      middle_name: false,
      first_last_name: !state.form_data.first_last_name
        ? "Apellido no puede estar vacio"
        : false,
      second_last_name: false,
      email: false,
      password: false,
      password_confirmation: false,
    };

    // Check if pasword contains numbers and letters and optionally special characters using regex
    let first_validation = true;
    let second_validation = true;
    if(!state.form_data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}/)){
      first_validation = false;
    }

    if (!state.form_data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/)){
      second_validation = false;
    }

    if (!first_validation && !second_validation) {
      errors.password =
        "La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número";
        errors.password_confirmation = true;
    }

    // Check if password and password confirmation are equal
    if (state.form_data.password !== state.form_data.password_confirmation) {
      errors.password = "Las contraseñas no coinciden";
      errors.password_confirmation = true;
    }

    // Check if password and password confirmation is empty
    if (
      state.form_data.password === "" ||
      state.form_data.password_confirmation === ""
    ) {
      errors.password = "La contraseña no puede estar vacia";
      errors.password_confirmation = true;
    }

    // Check Email is type email with regex
    if (
      state.form_data.email &&
      !state.form_data.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors.email = "Correo electrónico no valido";
    }

    // check if group_permissions is empty
    if (state.form_data.group_permissions.length === 0) {
      errors.group_permissions = "Debe seleccionar al menos un permiso";
    }

    setFormDataError(errors);
    let post_data = true;

    for (let key in errors) {
      if (errors[key]) {
        post_data = false;
      }
    }

    const data_post = state.form_data;
    data_post["created_by"] = data.user;
    data_post["updated_by"] = data.user;
    if (post_data) {
      axiosClient
        .post("/users-creation/userprofile/", data_post)
        .then((response) => {
          const user_list_tmp = state.user_list;
          user_list_tmp.unshift(response.data.instance);
          setUserList(user_list_tmp);
          setPDFData(response.data.pdf_data);
          cleanFormData();
          setPostStatus(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateUserStatus = async ({row_id, updated_by}) => {
    await axiosClient
      .patch(`/users-creation/userprofile/${row_id}/`, {
        // is_active: userStatus,
        origin: "inactive",
        updated_by: updated_by,
      })
      .then((response) => {
        const user_list_tmp = state.user_list;
        user_list_tmp.forEach((row, index, array) => {
          if (row.id === row_id) {
            array[index] = response.data;
          }else{
            array[index] = row;
          }
        });
        setUserList(user_list_tmp); 
      })
      .catch((error) => {
        console.error("ERROR RESPONSE", error);
      });
  };

  const updateUserPassword = async ({row_id, updated_by}) => {
    setPatchStatus(false);
    let errors = {
      password: false,
      password_confirmation: false,
      secret_key: false,
    };
    let first_validation = true;
    let second_validation = true;
    if(!state.password_data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}/)){
      first_validation = false;
    }

    if (!state.password_data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/)){
      second_validation = false;
    }

    if (!first_validation && !second_validation) {
      errors.password =
        "La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número";
        errors.password_confirmation = true;
    }

    if (state.password_data.password !== state.password_data.password_confirmation) {
      errors.password = "Las contraseñas no coinciden";
      errors.password_confirmation = true;
    }

    if (state.password_data.password === "" || state.password_data.password_confirmation === "") {
      errors.password = "La contraseña no puede estar vacia";
      errors.password_confirmation = true;
    }

    if (state.password_data.secret_key === "") {
      errors.secret_key = "La clave secreta no puede estar vacia";
    }

    if (state.password_data.secret_key !== "1903") {
      errors.secret_key = "La clave secreta es incorrecta";
    }

    setPasswordErrors(errors);
    let patch_data = true;

    for (let key in errors) {
      debugger
      if (errors[key]) {
        patch_data = false;
      }
    }
    if (patch_data) {
      await axiosClient
        .patch(`/users-creation/userprofile/${row_id}/`, {
          origin: "change_password",
          updated_by: updated_by,
          password: state.password_data.password,
          password_confirmation: state.password_data.password_confirmation,
          secret_key: parseInt(state.password_data.secret_key),
        })
        .then((response) => {
          const user_list_tmp = state.user_list;
          user_list_tmp.forEach((row, index, array) => {
            if (row.id === row_id) {
              array[index] = response.data;
            }else{
              array[index] = row;
            }
          });
          setUserList(user_list_tmp); 
          setPatchStatus(true);
        })
        .catch((error) => {
          console.error("ERROR RESPONSE", error);
        });
    }
  };

  const getUserList = ({page = 1, user_id}) => {
    axiosClient
      .get(`/users-creation/userprofile/?page=${page}&user=${user_id}`)
      .then((response) => {
        setUserListPages(Math.ceil(response.data.count/25));
        setUserList(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <userCreationContext.Provider
      value={{
        form_data: state.form_data,
        user_list: state.user_list,
        password_data: state.password_data,
        password_errors: state.password_errors,
        post_finallized: state.post_finallized,
        patch_finallized: state.patch_finallized,
        form_data_error: state.form_data_error,
        pdf_data:state.pdf_data,
        user_list_pages: state.user_list_pages,
        setFormData,
        getUserList,
        postFormData,
        cleanFormData,
        setPasswordData,
        updateUserStatus,
        setPDFData,
        setPasswordErrors,
        cleanPasswordData,
        updateUserPassword,
        
      }}
    >
      {props.children}
    </userCreationContext.Provider>
  );
};

export default UserCreationProvider;
