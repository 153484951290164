import React, { useEffect, useState, useContext } from "react";
import Pagination from '@mui/material/Pagination';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import {
  FaSearch,
  FaKey,
  FaUserAltSlash,
  FaUserCheck,
  FaRegEyeSlash,
  FaRegEye,
} from "react-icons/fa";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@mui/material/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import NewUserForm from "./NewUserForm";
import authContext from "../../../context/auth/authContext";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Input from "../../common/Input";
import userCreationContext from "../../../context/users_creation/userCreationContext";
import PdfCall from "./PdfCall";

const STableCell = styled(TableCell)`
  background-color: #c3c3c3;
  font-weight: bold !important;
  font-size: 1rem !important;
`;

const STableRow = styled(TableRow)`
  &:hover {
    background-color: #e6e6e6;
  }
`;

const UsersList = () => {
  const userCreationState = useContext(userCreationContext);
  const { 
    user_list,
    getUserList,
    post_finallized,
    postFormData,
    cleanFormData,
    setPDFData,
    updateUserStatus,
    cleanPasswordData,
    setPasswordData,
    password_data,
    password_errors,
    pdf_data,
    patch_finallized,
    updateUserPassword,
    user_list_pages
  } = userCreationState;
  const authState = useContext(authContext);
  const { user } = authState;

  
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [filter_type, setFilterType] = useState(0);
  const [local_user_list, setLocalUserList] = useState([]);
  const [open_active_user, setOpenActiveUser] = useState(false);
  const [open_change_password, setOpenChangePassword] = useState(false);
  const [row_id, setRowId] = useState(null);
  const [password_icon, setPasswordIcon] = useState(<FaRegEye />);
  const [password_type, setPasswordType] = useState("password");

  /***
   * Funcion de busqueda por nombre, segundo nombre, apellido paterno y apellido materno de usuario apartir del segundo
   * caracter ingresado por el usuario.
   */
  useEffect(() => {
    if (user_list.length > 0) {
      const search_split = search.toLowerCase().split(" ");
      const filterRowsText = user_list.filter((item) =>
      search_split.every(
          (itera) =>
            item.user_data.first_name.toLowerCase().includes(itera) ||
            item.user_data.last_name.toLowerCase().includes(itera) ||
            item.user_data.username.toLowerCase().includes(itera)
        )
      );

      /*
      0 = Todos
      1 = Activos
      2 = Inactivos
      user_data.is_active
      */
    const filterRowsStatus = filterRowsText.filter((item) => {
      if(filter_type === 0){
        return item;
      }else if(filter_type === 1){
        return item.user_data.is_active;
      }else if(filter_type === 2){
        return !item.user_data.is_active;
      }
    });
      setLocalUserList(filterRowsStatus);
    }
  }, [search, filter_type]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getUserList({'page':page,'user_id':user.id});
  }, []);

  useEffect(() => {
    if (patch_finallized) {
      setOpenChangePassword(false);
    }
  }, [patch_finallized]);

  useEffect(() => {
    setLocalUserList(user_list);
  }, [user_list]);

  useEffect(() => {
    getUserList({'page':page,'user_id':user.id});
  }, [page]);

  const handleClickOpenActiveUser = (row_id) => {
    setRowId(row_id);
    setOpenActiveUser(true);
  };
  
  const handleChangeStatus = async () => {
    await updateUserStatus({'row_id':row_id, 'updated_by':user.id});
    setOpenActiveUser(false);
  };

  const handleClickOpenChangePassword = (row_id) => {
    setRowId(row_id);
    cleanPasswordData();
    setPasswordIcon(<FaRegEye />);
    setPasswordType("password");
    setOpenChangePassword(true);
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    await updateUserPassword({
      'row_id':row_id,
      'updated_by':user.id
    });
    
  };

  function handleChangePasswordData(e) {
    e.preventDefault();
    setPasswordData({
      ...password_data,
      [e.target.name]: e.target.value,
    });
  }

  const showPassword = () => {
    setPasswordType(password_type === "password" ? "text" : "password");
    setPasswordIcon(
      password_type === "password" ? <FaRegEyeSlash /> : <FaRegEye />
    );
  };

  return (
    <div>
      <div className="table_actions">
        
      <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Usuario / Nombre o Apellido</FormLabel>
        <TextField
        autoComplete="off"
          onChange={(e) => setSearch(e.target.value)}
          id="username"
          placeholder="Nombre/ Apellido/ Usuario"
          variant="outlined"
          disabled={user_list.length === 0}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
        </FormControl>
        <FormControl className="radio_filter">
        <FormLabel id="demo-radio-buttons-group-label">Estado</FormLabel>
          <RadioGroup
          row
            defaultValue="all"
            name="radio-buttons-group"
          >
            <FormControlLabel onClick={()=>setFilterType(0)} value="all" control={<Radio />} label="Todos" />
            <FormControlLabel onClick={()=>setFilterType(1)} value="active" control={<Radio />} label="Activos" />
            <FormControlLabel onClick={()=>setFilterType(2)} value="inactive" control={<Radio />} label="Inactivos" />
          </RadioGroup>
        </FormControl>
        <FormControl>
        <PdfCall pdf_data={pdf_data} setPDFData={setPDFData}/>
        <NewUserForm
          user={user}
          post_finallized={post_finallized}
          postFormData={postFormData}
          cleanFormData={cleanFormData}
        />
        </FormControl>
      </div>
      <TableContainer
        component={Paper}
        style={{ width: "100%", marginTop: "40px" }}
      >
        <Table sx={{ minWidth: 200}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <STableCell align="center">Usuario</STableCell>
              <STableCell align="center">Nombre</STableCell>
              <STableCell align="center">Departamentos</STableCell>
              <STableCell align="center">Editado por</STableCell>
              <STableCell align="center">Fecha de edición</STableCell>
              <STableCell align="center" colSpan={2}>
                Acciones
              </STableCell>
            </TableRow>
          </TableHead>
          <TableBody > 
            {user_list.length > 0 ? (
              local_user_list.map((row, index) => (
                <STableRow key={index}>
                  <TableCell align="center">{row.user_data.username}</TableCell>
                  <TableCell align="center">
                    {row.user_data.first_name} {row.user_data.last_name}
                  </TableCell>
                  <TableCell align="center" key={index}>
                    <ul className="deparment_list">
                      {row.roles.length > 0 &&
                        row.roles.map((rols, index) => (
                          <li key={index}>
                            {rols.department.name} ({rols.role.name})
                          </li>
                        ))}
                    </ul>
                  </TableCell>
                  <TableCell align="center">
                    {row.updated_by.first_name} {row.updated_by.last_name}
                  </TableCell>
                  <TableCell align="center">{row.updated_at}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        row.user_data.is_active
                          ? "Desactivar usuario"
                          : "Activar Usuario"
                      }
                    >
                      <Button
                        onClick={() =>
                          handleClickOpenActiveUser(row.id)
                        }
                        variant="contained"
                        color={row.user_data.is_active ? "success" : "inherit"}
                      >
                        {row.user_data.is_active ? (
                          <FaUserAltSlash />
                        ) : (
                          <FaUserCheck />
                        )}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Cambiar contraseña">
                      <Button
                        onClick={() => handleClickOpenChangePassword(row.id)}
                        variant="contained"
                        color="info"
                        disabled={!row.user_data.is_active}
                      >
                        <FaKey />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </STableRow>
              ))
            ) : (
              <STableRow>
                <TableCell align="center" colSpan={7}>
                  No cuenta con usuarios registrados
                </TableCell>
              </STableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        (user_list_pages > 1 && filter_type === 0) 
        && (
      <Pagination className="pagination" count={user_list_pages} page={page} onChange={handleChangePage}/>
      )}
      
      <Dialog
        open={open_active_user}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Usuarios"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que desea realizar la acción?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              setRowId(null);
              setOpenActiveUser(false)}
              } 
              color="error">
            Cancelar
          </Button>
          <Button onClick={handleChangeStatus} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: "100%" }}>
        <Dialog open={open_change_password}>
          <DialogTitle>Cambiar Contraseña</DialogTitle>
          <form onSubmit={updatePassword}>
            <DialogContent>
              <Grid item xs={12}>
                <Input
                  autoFocus
                  autoComplete="off"
                  name="password"
                  id="password"
                  label="Nueva Contraseña"
                  value={password_data.password}
                  onChange={handleChangePasswordData}
                  type={password_type}
                  icon={password_icon}
                  color={"#66aaff"}
                  action={() => showPassword()}
                  required
                  error={password_errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  autoComplete="off"
                  name="password_confirmation"
                  id="password_confirmation"
                  label="Confirmar Contraseña"
                  value={password_data.password_confirmation}
                  onChange={handleChangePasswordData}
                  type={password_type}
                  icon={password_icon}
                  color={"#66aaff"}
                  action={() => showPassword()}
                  required
                  error={password_errors.password_confirmation}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  autoComplete="off"
                  label="Código Secreto"
                  value={password_data.secret_key}
                  name="secret_key"
                  id="secret_key"
                  onChange={handleChangePasswordData}
                  type="text"
                  error={password_errors.secret_key}
                />
              </Grid>
              <DialogActions>
                <Button color="error" onClick={() => setOpenChangePassword(false)}>
                  Cancelar
                </Button>
                <Button type="submit">Guardar</Button>
              </DialogActions>
            </DialogContent>
          </form>
        </Dialog>
      </div>
    </div>
  );
};
export default UsersList;
