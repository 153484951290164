import React from 'react';
import Joi from 'joi';
import {Button, Grid} from '@material-ui/core';
import {
FormHelperText
} from '@material-ui/core';
import Select from '@material-ui/core';

import BaseForm from '../../common/BaseForm';
import { getDisability, saveDisability, getDisabilities } from '../../../data_services/disabilities/disability_service';
import { toast } from 'react-toastify';
import PatientSearch from '../../common/PatientSearch'
import PhysicianSearch from "../../common/PhysicianSearch";
import UserSearch from "../../common/UserSearch";
import authContext from "../../../context/auth/authContext";

class disabilityTrackForm extends BaseForm {
    static contextType = authContext
    state = {
        data: {patient: undefined, format_type: "", physician: undefined, coordinator: undefined, saved_by: undefined, disability_status: "Iniciada",
                initial_disability: '' },
        init: { patient_name: undefined, physician_name: undefined, coordinator_name: undefined},
        errors: {},
        patients: [],
        physicians: [],
        users: [],
        initial_disabilities: []
    }

    format_options = [
        {id: 'Inicial',
        name: 'Inicial',},
        {id: 'Seguimiento',
        name: 'Seguimiento',},
        {id: 'Informacion Complementaria',
        name: 'Informacion Complementaria'}
    ]

    validators = {
        id: Joi.number(),
        patient: Joi.required().label('Paciente'),
        format_type: Joi.string().required().label('Tipo de Formato'),
        physician: Joi.required().label('Medico Tratante'),
        coordinator: Joi.required().label('Coordinador que recibe'),
        saved_by: Joi.required().label('Usuario que guarda'),
        disability_status: Joi.string().label('Estado de la incapacidad'),
        initial_disability: Joi.when('format_type', {is: Joi.valid('Seguimiento', 'Informacion Complementaria'), then: Joi.required(), otherwise: Joi.any()}).label('Incapacidad inicial')
    }


    async populateDisability(){
        const disabilityId = this.props.match.params.id
        if (disabilityId === "new") {
             let _data = {...this.state.data}
             _data['saved_by'] = this.context ? parseInt(this.context.user.id):null
            this.setState({data: _data})
            return
        }

        try{
            const {data:disability } = await getDisability(disabilityId)
            this.setState({ data: this.mapToViewModel(disability) })
        }
        catch (ex){
            if (ex.response && ex.response.status === 404)
            this.props.history.replace("/not-found")
        }
    }

    async componentDidMount(){
        await this.populateDisability()
    }

    mapToViewModel(disability){
        this.setState({init: {
            patient_name: disability.patient_name,
            physician_name: disability.physician_name,
            coordinator_name: disability.coordinator_name
        }
        });
        return {
            id: disability.id, 
            patient: disability.patient,
            format_type: disability.format_type,
            physician: disability.physician,
            coordinator: disability.coordinator,
            disability_status: disability.disability_status,
            saved_by: disability.saved_by
        }
    }

    submitForm = async () => {
        try {
            const response = await saveDisability(this.state.data)
            if (response.status === 200 || response.status === 201){
                toast.success('Guardado con exito!')
                this.props.history.push('/disabilities/tracking/history/')
            }     
        } catch (error) {
            toast.error(error.message)
        }
          
    }

    handleCancel = () => {
         this.props.history.push('/disabilities/tracking/history/')
    }

setPatient = async (patient) =>{
    this.setState({initial_disabilities: []});
  if(patient !== null){
      let _data = {...this.state.data}
      let _init = {...this.state.init}
      _data['patient'] = patient.patient_id
      _init['patient_name'] = undefined
      this.setState({data: _data})
      this.setState({init: _init})
      _data['initial_disability'] = '';
      const {data: disablities} = await getDisabilities({patient_id: patient.patient_id});
      this.setState({initial_disabilities: disablities});
  }
}

setPhysician = (physician) =>{
  if(physician !== null){
      let _data = {...this.state.data}
      let _init = {...this.state.init}
      _data['physician'] = physician.id
      _init['physician_name'] = undefined
      this.setState({data: _data})
      this.setState({init: _init})
  }
}

setCoordinator = (coordinator) =>{
  if(coordinator !== null){
      let _data = {...this.state.data}
      let _init = {...this.state.init}
      _data['coordinator'] = coordinator.id
      _init['coordinator_name'] = undefined
      this.setState({data: _data})
      this.setState({init: _init})
  }
}


    render() {
        return ( <React.Fragment>
            <form onSubmit={this.handleSubmit} className={'container__content-body'}>
            <h1>Seguimiento de Incapacidades</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                   <PatientSearch label={'Buscar Paciente'}  onChangePatient={this.setPatient}
                                  search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                  empty_label={'No se encontraron resultados'}
                                  initial_text={this.state.init.patient_name}/>
                    {this.state.errors['patient_name'] && <FormHelperText>{this.state.errors['patient_name']}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <PhysicianSearch label={'Medico Tratante'}  onChangePhysician={this.setPhysician}
                                     search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                     empty_label={'No se encontraron resultados'}
                                     initial_text={ this.state.init.physician_name }/>
                    {this.state.errors['physician_name'] && <FormHelperText>{this.state.errors['physician_name']}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    {this.renderSelect('format_type', 'Tipo de Formato', this.format_options)}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <UserSearch label={'Coordinador que Recibe'}  onChangeUser={this.setCoordinator}
                                search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                empty_label={'No se encontraron resultados'}
                                initial_text={ this.state.init.coordinator_name }/>
                    {this.state.errors['coordinator_name'] && <FormHelperText>{this.state.errors['coordinator_name']}</FormHelperText>}
                </Grid>
                { this.state.data.format_type !== 'Inicial' && this.state.data.format_type !== "" && <Grid item xs={12} sm={6} md={4} lg={3}>
                    {this.renderSelect('initial_disability', 'Incapacidad Inicial', this.state.initial_disabilities)}
                    </Grid>
                }
                <Grid container item xs={12} alignItems="center">
                    {this.renderButton('Guardar', 'primary')}
                    <Button variant="contained" color="default" onClick={this.handleCancel}>
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
            </form>
        </React.Fragment> );
    }
}
 
export default  disabilityTrackForm;