import React, { useState, useEffect, useContext } from "react";
import Input from "../../../common/Input";
import DietaHabitual from "./DietaHabitual";
import Recordatorio24hrs from "./Recordatorio24hrs";
import FrecuenciaConsumo from "./FrecuenciaConsumo";
import Checkbox from "../../../common/Checkbox";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import axiosClient from "../../../../config/axios.js";

const DatosDieteticos = () => {
  const notaState = useContext(notaContext);
  const {
    nota_datos_dieteticos,
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_frecuencia_consumo,
    nota_recordatorio24,
    nota_detalle,
    nota_dieta_habitual,
    setNotaDatosDieteticos,
  } = notaState;

  const [recordatorio24hrs, setRecordatorio] = useState(false);
  const [dieta_habitual, setDietaHabitual] = useState(false);
  const [frecuencia_consumo, setFrecuenciaConsumo] = useState(false);
  const [features_options, setFeaturesOptions] = useState([]);

  const consultarFeatures = async () => {
    const api = await axiosClient.get("/api/feature/");
    const tmp_data = await api.data;
    await setFeaturesOptions(tmp_data);
  };

  const updateAll = () => {
    setRecordatorio(nota_datos_dieteticos.recordatorio24hrs);
    setDietaHabitual(nota_datos_dieteticos.dieta_habitual);
    

    if (nota_frecuencia_consumo.length > 0) {
      setFrecuenciaConsumo(true);
    }
  };

  const validations = () => {
    let error = false;
    if (!recordatorio24hrs && !dieta_habitual) {
      error = true;
    }
    if (!frecuencia_consumo) {
      error = true;
    }

    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_datos_dieteticos: error,
    });
  };

  useEffect(() => {
    consultarFeatures();
  },[])

  useEffect(() => {
    validations();
  }, [nota_datos_dieteticos]);

  useEffect(() => {
    if (nota_detalle){
      updateAll();
    }
  }, [nota_detalle]);

  useEffect(() => {
    setNotaDatosDieteticos({
      dieta_habitual: dieta_habitual,
      recordatorio24hrs: recordatorio24hrs,
      frecuencia_consumo: frecuencia_consumo,
      dieta_option: recordatorio24hrs ? "recordatorio24hrs" : "dieta_habitual", 
    })
  }, [
    recordatorio24hrs,
    dieta_habitual,
    frecuencia_consumo
  ])

  let { dieta_option } = nota_datos_dieteticos;

  let error = nota_nutricional_error.nota_datos_dieteticos;
  
  return (
    <div className="datos-dieteticos">
      <h1>Datos Dieteticos {error && <b>*</b>}</h1>
      <div>
        <div>
          <div>
            <label>Recordatorio de 24hrs</label>
            <br />
            <input
              className="radio-button"
              type="radio"
              name="dieta_option"
              value="recordatorio24hrs"
              onChange={() => {
                setRecordatorio(!recordatorio24hrs);
                setDietaHabitual(false);
              }}
              disabled={nota_detalle ? true : false}
              required={dieta_option === "recordatorio24hrs" ? true : false}
            />
          </div>
          <div>
            <label>Dieta Habitual</label>
            <br />
            <input
              className="radio-button"
              type="radio"
              name="dieta_option"
              value="dieta_habitual"
              onChange={() => {
                setDietaHabitual(!dieta_habitual);
                setRecordatorio(false);
              }}
              disabled={nota_detalle ? true : false}
              required={dieta_option === "dieta_habitual" ? true : false}
            />
          </div>

          <Checkbox
            type="checkbox"
            name="frecuencia_consumo"
            onChange={() => {
              setFrecuenciaConsumo(!frecuencia_consumo);
            }}
            value={frecuencia_consumo}
            label="frecuencia de consumo de alimentos"
            disabled={nota_detalle ? true : false}
            checked={frecuencia_consumo === true ? "checked" : ""}
          />
        </div>
        {recordatorio24hrs && <Recordatorio24hrs features_options={features_options} />}
        {dieta_habitual && <DietaHabitual features_options={features_options} />}
        {frecuencia_consumo && <FrecuenciaConsumo />}
      </div>
    </div>
  );
};

export default DatosDieteticos;
