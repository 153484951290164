import React from "react";

const Button = ({ label, styleName, ...rest }) => {
  return (
    <button {...rest} className={`${styleName}`}>
      {label}
    </button>
  );
};

export default Button;
