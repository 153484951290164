import React, { Fragment, useState, useEffect, useContext } from "react";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const MotivoConsulta = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_motivo_consulta,
    setNotaMotivoMonsulta,
  } = notaState;

  const handleChange = (e) => {
    setNotaMotivoMonsulta({
      ...nota_motivo_consulta,
      [e.target.name]: e.target.value,
    });
  };

  const validations = () => {
    if (!nota_motivo_consulta.reason_for_consultation) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_motivo_consulta: true,
      });
    } else {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_motivo_consulta: false,
      });
    }
  };

  useEffect(() => {
    if (!nota_detalle) {
      validations();
    }
  }, [nota_motivo_consulta]);

  useEffect(() => {
    if (nota_detalle) {
      validations();
    }
  }, [nota_detalle]);

  let { reason_for_consultation } = nota_motivo_consulta;

  let error = nota_nutricional_error.nota_motivo_consulta;
  
  return (
    <div className="motivo-consulta">
      <h1>Motivo Consulta {error && <b>*</b>}</h1>
      <div>
        <TextArea
          name="reason_for_consultation"
          label="Motivo de consulta *"
          onChange={handleChange}
          value={reason_for_consultation}
          cols="50"
          rows="10"
          disabled={nota_detalle ? true : false}
        />
      </div>
    </div>
  );
};

export default MotivoConsulta;
