import axios from 'axios';

let base_url = null;
switch (document.location.host) {
    case 'react.excel.network':
        base_url = '//excel.network';
        break;
    case 'rsb.excel.network':
        base_url = '//sandbox.excel.network';
        break;
    case 'rsb1.excel.network':
        base_url = '//sb1.excel.network';
        break;
    case 'rsb2.excel.network':
        base_url = '//sb2.excel.network';
        break;
    case 'rsb3.excel.network':
        base_url = '//sb3.excel.network';
        break;
    case 'rsb4.excel.network':
        base_url = '//sb4.excel.network';
        break;
    case 'rsb5.excel.network':
        base_url = '//sb5.excel.network';
        break;
    case 'rsb6.excel.network':
        base_url = '//sb6.excel.network';
        break;
    case 'rsb7.excel.network':
        base_url = '//sb7.excel.network';
        break;
    case 'localhost:3001':
      base_url = 'http://localhost:8001';
      break;
    default:
        base_url = 'http://localhost:8000';
        break;
}


const axiosClient = axios.create({
    //baseURL : `http://${process.env.BACKEND_HOST}:${process.env.BACKEND_PORT}`
    baseURL : base_url
  
})

export default axiosClient;