import React, { useState, useEffect, useContext } from "react";
import TableHeader from "../NotasNutriologia/TableHeader";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import "../../../../styles/notasnutricion.css";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
//import pdfContext from "../../../../context/pdf/pdfContext";
import { FaEdit, FaPrint } from "react-icons/fa";

localStorage.removeItem("nota");

const SimpleTable = styled.table`
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
`;

const SimpleTd = styled.td`
  padding: 5px;
  vertical-align: top;
  border: solid 1px #2e2a25;
`;

const Table = ({ columns, tmp_nota }) => {
  //const pdfState = useContext(pdfContext);
  //const { setPDFData } = pdfState;
  const notaState = useContext(notaContext);
  const { setIdNota, setLoadDetalleNota, setDetalle,setFetchData } = notaState;

  const history = useHistory();

  const [ids_nota, setIdsNota] = useState(null);
  //const [nota, setNota] = useState(null);

  const Pdf = async (id) => {
    //setNota(id);
    localStorage.setItem("nota", id);
    setLoadDetalleNota(id);
    setDetalle(true);
    history.push("/nota-nutriologia/pdf");
  };

  const Detalle = async (id) => {
    setFetchData(true)
    localStorage.setItem("nota", id);
    setIdsNota(id);
    /* setLoadDetalleNota(id); */
    setDetalle(true);
    history.push("/nota-nutriologia/detalle");
  };

  useEffect(() => {
    setIdNota(ids_nota);
  }, [ids_nota]);

  return (
    <SimpleTable>
      <TableHeader columns={columns} />
      <tbody>
        {tmp_nota.map((nota, index) => (
          <tr key={index}>
            <SimpleTd>{nota.id}</SimpleTd>
            <SimpleTd>{nota.created_date}</SimpleTd>
            <SimpleTd>{nota.physician_name}</SimpleTd>
            <SimpleTd>{nota.patient_name} </SimpleTd>
            <SimpleTd className="buttons-table">
              <button
                className="nota-button circle"
                onClick={() => Detalle(nota.id)}
              >
                <FaEdit />
              </button>
              <button
                className="nota-button circle"
                onClick={() => Pdf(nota.id)}
              >
                <FaPrint />
              </button>
            </SimpleTd>
          </tr>
        ))}
      </tbody>
    </SimpleTable>
  );
};

export default Table;
