// eslint-disable-next-line
import {
    SET_PDF
} from "../../types";
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state, action) => {
    switch (action.type) {
      case SET_PDF:
        return {
          ...state,
          pdf_data: action.payload,
        };
  
      default:
        return state;
    }
  };
  