import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../../../common/Input";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import TextArea from "../../../common/TextArea";

const IndicadoresDieteticos = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_indicadores_dieteticos,
    setNotaIndicadoresDieteticos,
  } = notaState;

  const validations = () => {
    let error = false;
    if (!nota_indicadores_dieteticos.meals_aday) {
      error = true;
    }
    if (!nota_indicadores_dieteticos.supplementation) {
      error = true;
    }
    if (!nota_indicadores_dieteticos.dislikes) {
      error = true;
    }
    if (!nota_indicadores_dieteticos.preferences) {
      error = true;
    }
    if (!nota_indicadores_dieteticos.allergy_or_intolerance_to_any_food) {
      error = true;
    }

    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_indicadores_dieteticos: error,
    });
  };

  useEffect(() => {
    if (!nota_detalle){
      validations();
    }
  }, [nota_indicadores_dieteticos]);

  useEffect(() => {
    if (nota_detalle){
      validations();
    }
  }, [nota_detalle]);

  const handleChange = (e) => {
    setNotaIndicadoresDieteticos({
      ...nota_indicadores_dieteticos,
      [e.target.name]: e.target.value,
    });
  };

  let {
    meals_aday,
    supplementation,
    allergy_or_intolerance_to_any_food,
    dislikes,
    preferences,
  } = nota_indicadores_dieteticos;

  let error = nota_nutricional_error.nota_indicadores_dieteticos;
  
  return (
    <div className="indicadores-dieteticos">
      <h1>INDICADORES DIETÉTICOS {error && <b>*</b>}</h1>
      <div>
        <Input
          type="text"
          name="meals_aday"
          onChange={handleChange}
          value={meals_aday}
          label="¿Cuántas comidas hace al día?"
          disabled={nota_detalle ? true : false}
        />

        <TextArea
          type="text"
          name="supplementation"
          onChange={handleChange}
          value={supplementation}
          cols="50"
          rows="10"
          label="Suplementación"
          disabled={nota_detalle ? true : false}
        />

        <Input
          type="text"
          name="allergy_or_intolerance_to_any_food"
          onChange={handleChange}
          value={allergy_or_intolerance_to_any_food}
          label="¿Alergia o intolerancia a algun alimento?"
          disabled={nota_detalle ? true : false}
        />
        <Input
          type="text"
          name="dislikes"
          onChange={handleChange}
          value={dislikes}
          label="¿Disgustos?"
          disabled={nota_detalle ? true : false}
        />
        <Input
          type="text"
          name="preferences"
          onChange={handleChange}
          value={preferences}
          label="¿Preferencias?"
          disabled={nota_detalle ? true : false}
        />
      </div>
    </div>
  );
};

export default IndicadoresDieteticos;
