import React, { Fragment, useState, useEffect, useContext } from "react";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const IndicadoresBioquimicos = () => {
  const notaState = useContext(notaContext);
  const {
    nota_detalle,
    nota_indicadores_bioquimicos,
    setNotaIndicadoresBioquimicos,
  } = notaState;

  const handleChange = (e) => {
    setNotaIndicadoresBioquimicos({
      ...nota_indicadores_bioquimicos,
      [e.target.name]: e.target.value,
    });
  };

  let { biochemical_indicators } = nota_indicadores_bioquimicos;

  return (
    <div className="indicadores-bioquimicos">
      <h1>INDICADORES BIOQUÍMICOS </h1>
      <div>
        <TextArea
          name="biochemical_indicators"
          label="Indicadores Bioquímicos "
          onChange={handleChange}
          value={biochemical_indicators}
          cols="50"
          rows="10"
          disabled={nota_detalle ? true : false}
        />
      </div>
    </div>
  );
};

export default IndicadoresBioquimicos;
