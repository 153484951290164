/* eslint no-unused-vars:0 */
import React, {useContext} from 'react';
import { Redirect, Route } from 'react-router-dom';
import authContext, {has_perm} from '../../context/auth/authContext'

const ProtectedRoute = ({component: Component, render, perm, ...rest}) => {

  const authState = useContext(authContext);
  const { user, setUser } = authState;
    return (
        <Route
        {...rest}
        render={props => {
            if(!user) return (
            <Redirect to={{
                    pathname: "/login",
                    state: { from: props.location }
                }}
                />
            );
            if (!has_perm(user, perm)) return (
                <Redirect to={{
                    pathname: "/not-found",
                    state: { from: props.location }
                }}
                />
            )
            return Component ? <Component {...props} />: render(props);
        }}
        />
     );
}

export default ProtectedRoute;