import { createContext } from 'react'

const authContext = createContext();

export default authContext;


export function has_perm(user, perm) {
    return user.permissions.indexOf(perm) !== -1
}

