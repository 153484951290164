import React from "react";
import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

const Load = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  svg {
    font-size: 5rem;
    animation: spin 5s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

const Loader = () => {
  return (
    <Load>
      <FaSpinner />
      <p>Cargando</p>
    </Load>
  );
};

export default Loader;
