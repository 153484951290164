import React, {Fragment, useEffect, useState} from 'react';
import Pdf from './Pdf';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const PdfCall = ({pdf_data}) => {
    // Estado que trae los datos del usuario y el Pdf
    const [data, setData] = useState({
        'name': '',
        'department': '',
        'area': '',
        'account': '',
        'password': '',
    });
    const [file_name, setFileName] = useState("file");

    /**
     * It downloads a PDF file.
     */
    const downloadPDF = async () => {
        if (pdf_data.name){
            await setData(pdf_data);
            await setFileName(pdf_data.name.split(" ").join("_"));
        }
    }

    /**
     * It saves the data to a file.
     */
    const download = () => {
        saveFile(data);
    };

    /**
     * It saves a pdf file with the name of the file_name variable.
     */
    const saveFile = async (data) => {
        await pdf(<Pdf data={data} />)
            .toBlob()
            .then((blob) =>
                saveAs(blob, `${file_name}.pdf`)
            );
    };

    useEffect (() => {
        if (pdf_data.name)
            downloadPDF();
    }, [pdf_data]);

    return (
        <Fragment>
            {pdf_data.name.length > 0 && <button onClick={download} color='primary'>Download PDF</button>}
        </Fragment>
    );
}
export default PdfCall;