import React from "react";
import Page from "./components/layout/Page";
import NavigationProvider from "./context/navigation/navigationProvider";
import AuthProvider from "./context/auth/authProvider.jsx";
import NotaProvider from "./context/notas_nutriologia/notaProvider.jsx";
import PdfProvider from "./context/pdf/pdfPovider.jsx";
import tokenAuth from "./config/tokenAuth.js";

import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {esES} from "@material-ui/data-grid";

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, esES);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString); 

const temp = urlParams.get('token')
const extra_data = urlParams.get('extra_data')

if (temp){
  localStorage.setItem('token', temp);
  localStorage.setItem('extra_data', extra_data);
  window.location.replace(window.location.pathname)
}
const token = localStorage.getItem('token');

if (token) {
  tokenAuth(token);
}else{
  let base_url = null;
  switch (document.location.host) {
      case 'react.excel.network':
          base_url = '//excel.network';
          break;
      case 'rsb.excel.network':
          base_url = '//sandbox.excel.network';
          break;
      case 'rsb1.excel.network':
          base_url = '//sb1.excel.network';
          break;
      case 'rsb2.excel.network':
          base_url = '//sb2.excel.network';
          break;
      case 'rsb3.excel.network':
          base_url = '//sb3.excel.network';
          break;
      case 'rsb4.excel.network':
          base_url = '//sb4.excel.network';
          break;
      case 'rsb5.excel.network':
          base_url = '//sb5.excel.network';
          break;
      case 'rsb6.excel.network':
          base_url = '//sb6.excel.network';
          break;
      case 'rsb7.excel.network':
          base_url = '//sb7.excel.network';
          break;
      case '127.0.0.1:13000':
          base_url = 'http://127.0.0.1:18000';
          break;
      case 'localhost:3001':
          base_url = 'http://localhost:8001';
          break;
      default:
          base_url = 'http://localhost:8000';
          break;
  }
  window.location.href = base_url
}


const App = () => {
  if (!token) return null;
  return (
    <NotaProvider>
      <AuthProvider>
        <NavigationProvider>
          <PdfProvider>
            <ThemeProvider theme={theme}>
              {/* <Page user={user} /> */}
              <Page/>
            </ThemeProvider>
          </PdfProvider>


        </NavigationProvider>
      </AuthProvider>
    </NotaProvider>
  );
};

export default App;
