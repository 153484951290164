import React from "react";
import UsersList from './UsersList';

const UserCreation = () => {
    return (
        <div className="container__content-body">
            <UsersList/>
            {/*Componente de la lista*/}
        </div>
    )
}

export default UserCreation;