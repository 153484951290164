import React, { useState, useEffect } from "react";

const Select = ({ name, label, options, error, className, ...rest }) => {
  useEffect(() => {
    error ? setShow(true) : setShow(false);
  }, [error]);

  const [show, setShow] = useState(false);
  return (
    <div className={`${className} form-group`}>
      <label htmlFor={name}>{label}</label>
      <select 
        name={name}
        id={name}
        {...rest}
        className="form-control"
        
      >
        <option value="" />
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {show && (
        <div className="alert" style={{ marginTop: "5px" }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default Select;
