import React, { Fragment, useState, useEffect, useContext } from "react";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const PlanTratamiento = () =>{
    const notaState = useContext(notaContext);
    const {
        nota_detalle,
        nota_plan_tratamiento,
        setNotaPlanNutricionalTratamiento,
    } = notaState;

    /**
     * A function that handles the change of the input value.
     */
    const handleChange = (e) => {
        setNotaPlanNutricionalTratamiento(e.target.value);
    };

    const{nutritional_treatment_plan} = nota_plan_tratamiento;

    return(
        <Fragment>
            <h1>Plan Tratamiento </h1>
                <Fragment>
                    <TextArea 
                    name="nutritional_treatment_plan" 
                    label="Plan Tratamiento"
                    onChange={handleChange}
                    value={nutritional_treatment_plan}
                    cols="50"
                    rows="10"
                    disabled={nota_detalle ? true : false}
                    />
                </Fragment>
        </Fragment>
    );
};

export default PlanTratamiento;