import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import Error from "../pages/Error";
import NotasNutriologia from "../pages/ExpMedico/NotasNutriologia/Index";
import Lista from "../pages/ExpMedico/NotasNutriologia/Lista";
import DisabilityList from "../pages/Disabilities/disability_track_list";
import DisabilityTrackForm from "../pages/Disabilities/DisabilityTrackForm";
import DisabilityTrackDetail from "../pages/Disabilities/DisabilityTrackDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PermisosMedicos from "../pages/PhysisicianPermissions/index";
import PermisosMedicosCrear from "../pages/PhysisicianPermissions/createPermission";
import ProtectedRoute from "../common/protectedRoute";
import ScheduleTable from "../pages/Schedule/ScheduleTable";
import PatientPortalDetail from "../../components/pages/Auth/PatientPortalDetail";
import InfoRequestTable from "../pages/Patients/InfoRequestTable";
//import PDF from "./PDF"

import NotaNutricionPdf from "../pages/ExpMedico/NotasNutriologia/NotaNutricionPdf";
import UserCreation from "../pages/UsersCreation/Index";
import UserCreationProvider from "../../context/users_creation/userCreationProvider";

import AccountIntegration from "../pages/AccountIntegration/Index";

const Content = () => {
  /*
      añadir este classname a cada componente para que mantenga los estilos
       className="container__content-body"

      Ejemplo
      MedicalSchedule
      <div className="container__content-body">
       ...Contenido
      </div>
    */
  return (
    <>
      <ToastContainer />
      <Switch>
      <Route exact path="/accounts-integration" component={AccountIntegration}  />
        <Route
          exact
          path="/nota-nutriologia/crear"
          component={NotasNutriologia}
        />
        <Route
          exact
          path="/nota-nutriologia/detalle"
          component={NotasNutriologia}
        />
        <Route
          exact
          path="/nota-nutriologia/pdf"
          component={NotaNutricionPdf}
        />
        <Route exact path="/nota-nutriologia" component={Lista} />
        <Route exact path="/patients/detalles" component={NotasNutriologia} />

        <ProtectedRoute
          exact
          path="/disabilities/tracking/history/"
          component={DisabilityList}
          perm="disabilities.add_disabilitytracking"
        />
        <ProtectedRoute
          exact
          path="/disabilities/tracking/:id/:action"
          component={DisabilityTrackDetail}
          perm="disabilities.add_disabilitytracking"
        />
        <ProtectedRoute
          exact
          path="/disabilities/tracking/:id"
          component={DisabilityTrackForm}
          perm="disabilities.change_disabilitytracking"
        />
        <ProtectedRoute
          exact
          path="/schedule"
          component={ScheduleTable}
          perm="agenda.add_cita"
        />
        <Route
          exact
          path="/permisos/medicos/crear"
          component={PermisosMedicosCrear}
        />
        <Route exact path="/permisos/medicos" component={PermisosMedicos} />
        <Route
          excet
          path="/patient_portal/requests"
          component={InfoRequestTable}
        ></Route>
        <Route exact path="/patient_portal" component={PatientPortalDetail} />
        <Route exact path="/" component={Home} />

        <UserCreationProvider>
          <Route exact path="/users-creation" component={UserCreation} />
        </UserCreationProvider>
        <Route component={Error} />
      </Switch>
    </>
  );
};

export default Content;
