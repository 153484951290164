import React from "react";
import styled from "styled-components";

const SimpleTableHeader = styled.thead`
  background-color: #7e8084;
  font-size: 1rem;
  color: #fff;

  tr th {
    border: 1px solid #000;
    padding: 8px;
  }
`;

const TableHeader = ({ columns }) => {
  return (
    <SimpleTableHeader className="table__header">
      <tr>
        {columns.map((column, index) => (
          <th key={index}>{column.label}</th>
        ))}
      </tr>
    </SimpleTableHeader>
  );
};

export default TableHeader;
