import React, {useState} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Popper, ButtonBase, InputBase, LinearProgress } from '@material-ui/core';
import { Person, Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axiosClient from '../../config/axios';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
        fontSize: 13,
        padding: "9px",
        border: "solid 1px #ccbcbc",
        borderRadius: "6px",
        '&:hover,&:focus': {
            borderColor: '#1f1c1c',
        },
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    tag: {
        marginTop: '-6px',
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
        fontSize: '1.2em'
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 390,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
}));

export default function UserSearch({onChangeUser, label, search_label, empty_label, initial_text}) {
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(null);
    const [user, setUser] = useState(null);

    const handleClick = (event) => {
        setUser(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason) => {
        if (reason === 'toggleInput') {
            return;
        }
        setValue(user);
        onChangeUser(user)
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const unSelect = () => {
        setValue(null)
        setUser(null)
        onChangeUser(null)
    }

    const search_users = async (event) => {
        unSelect()
        let search = event.target.value
        if (event.charCode === 13) {
            setIsLoading(true)
            let {data} = await axiosClient.get('/schedule/calendario/buscar?query_text=' + search + '&search_by=user')
            setOptions(data.search_result)
            setIsLoading(false)
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    return (
        <React.Fragment>
            <div className={classes.root}>
                <ButtonBase
                    disableRipple
                    className={classes.button}
                    aria-describedby={id}
                    onClick={handleClick}
                >
                    <span>{label}</span>
                    <Person/>
                </ButtonBase>
                {initial_text && (<div className={classes.tag}>{ initial_text }</div>)}

                {value && (
                    <div
                        key={value.id}
                        className={classes.tag}
                    >
            <span
                className={classes.color}>{`${value.first_name} ${value.last_name}`}</span>
                    </div>
                )}
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
            >
                <div className={classes.header}>{search_label}{isLoading && <LinearProgress/>}</div>
                <Autocomplete
                    open
                    onClose={handleClose}
                    classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={user}
                    onChange={(event, newValue) => {
                        setUser(newValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText={empty_label}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <DoneIcon
                                className={classes.iconSelected}
                                style={{visibility: selected ? 'visible' : 'hidden'}}
                            />
                            <span className={classes.color}/>
                            <div className={classes.text}>
                                {`${option.first_name} ${option.last_name}`}
                            </div>
                            <CloseIcon onClick={unSelect}
                                       className={classes.close}
                                       style={{visibility: selected ? 'visible' : 'hidden'}}
                            />
                        </React.Fragment>
                    )}
                    options={options}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                    renderInput={(params) => (
                        <InputBase
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus onKeyPress={search_users}
                            className={classes.inputBase}
                        />
                    )}
                />
            </Popper>
        </React.Fragment>
    );
}
