import React, { useReducer } from 'react';
import navigationContext from './navigationContext.jsx';
import navigationReducer from './navigationReducer.jsx';
import { 
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    UPDATE_PAGE,
    SET_BREADCUMBS
} from '../../types';
import { SidebarData } from '../../components/layout/Menu/SidebarData'


const NavigationProvider = props => {

    const initialState = {
        sidebar:window.innerWidth > 1048 ? true : false,
        actual_page:'Home',
        breadcumbs:[]
    };

    const [state, dispatch] = useReducer(navigationReducer, initialState);


    const showSidebar = () =>{
        dispatch({
            type:SHOW_SIDEBAR
        })
    }

    const hideSidebar = () =>{
        dispatch({
            type:HIDE_SIDEBAR,
        })
    }

    const updatePage = (location) =>{
        let pathname;
        if (location.pathname.substring(location.pathname.length, location.pathname.length -1 ) === "/"){
            pathname = location.pathname.substring(0, location.pathname.length -1 );
        }else{
            pathname = location.pathname;
        }

        let page;
        let bread = [];

        for (let index_data in SidebarData) { //NO
            let subnav_data = SidebarData[index_data].subNav;
            let subdnav_title = {
                title:SidebarData[index_data].title,
                link: false,
                index:0,
                path:''
            }
            for (let index_subnav in subnav_data) { //SI
                let menu_data = subnav_data[index_subnav];
                let page_title = {
                    title:menu_data.title,
                    link: true,
                    index:1,
                    path:menu_data.path
                }
                if (menu_data.path === pathname){
                    page = menu_data.title;
                    bread.push(subdnav_title)
                    bread.push(page_title)
                    break;
                }
                let menu = menu_data.menu;
                for (let index_menu in menu){ //NO
                    let menu_i = menu[index_menu].subNav
                    let menu_i_title = {
                        title:menu[index_menu].title,
                        link: false,
                        index:2,
                        path:''
                    }

                    for (let item in menu_i){ //SI
                        let i = menu_i[item];
                        let i_title = {
                            title: i.title,
                            link: true,
                            index:3,
                            path:i.path
                        }
                        if (i.path === pathname){
                            page = menu_data.title;

                            bread.push(subdnav_title)
                            bread.push(page_title)
                            bread.push(menu_i_title)
                            bread.push(i_title)
                            break;
                        }
                    }
                }
            }
        }
        
        dispatch({
            type:UPDATE_PAGE,
            payload:page
        })

        updateBreadcumbs(bread)

        /* if (window.innerWidth < 1024) {
            dispatch({
                type:HIDE_SIDEBAR,
            })
        } */
    }

    const updateBreadcumbs = items => {
        dispatch({
            type:SET_BREADCUMBS,
            payload:items
        })
    }


    return(
        <navigationContext.Provider
            value={{
                sidebar:state.sidebar,
                actual_page:state.actual_page,
                breadcumbs:state.breadcumbs,
                showSidebar,
                hideSidebar,
                updatePage,
                updateBreadcumbs
            }}
        >
            {props.children}
        </navigationContext.Provider>
    )
}

export default NavigationProvider;