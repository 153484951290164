import React, { useContext } from "react";
import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import logo from "../../../assets/excel_white.png";
import logo_medi from "../../../assets/mediexcel_logo_white.png";
import { Link } from "react-router-dom";
import navigationContext from "../../../context/navigation/navigationContext";
import authContext from "../../../context/auth/authContext";

import { FaAngleLeft } from 'react-icons/fa';

export const SidebarNav = styled.nav`
	@media only screen and (min-width: 1048px) {
		min-width: ${({ sidebar }) =>
			sidebar
				? '200px'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		width: ${({ sidebar }) =>
			sidebar
				? '200px'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		transition: 1s;
	}

	@media only screen and (max-width: 1048px) {
		min-height: ${({ sidebar }) =>
			sidebar
				? '100vh'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		height: ${({ sidebar }) =>
			sidebar
				? '100vh'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		min-width: ${({ sidebar }) =>
			sidebar
				? '300px'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		width: ${({ sidebar }) =>
			sidebar
				? '300px'
				: '0'}; // property from Navbar.jsx sidebar could be true or false;
		position: ${({ sidebar }) => (sidebar ? 'absolute' : 'flex')};
		z-index: ${({ sidebar }) => (sidebar ? 1 : 'inherit')};
		transition: visibility 0s, opacity 0.5s linear;
	}
`;

export const MenuIcons = styled(Link)`
	color: white;
	margin: auto;
	font-size: 1.5rem;
	align-self: flex-end;
	@media only screen and (min-width: 1048px) {
		display: none;
	}
`;

const LogoContainer = styled.div`
  height: 10% !important;
  object-fit: cover;
`;

const Logo = styled.img`
	height: 7rem;
	&:hover {
		cursor: pointer;
	}
`;

const Sidebar = () => {
  const navigationState = useContext(navigationContext);
  const authState = useContext(authContext);
  const { sidebar, hideSidebar } = navigationState;
  const { user } = authState;
  const user_groups = user["user"]["groups"];

  return (
    <SidebarNav sidebar={sidebar} className={user_groups.includes("Chula Vista") ? 'sidebar_medi' : 'sidebar'}>
      <div>
        <div className={user_groups.includes("Chula Vista") ? 'sidebar__logo_medi' : 'sidebar__logo'}>
          {/*<Link to="/"> */}
          {/* <LogoContainer> */}
            <a href="https://excel.network">
              <Logo
                src={user_groups.includes("Chula Vista") ? logo_medi : logo}
                alt="MediExcel"
              />
            </a>
          {/* </LogoContainer> */}
          <MenuIcons to="#" className="sidebar__close">
            <FaAngleLeft onClick={hideSidebar} />
          </MenuIcons>
        </div>

				{SidebarData.map((item, index) => {
					return <SubMenu item={item} key={index} />;
				})}
			</div>
		</SidebarNav>
	);
};

export default Sidebar;
