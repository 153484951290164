import React, { useReducer } from "react";
import pdfContext from "./pdfContext.jsx";
import pdfReducer from "./pdfReducer.jsx";
import {
  SET_PDF,
} from "../../types";


const PdfProvider = (props) => {
  const initialState = {
    pdf_data: {},
   
  };

  const [state, dispatch] = useReducer(pdfReducer, initialState);


  const setPDFData = (data) =>{
    dispatch({
      type: SET_PDF,
      payload: data,
    });
  };

  return (
    <pdfContext.Provider
      value={{
        pdf_data: state.pdf_data,
        setPDFData,
      }}
    >
      {props.children}
    </pdfContext.Provider>
  );
};

export default PdfProvider;
