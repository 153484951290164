import React, { useEffect, useState, useContext, Fragment } from "react";
import axiosClient from "../../../../config/axios.js";
import styled from "styled-components";
import Table from "../NotasNutriologia/Table";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";

const Paginations = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const Lista = () => {
  const [datos, setDatos] = useState([]);
  const [count_page, setCountPage] = useState(1);

  const consultarDatos = async (page) => {
    const api_nota = await axiosClient.get(`/api/nutrition_note/?page=${page}`);
    const tmp_nota = await api_nota.data.results;
    setDatos(tmp_nota);
    setCountPage(Math.ceil(api_nota.data.count/10));
    //¡setClearDatos
  };

  //   const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    consultarDatos(1);
  }, []);

  useEffect(() => {
    consultarDatos(page);
  }, [page]);

  let columns = [
    { label: "Folio de Nota" },
    { label: "Fecha y Hora de la Nota" },
    { label: "Medico" },
    { label: "Paciente" },
    { label: "Acciones" },
  ];

  return (
    <Fragment>
      <div className="container__content-body">
        <div>
          <Table columns={columns} tmp_nota={datos} />
        </div>
        <Paginations>
          <Typography>Page: {page}</Typography>
          <Pagination count={count_page} page={page} onChange={handleChange} />
        </Paginations>
      </div>
    </Fragment>
  );
};

export default Lista;

/***
 * count trae la cantidad de elementos
 *
 * necesito la cantidad de paginas
 */
