import * as FaIcons from 'react-icons/fa';

export const SidebarData = [
  { 
    title: "Patients Requests",
    path: "/patient_portal/requests",
  },
  {
    title: "Portal Pacientes",
    path: "/patient_portal/",
  },
];

