/**
 * New user form component that renders the form to create a new user
 */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import Form from './Form';
import Modal from '../../common/Modal';
import Button from '@mui/material/Button';

const NewUserForm = ({ user, post_finallized, postFormData, cleanFormData }) => {

    const [roles, setRoles] = useState([]);
    const childRef = useRef();

    /**
     * Function that sends the data to the API
     */
    const handleSubmit = () => {
        postFormData({ 'user': user.id });
    }

    /**
     * Function that cleans the form data and opens the modal.
     */
    const handleOpenModal = () => {
        childRef.current.handleClickOpen();
        cleanFormData();
    }

    /* A hook that is called when the user changes and get the role list. */
    useEffect(() => {
        const roles_list = user.roles.filter(instance => instance['is_coordinator']);
        const new_role_list = [];
        roles_list.forEach(instance => {
            new_role_list.push({
                id: instance.department_rol_id,
                name: instance.role.name
            });
        })
        setRoles(new_role_list);
    }, [user])

    return (
        <>
            <Button onClick={() => handleOpenModal()} disabled={!roles.length > 0}>Nuevo Usuario</Button>
            <Modal
                ref={childRef}
                title="Nuevo Usuario"
                content={<Form roles={roles} />}
                action={handleSubmit}
                post_finallized={post_finallized}
            />
        </>
    );
}

export default NewUserForm;