import React, { Component } from 'react';
import Joi from 'joi';
import {TextField, Select, FormHelperText, MenuItem, InputLabel, Button} from '@material-ui/core';

class BaseForm extends Component {
    state = { 
        data: {},
        errors: {}
     }

    validate = () => {
        const {data} = this.state;
        const { error } = Joi.object(this.validators).validate(data, {abortEarly: false})
        
        if(!error) return null;

        const newErrors = {}
        for (let item of error.details) newErrors[item.path[0]] = item.message;
        return newErrors
    }

    validateProperty = ({name, value}) => {
        const obj = { [name]: value}
        const validator = { [name]: this.validators[name]}
        const {error} = Joi.object(validator).validate(obj)
        return error ? error.details[0].message:null
    }
    
    handleSubmit = e =>{
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if(errors) return;

        this.submitForm();
    }

    handleChange = ({ target:input }) =>{
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if(errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({...this.state, data, errors});
    }

    renderButton = (label, color_class) => {
        const disabled = this.validate() != null
        return (
            <Button disabled={disabled} color={color_class} variant="contained" onClick={this.handleSubmit}>{label}</Button>
        )
    }
    
    renderInput = (name, label, type = 'text') => {
        const {data, errors} = this.state;
        return (
            <TextField fullWidth type={type} name={name} label={label} id={name} variant="outlined"
                    onChange={this.handleChange} value={data[name]} error={!!errors[name]}
                    InputLabelProps={(type==="date" || type==="time") ? { shrink: true, }:{}}
                    helperText={errors[name]}/>
        )
    }
    
    renderSelect = (name, label, options) => {
        const {data, errors} = this.state;
        return (
            <React.Fragment>
                <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select fullWidth name={name} id={name} onChange={this.handleChange} value={data[name]} error={!!errors[name]}>
            <MenuItem key="0" value="" >---Seleccione---</MenuItem>
                {options.map(opt => (
                    <MenuItem key={opt.id} value={opt.id.toString()} >{opt.name}</MenuItem>
                ))}
            </Select>
            {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
            </React.Fragment>
        )
    }
}
 
export default BaseForm;