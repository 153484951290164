import React, { useEffect, useState } from "react";
import { FormControl } from "@material-ui/core";
import { Grid } from "@mui/material";
import Input from '../components/common/Input';
import TextArea from '../components/common/TextArea';
import Select from '../components/common/Select';


/**
 * It's a function that returns a FormControl component that contains a Form
 * @returns A FormControl component with a Grid container with two Grid for folio, observations, observations type.
 */
const ModalContent = ({ categories, folio_service, account_observation, account_observation_type, setFolioService, setAccountObservation, setAccountObservationType }) => {

    return (<FormControl >
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Input error={folio_service.length == 0 ? "Favor de insertar id" : false}
                    onChange={(e) => {
                        setFolioService(e.target.value);
                    }} required name="folio_service" label="Folio *" value={folio_service} />
            </Grid>
            <Grid item xs={12} md={12}>
                <Select error={account_observation_type == "" ? "Favor de seleccionar tipo de observación" : false}
                    onChange={(e) => {
                        setAccountObservationType(e.target.value);
                    }} required name="account_observation_type" label="Categoria de Incidencia *" value={account_observation_type}
                    options={categories} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextArea onChange={(e) => {
                    setAccountObservation(e.target.value);
                }} name="account_observation" label="Observaciones" value={account_observation} />
            </Grid>
        </Grid>
    </FormControl>)
};

/**
 * It returns an array of the state variables, the modal content, and a function to set the state
 * variables
 * @returns The categories, folio_service, account_observation, account_observation_type, ModalContent, and setForm.
 */
const useFolio = () => {

    const [folio_service, setFolioService] = useState("");
    const [account_observation, setAccountObservation] = useState("");
    const [account_observation_type, setAccountObservationType] = useState("");
    const [categories, setCategories] = useState("");

    /**
     * It sets the state of the form categories, folio, observation and observation type.
     */
    const setForm = (categories, folio, observation, observation_type) => {

        setCategories(categories);
        setFolioService(folio);
        setAccountObservation(observation);
        setAccountObservationType(observation_type);
    }

    return [folio_service, account_observation, account_observation_type,
        <ModalContent categories={categories} folio_service={folio_service} setFolioService={setFolioService}
            account_observation={account_observation} setAccountObservation={setAccountObservation}
            account_observation_type={account_observation_type} setAccountObservationType={setAccountObservationType} />,
        setForm];
}

export default useFolio;