import React, { useState, useEffect, useContext, useRef } from "react";
import TextArea from "../../../common/TextArea";
import { TextField } from "@mui/material";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import { MultiSelect } from "react-multi-select-component";
import useFetch from "../../../../hooks/useFetch";

const DiagnosticoNutricional = () => {
  const notaState = useContext(notaContext);
  const {
    nota_detalle,
    nota_diagnostico_nutricional,
    setNotaDiagnosticoNutricional,
    setCIEResult,
  } = notaState;

  const [cie_test, setCIEResultSelect] = useState([]);
  const searchInput = useRef(null);
  const { data, setData } = useFetch();
  const items = data.results.results;
  items?.map((item) => (item.value = item.id));

  /** limpiar campo **/
  const resetInputField = () => {
    setCIEResultSelect([]);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      resetInputField();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  /**
   * A function that handles the change of the input fields.
   */
  const handleChange = (e) => {
    setNotaDiagnosticoNutricional({
      ...nota_diagnostico_nutricional,
      [e.target.name]: e.target.value,
    });
  };

  /* A hook that is called when the component is mounted. */
  useEffect(() => {
    setCIEResult(cie_test);
  }, [cie_test]);

  let { nutritional_diagnosis } = nota_diagnostico_nutricional;

  return (
    <div className="diagnostico-nutricional">
      <h1>Diagnóstico Nutricional</h1>
      <div>
        <div>
          <TextArea
            name="nutritional_diagnosis"
            onChange={handleChange}
            value={nutritional_diagnosis}
            cols="50"
            rows="10"
            label="Diagnóstico Nutricional"
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <TextField
            type="text"
            placeholder="Busqueda"
            value={data.slug}
            onChange={(e) => setData({ ...data, slug: e.target.value })}
            onClick={resetInputField}
          />
          <MultiSelect
            options={items || []}
            value={cie_test}
            onChange={setCIEResultSelect}
            ref={searchInput}
            labelledBy="Select"
            name="cie"
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default DiagnosticoNutricional;
