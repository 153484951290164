import React,{useState,useEffect} from 'react'

const Checkbox = ({ name, label, error, ...rest }) => {
    useEffect(() => {
        error ? setShow(true) : setShow(false);
      }, [error]);
    
      const [show, setShow] = useState(false);
    return ( 
        <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input {...rest} name={name} id={name} className="form-check-input" type="checkbox" />
      {show && (
        <div className="alert" style={{ marginTop: "5px" }}>
          {error}
        </div>
      )}
    </div>
     );
}
 
export default Checkbox;