import React, { Fragment, useEffect, useState, useContext } from "react";
import Input from "../../../common/Input";
import axiosClient from "../../../../config/axios.js";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import { MultiSelect } from "react-multi-select-component";

const Recordatorio24hrs = ({features_options}) => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_recordatorio24,
    setNotaRecordatorio24,

  } = notaState;



  const handleChange = (e) => {
    setNotaRecordatorio24({
      ...nota_recordatorio24,
      [e.target.name]: e.target.value,
      diet_type: 1,
    });
  };

  const handleChangeSelect = (value) => {
    /* A function that is called when the user selects a feature. It is updating the state of the
    component. If the user selects no features, it sets the error to true. If the user
    selects a feature, it sets the error to false.*/
    setNotaRecordatorio24({
      ...nota_recordatorio24,
      features: value,
    });
    if (value.length == 0)
    {
      setNotaNutricionalError({
        nota_nutricional_error:true,
      });
    }
    else{
      setNotaNutricionalError({
        nota_nutricional_error:false,
      });
    }

  };


   
  if (!features_options || features_options.length === 0) return null;

  let {
    time_breakfast,
    time_morning_snack,
    time_food,
    time_evening,
    time_dinner,
    time_night_collation,
    breakfast,
    morning_snack,
    food,
    evening_snack,
    dinner,
    night_collation,
    features,
    features_data,
    observations,
  } = nota_recordatorio24;

  features = features || [];


  return (
    <div className="recordatorio-24-hrs">
      <h1>Recordatorio de 24horas</h1>
      <div>
        <div>
          <Input
            type="time"
            name="time_breakfast"
            onChange={handleChange}
            value={time_breakfast}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="breakfast"
            onChange={handleChange}
            value={breakfast}
            label="Desayuno "
            disabled={nota_detalle}
          />

          <div>
            <label><b>Caracteristicas</b></label>
            {!nota_detalle && features_options && features_options.length > 0?
              <MultiSelect
                options={features_options}
                value={features}
                onChange={handleChangeSelect}
                labelledBy="Select"
                name="features"
                disabled={nota_detalle}
              />
              :
              <ul>
              {features_data && features_data.map(item => {
                return <li>{item.label}</li>
              })}
              </ul>
            }
          </div>
        </div>
        <div>
          <Input
            type="time"
            name="time_morning_snack"
            onChange={handleChange}
            value={time_morning_snack}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="morning_snack"
            onChange={handleChange}
            value={morning_snack}
            label="Colación "
            disabled={nota_detalle}
          />
        </div>
        <div>
          <Input
            type="time"
            name="time_food"
            onChange={handleChange}
            value={time_food}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="food"
            onChange={handleChange}
            value={food}
            label="Comida "
            disabled={nota_detalle}
          />
        </div>
        <div>
          <Input
            type="time"
            name="time_evening"
            onChange={handleChange}
            value={time_evening}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="evening_snack"
            onChange={handleChange}
            value={evening_snack}
            label="Colación "
            disabled={nota_detalle}
          />
        </div>

        <div>
          <Input
            type="time"
            name="time_dinner"
            onChange={handleChange}
            value={time_dinner}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="dinner"
            onChange={handleChange}
            value={dinner}
            label="Cena "
            disabled={nota_detalle}
          />
        </div>
        <div>
          <Input
            type="time"
            name="time_night_collation"
            onChange={handleChange}
            value={time_night_collation}
            label="Hora "
            disabled={nota_detalle}
          />

          <TextArea
            type="text"
            name="night_collation"
            onChange={handleChange}
            value={night_collation}
            label="Colación "
            disabled={nota_detalle}
          />
        </div>

        <div>
          <TextArea
            name="observations"
            onChange={handleChange}
            value={observations}
            cols="50"
            rows="10"
            label="Observaciones "
            disabled={nota_detalle}
          />
        </div>
      </div>
    </div>
  );
};

export default Recordatorio24hrs;
