import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../common/ScreenSizes";
import Joi from "joi";
import Input from "../../common/Input";
import Button from "../../common/Button";
import logo from "../../../assets/excel.png";
import authContext from '../../../context/auth/authContext.jsx';


const AuthLayout = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  justify-items: center;
  background: #3b6ca6 !important;
`;

const AuthLayoutInner = styled.div`
  width: 500px;
  margin: auto;
  background: #ffffff;
  padding: 40px 50px 40px 50px;
  border-radius: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  ${device.max_tablet} {
    width: 500px;
    padding: 30px 40px 30px 40px;
  }

  ${device.max_mobileL} {
    width: 350px;
    padding: 10px 15px 10px 15px;
  }

  ${device.max_mobileM} {
    width: 300px;
    padding: 10px 15px 10px 15px;
  }

  ${device.max_mobileS} {
    width: 250px;
    padding: 10px 15px 10px 15px;
  }
`;
const AuthLogo = styled.img`
  object-fit: contain;
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  ${device.max_mobileL} {
    height: 125px;
  }
  ${device.max_mobileM} {
    height: 100px;
  }
  ${device.max_mobileS} {
    height: 75px;
  }
`;

const AuthTitle = styled.h1`
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  font-size: 2.5rem;

  ${device.max_mobileL} {
    font-size: 2rem;
  }
  ${device.max_mobileM} {
    font-size: 1.75rem;
  }
  ${device.max_mobileS} {
    font-size: 1.5rem;
  }
`;

const ForgotPassword = styled(Link)`
  float: right;
  font-size: 1rem;
  color: #3b6ca6 !important;
  margin-top: 10px;
`;
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const authState = useContext(authContext);
  const { userLogin } = authState;

  const schema = Joi.object({
    username: Joi.string().required().label("Username"),
    password: Joi.string().min(5).required().label("Password"),
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    
    
    if (Object.keys(errors).length > 0) return {};
    userLogin({'email':username, 'password':password});
  };

  const handleChange = ({ currentTarget: input }) => {
    const errorMessage = validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    if (input.name === "username") {
      setUsername(input.value);
    } else if (input.name === "password") {
      setPassword(input.value);
    }
  };

  const validate = () => {
    const options = { abortEarly: false };

    const loginData = { username, password };
    const { error } = schema.validate(loginData, options);

    if (!error) return {};

    const _errors = {};
    for (let index in error.details) {
      let item = error.details[index];
      _errors[item.path[0]] = item.message;
    }
    return _errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema_ = Joi.object({
      [name]: schema.extract(name),
    });
    const { error } = schema_.validate(obj);
    return error ? error.details[0].message : null;
  };

  return (
    <AuthLayout>
      <AuthLayoutInner>
        <AuthLogo src={logo} alt="" />
        <AuthTitle>Ingresar</AuthTitle>
        <form onSubmit={handleSubmit}>
          <Input
            autoFocus={true}
            name="username"
            label="Usuario"
            placeholder="Nombre del usuario"
            type="text"
            value={username}
            onChange={handleChange}
            error={errors["username"]}
          />

          <Input
            autoFocus={false}
            name="password"
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            value={password}
            onChange={handleChange}
            error={errors["password"]}
          />

          <Button label="Ingresar" styleName="btn-main btn-block" />
          <ForgotPassword to="forgot-password">
            Olvide mi contraseña
          </ForgotPassword>
        </form>
      </AuthLayoutInner>
    </AuthLayout>
  );
};

export default Login;
