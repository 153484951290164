import { Link } from "react-router-dom";
import {Button} from '@material-ui/core';
import DisabilityTrackTable from './DisabilityTrackTable';
import React, {useContext} from "react";
import authContext, {has_perm} from "../../../context/auth/authContext";

const DisabilityList = () => {
  //USUARIO
  const authState = useContext(authContext);
  const { user } = authState;
    return (
        <div className="container__content-body">
            <h1>Historial de Incapacidades
                {
                    has_perm(user, 'disabilities.change_disabilitytracking') &&
                    <Button color="primary" variant="outlined" component={Link} to={{pathname: '/disabilities/tracking/new'}}>Nueva</Button>
                }
            </h1>
            <DisabilityTrackTable user={user.id}/>
        </div>
    )
};

export default DisabilityList;