import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../../../common/Input";
import TextArea from "../../../common/TextArea";
import Checkbox from "../../../common/Checkbox";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const IndicadoresClinicos = () => {
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_indicadores_clinicos,
    setNotaIndicadoresClinicos,
  } = notaState;

  const validations = () => {
    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_indicadores_clinicos: false,
    });
    if (!nota_indicadores_clinicos.actual_problems) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_indicadores_clinicos: true,
      });
    }
    if (!nota_indicadores_clinicos.clinical_indicators_observations) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_indicadores_clinicos: true,
      });
    }
  };

  useEffect(() => {
    if (!nota_detalle) {
      validations();
    }
  }, [nota_indicadores_clinicos]);

  useEffect(() => {
    if (nota_detalle) {
      validations();
    }
  }, [nota_detalle]);

  const handleChange = (e) => {
    setNotaIndicadoresClinicos({
      ...nota_indicadores_clinicos,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangecheckbox = (e) => {
    setNotaIndicadoresClinicos({
      ...nota_indicadores_clinicos,
      [e.target.name]: e.target.checked,
    });
  };

  let {
    actual_problems,
    diarrhea,
    constipation,
    gastritis,
    ulcers,
    sickness,
    threw_up,
    colitis,
    gastroesophageal_reflux,
    clinical_indicators_observations,
  } = nota_indicadores_clinicos;

  let error = nota_nutricional_error.nota_indicadores_clinicos;

  return (
    <div className="indicadores-clinicos">
      <h1>INDICADORES CLÍNICOS {error && <b>*</b>}</h1>
      <div>
        <div>
          <TextArea
            type="text"
            name="actual_problems"
            onChange={handleChange}
            value={actual_problems}
            cols="50"
            rows="10"
            label="Nota Nutricional"
            disabled={nota_detalle ? true : false}
          />
        </div>
        <div>
          <Checkbox
            type="checkbox"
            name="diarrhea"
            onChange={handleChangecheckbox}
            value={diarrhea}
            label="Diarrea"
            checked={diarrhea ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="constipation"
            onChange={handleChangecheckbox}
            value={constipation}
            disabled={nota_detalle ? true : false}
            checked={constipation ? "checked" : ""}
            label="Estreñimiento"
          />

          <Checkbox
            type="checkbox"
            name="gastritis"
            onChange={handleChangecheckbox}
            value={gastritis}
            label="Gastritis"
            checked={gastritis ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="ulcers"
            onChange={handleChangecheckbox}
            value={ulcers}
            label="Úlceras"
            checked={ulcers ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <Checkbox
            type="checkbox"
            name="sickness"
            onChange={handleChangecheckbox}
            value={sickness}
            label="Náuseas"
            checked={sickness ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="threw_up"
            onChange={handleChangecheckbox}
            value={threw_up}
            label="Vómito"
            checked={threw_up ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="colitis"
            onChange={handleChangecheckbox}
            value={colitis}
            label="Colitis"
            checked={colitis ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="gastroesophageal_reflux"
            onChange={handleChangecheckbox}
            value={gastroesophageal_reflux}
            label="Reflujo gastroesofágico"
            checked={gastroesophageal_reflux ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <TextArea
            name="clinical_indicators_observations"
            onChange={handleChange}
            value={clinical_indicators_observations}
            cols="50"
            rows="10"
            label="Observaciones *"
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default IndicadoresClinicos;
