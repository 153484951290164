import React, { useState, useEffect, useContext } from "react";
import axiosClient from "../../../../config/axios.js";
import Input from "../../../common/Input";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import { roundUp } from "../../../common/utils";

const Antropometricos = () => {
  const data_patient = JSON.parse(localStorage.getItem('extra_data'))
  const patient_id = Object.values(data_patient)[0]
  const notaState = useContext(notaContext);
  const {
    setNotaNutricionalError,
    nota_nutricional_error,
    nota_detalle,
    nota_antropometricos,
    setNotaAntropometricos,
  } = notaState;

  const [formulas, setFormulas] = useState({
    imc: 0,
    kg_grasa: 0,
    kg_musculo: 0,
    adjusted_weight: 0,
    utility_height: 0,
  });

  const getVitalSigns = async () => {
    if (!data_patient || !patient_id) return null;
    const data = await axiosClient.get(`api/vital_signs/?patient=${patient_id}`)
    if(data.data.length > 0){
      const tmp_data = await data.data[0];
      console.log('tmp',tmp_data);
      
      setNotaAntropometricos({
        ...nota_antropometricos,
        height: parseFloat(tmp_data.weight).toFixed(2),
        size: parseFloat(tmp_data.height).toFixed(2)*100,
        waist_circumference: parseFloat(tmp_data.waist_circumference).toFixed(2),
        hip_circumference: parseFloat(tmp_data.hip_circumference).toFixed(2),
        date: new Date().toLocaleDateString(),
      })
    }
    else{
      setNotaAntropometricos({
        ...nota_antropometricos,
        date: new Date().toLocaleDateString(),
      })
    }
    
  }

  const validations = () => {
    setNotaNutricionalError({
      ...nota_nutricional_error,
      nota_antropometricos: false,
    });
    if (!nota_antropometricos.height) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_antropometricos: true,
      });
    }
    if (!nota_antropometricos.ideal_weight) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_antropometricos: true,
      });
    }
    if (!nota_antropometricos.size) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_antropometricos: true,
      });
    }
    if (!nota_antropometricos.fat_percentage) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_antropometricos: true,
      });
    }
    if (!nota_antropometricos.muscle_percentage) {
      setNotaNutricionalError({
        ...nota_nutricional_error,
        nota_antropometricos: true,
      });
    }
  };

  useEffect(() => {
    if (!nota_detalle) {
      //validations();
      formulasnota_antropometricos();
    }
  }, [nota_antropometricos]);

  useEffect(() => {
    if (nota_detalle) {
      //validations();
    }
    formulasnota_antropometricos();
  }, [nota_detalle]);

  useEffect(() => {
    getVitalSigns(); 
  }, []);

  const handleChangeInput = (e) => {
    const re = /^(\d)*(\.)?([0-9]{1,2})?$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      setNotaAntropometricos({
        ...nota_antropometricos,
        [e.target.name]: e.target.value,
      });
    }
  };

  const formulasnota_antropometricos = () => {
    let imc =
      nota_antropometricos.height / (nota_antropometricos.size/100)**2;
    let kg_grasa =
      (nota_antropometricos.fat_percentage * nota_antropometricos.height) / 100;
    let kg_musculo =
      (nota_antropometricos.muscle_percentage * nota_antropometricos.height) /
      100;
    let adjusted_weight =
      parseInt(nota_antropometricos.ideal_weight) +
      (parseInt(nota_antropometricos.height) -
        parseInt(nota_antropometricos.ideal_weight)) *
        0.25;

    setFormulas({
      ...formulas,
      imc: roundUp(imc),
      kg_grasa: roundUp(kg_grasa),
      kg_musculo: roundUp(kg_musculo),
      adjusted_weight: adjusted_weight,
    });
  };

  const { imc, kg_grasa, kg_musculo, adjusted_weight } = formulas;


  let {
    date,
    height,
    ideal_weight,
    size,
    waist_circumference,
    hip_circumference,
    fat_percentage,
    muscle_percentage,
    visceral_fat_percentage,
    utility_height,
  } = nota_antropometricos;

  let error = nota_nutricional_error.nota_antropometricos;
  return (
    <div className="antropometricos">
      <h1>Antropométricos {error && <b>*</b>}</h1>
      <div>
        <div>
          <Input
            type="text"
            name="created_at"
            value={date}
            label="Fecha"
            disabled={nota_detalle ? true : false}
            readOnly
          />

          <Input
            type="text"
            name="height"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={height || ""}
            label="Peso *"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="ideal_weight"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={ideal_weight || ""}
            label="Peso Ideal *"
            disabled={nota_detalle ? true : false}
          />
          <Input
            type="text"
            name="adjusted_weight"
            readOnly
            value={adjusted_weight || ""}
            label="Peso ajustado para obesidad "
            disabled
          />
        </div>
        <div>
          <Input
            type="text"
            name="size"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={size || ""}
            label="Talla (cm) *"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="imc"
            readOnly
            value={imc || ""}
            label="IMC"
            disabled
          />

          <Input
            type="text"
            name="waist_circumference"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={waist_circumference || "0"}
            label="Circunferencia de cintura "
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="hip_circumference"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={hip_circumference || "0"}
            label="Circunferencia de cadera "
            disabled={nota_detalle ? true : false}
          />
        </div>
        <div>
          <Input
            type="text"
            name="fat_percentage"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={fat_percentage || ""}
            label="% Grasa *"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="muscle_percentage"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={muscle_percentage || ""}
            label="% Musculo *"
            disabled={nota_detalle ? true : false}
          />

          <Input
            type="text"
            name="kg_grasa"
            value={kg_grasa || ""}
            readOnly
            label="Kg en grasa "
            disabled
          />

          <Input
            type="text"
            name="kg_musculo"
            value={kg_musculo || ""}
            readOnly
            label="Kg en musculo "
            id="mi-select option"
            disabled
          />
        </div>
        <div>
          <Input
            type="text"
            name="visceral_fat_percentage"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={visceral_fat_percentage || ""}
            label="% Grasa visceral "
            disabled={nota_detalle ? true : false}
          />
            <Input
            type="text"
            name="utility_height"
            onChange={!nota_detalle ? handleChangeInput : undefined}
            value={utility_height || ""}
            label="Peso Utilizado *"
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default Antropometricos;
