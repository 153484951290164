import React from "react";
import TableHeader from "./tableHeader";
import styled from "styled-components";

const SimpleTable = styled.table`
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
`;

const Table = ({ columns }) => {
  return (
    <SimpleTable>
      <TableHeader columns={columns} />
    </SimpleTable>
  );
};

export default Table;
