import React, {useContext} from "react";
import navigationContext from "../../context/navigation/navigationContext";
import MenuItem from "../common/MenuItem";
import { SidebarData } from "./Menu/SidebarData"
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto;
  
  &::-webkit-scrollbar{
    height:0.4rem;
  }
`;

const Navbar = () => {
  const navigationState = useContext(navigationContext);
  const { actual_page } = navigationState;

  if (actual_page === 'Home') return null;
  
  let menu;

  /*
    SidebarData
      >subnav data
        >menu items

        Administracion
          > Admision
            >Agenda
              ...
              ...
            >Paciente
              ...
            ...
  */
  for (let index_data in SidebarData) {
    let subnav_data = SidebarData[index_data].subNav;
    for (let index_subnav in subnav_data) {
      let menu_data = subnav_data[index_subnav];
      if (menu_data['title'] === actual_page){
        menu = menu_data.menu;
      }
    }
  }
  if (!menu || menu.length === 0) return null;
  
  
  return (
    <Content className="container__subnavbar">
      {
        menu.map((item, index) => {
          return <MenuItem key={index} item={item}/>
        })
      }
    </Content>
  );
};

export default Navbar;
