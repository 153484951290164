// eslint-disable-next-line
import { 
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    UPDATE_PAGE,
    SET_BREADCUMBS
} from '../../types';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, action) => {
    switch(action.type){
        
        case SHOW_SIDEBAR:
            return {
                ...state,
                sidebar: true
            }
        case HIDE_SIDEBAR:
            return {
                ...state,
                sidebar: false,
            }
        case UPDATE_PAGE:
            return {
                ...state,
                actual_page:action.payload
            }
        case SET_BREADCUMBS:
            return{
                ...state,
                breadcumbs:action.payload
            }
        default:
            return state;
    }
}