import React, { Fragment, useState, useEffect, useContext } from "react";
import FrecueciaConsumoIndividual from "./FrecueciaConsumoIndividual";
import TextArea from "../../../common/TextArea";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import axiosClient from "../../../../config/axios.js";

const FrecuenciaConsumo = () => {
  const notaState = useContext(notaContext);
  const {
    nota_frecuencia_consumo,
    nota_detalle,
    setNotaFrecuenciaConsumo,
    nota_frecuencia_consumo_observaciones,
    setNotaFrecuenciaConsumoObservaciones
  } = notaState;


  const consultarFeatures = async () => {
    const api = await axiosClient.get("/api/diet_product/");
    const tmp_data = await api.data;
    let  tmp = []
    tmp_data.forEach(item => {
      tmp.push({
        product_name:item.product_name,
        id:item.id,
        never_or_almost_never:null,
        up_to_date:null,
        a_week:null,
        a_month:null,
      })
    });
    setNotaFrecuenciaConsumo(tmp);
  };


  useEffect(() => {
    if (!nota_detalle){
      consultarFeatures();
    }
  }, []);

  const handleChange = (e) => {
    setNotaFrecuenciaConsumoObservaciones(e.target.value);
  };

  const addProducts = (product) => {
    if (!nota_detalle){
      let exist = false;
      let its_ok = false;

      let list_temp = [];
      nota_frecuencia_consumo.forEach((item, index) => {
        if (product.product_name === item.product_name) {
          exist = true;
        } else {
          list_temp.push(item);
        }
      });

      if (
        product.never_or_almost_never ||
        (product.a_month && product.a_month !== "-") ||
        (product.a_week && product.a_week !== "-") ||
        (product.up_to_date && product.up_to_date !== "-")
      ) {
        its_ok = true;
      }

      if (exist) {
        if (its_ok) {
          list_temp.push(product);
          setNotaFrecuenciaConsumo(list_temp);
        } else {
          setNotaFrecuenciaConsumo(list_temp);
        }
      } else {
        if (its_ok) {
          setNotaFrecuenciaConsumo([...nota_frecuencia_consumo, product]);
        }
      }
    }
  };



  return (
    <div className="frecuencia-consumo">
      <h1>FRECUENCIA DE CONSUMO DE ALIMENTOS</h1>
      <div>
        <div>
          <label>Grupo de alimentos</label>
          <label>Nunca</label>
          <label>al dia</label>
          <label>a la semana</label>
          <label>al mes</label>
        </div>
        <div>
          {nota_frecuencia_consumo.map((item, index) => {
            return (
              <FrecueciaConsumoIndividual
                key={index}
                product={item}
                addProducts={addProducts}
                disabled={nota_detalle ? true : false}
              />
            );
          })}
        </div>
        <div>
          <TextArea
            name="observations"
            onChange={handleChange}
            value={nota_frecuencia_consumo_observaciones}
            cols="50"
            rows="10"
            label="Observaciones"
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default FrecuenciaConsumo;
