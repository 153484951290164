import React, { useState, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import notaContext from "../../../context/notas_nutriologia/notaContext";



export const SidebarLinkHome = styled.a`
	display: flex;
	color: #f5f5f5;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem;
	list-style: none;
	height: 3.5rem;
	text-decoration: none;

	&:hover {
		background: #9197a3;
		border-left: 4px solid #7e8084;
		cursor: pointer;
		font-weight: bold;
	}
`;

export const SidebarLink = styled.a`
	display: flex;
	color: #f5f5f5;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem;
	list-style: none;
	height: 3.5rem;
	text-decoration: none;

	&:hover {
		background: #9197a3;
		border-left: 4px solid #7e8084;
		cursor: pointer;
		font-weight: bold;
	}
`;

export const SidebarLabel = styled.span`
	margin-left: 1rem;
	font-size: 1rem;
`;

export const DropdownLink = styled(Link)`
	background: #414757;
	height: 3.5rem;
	padding-left: 3rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #f5f5f5;

	&:hover {
		background: #9197a3;
		border-left: 4px solid #7e8084;
		cursor: pointer;
	}
`;

const SubMenu = ({ item }) => {
	const [subnav, setSubnav] = useState(false);
	const showSubMenu = () => setSubnav(!subnav);
	const notaState = useContext(notaContext);
	const {setDetalle} = notaState;

	return (
		<Fragment>
			{item.path === '/' ? (
				<SidebarLinkHome href='https://excel.network'>
					<div>
						{item.icon}
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
				</SidebarLinkHome>
			) : (
				<SidebarLink href={item.path} onClick={item.subNav && showSubMenu}>
					<div>
						{item.icon}
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
					<div>
						{item.subNav && subnav
							? item.iconOpen
							: item.subNav
							? item.iconClosed
							: null}
					</div>
				</SidebarLink>
			)}
			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<DropdownLink to={item.path} key={index} onClick={() => setDetalle(false)}>
							<SidebarLabel >{item.title}</SidebarLabel>
						</DropdownLink>
					);
				})}
		</Fragment>
	);
};

export default SubMenu;

