/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars:0 */
import {Button, Grid, TextField, InputAdornment, IconButton, Badge, Tooltip, Chip} from "@material-ui/core";
import {useHistory, useParams} from 'react-router-dom';
import FormGroup from "@material-ui/core";
import {
    deleteDisability,
    getDisabilityDetail,
    updateDisabilityDates
} from "../../../data_services/disabilities/disability_service";
import React, {useContext, useEffect, useState} from "react";
import authContext, {has_perm} from "../../../context/auth/authContext";
import {Close, History, Save, AttachFile} from "@material-ui/icons";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "../../../../node_modules/@material-ui/core/Dialog/Dialog";
import DialogTitle from "../../../../node_modules/@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "../../../../node_modules/@material-ui/core/DialogContent/DialogContent";
import DisabilityTrackHistoryTable from "./DisabilityTrackHistoryTable";
import DisabilityFileForm from "./DisabilityFileForm";
import PhysicianSearch from "../../common/PhysicianSearch";
import DiagnosisSearch from "../../common/DiagnosisSearch";

const useStyles = makeStyles((theme) => ({
    footer: {
        width: "auto",
        fontSize: '1.2em',
        padding: "9px",
        borderTop: "solid 1px #ccbcbc",
        marginTop: '12px',
        textAlign: 'right'
    }
})
)

const DisabilityTrackDetail = () => {
  //USUARIO
  const authState = useContext(authContext);
  let {user, setUser } = authState;
    const history = useHistory();
    const params = useParams();
    let action = params.action
    const classes = useStyles()
    let id = params.id
    const [disability, setDisability] = useState({})
    const [diagnosis, setDiagnosis] = useState('')
    const [viewHistory, setViewHistory] = useState(false)
    const [datesData, setDatesData] = useState({
        coordinator_date: undefined,
        physician_deliver: undefined,
        physician_received: undefined,
        admin_date: undefined,
        notify_date: undefined,
        deliver_date: undefined,
        disability_start: undefined,
        disability_end: undefined,
    })
    const [datesDisabled, setDatesDisabled] = useState({
        coordinator_date: true,
        physician_deliver: true,
        physician_received: true,
        admin_date: true,
        notify_date: true,
        deliver_date: true,
        disability_start: true,
        disability_end: true,
    })
    const [notesData, setNotesData] = useState({
        coordinator_notes: undefined,
        physician_notes: undefined,
        archive_notes: undefined,
        member_note: undefined,
    })
    const handleCancel = () => {
        history.push('/disabilities/tracking/history/')
    }
    const handleConfirm = async () => {
        const response = await deleteDisability(id)
        if(response.status === 200)
            toast.info('Se ha eliminado el registro con exito!!!')
        history.push('/disabilities/tracking/history/')
    }

    const handleDate = ({target:input}) => {
        let dates = {...datesData}
        let disabled = {...datesDisabled}
        let _disability = {...disability}
        let field = input.name
        if (dates[field] !== input.value){
            dates[input.name] = input.value
            _disability[input.name] = input.value
            disabled[field] = false
            setDatesData(dates)
            setDatesDisabled(disabled)
            setDisability(_disability)
        }        
    }

    const handleNote = ({target:input}) => {
        let notes = {...notesData}
        let _disability = {...disability}
        notes[input.name] = input.value
        _disability[input.name] = input.value
        setNotesData(notes)
        setDisability(_disability)
    }

    const handleSave = async (field) => {
        let new_data = {...notesData, ...datesData}
        if(field == 'Medico que Incapacita'){
            new_data = {responsible_physician: disability.responsible_physician}
        }
        if(field == 'Diagnostico'){
            new_data = {diagnosis_code: disability.diagnosis_code}
        }
        const response = await updateDisabilityDates(disability.id, new_data)
        if(response.status === 200){
            toast.success('Se ha actualizado el campo con exito!!!')
            setDatesDisabled({
                coordinator_date: true,
                physician_deliver: true,
                physician_received: true,
                admin_date: true,
                notify_date: true,
                deliver_date: true,
                disability_start: false,
                disability_end: true,
            })
        }
        else
            toast.error(response.data)
        await populateDisability()
    }


    const populateDisability = async () => {
        try {
            const disabilityId = parseInt(id)
            const {data: disabilityDetail} = await getDisabilityDetail(disabilityId)
            disabilityDetail.history = disabilityDetail.history.map(item =>{ return {...item, id: item.history_id}})
            disabilityDetail.responsible_physician = ''
            setDisability(disabilityDetail)
            setDatesData({
                coordinator_date: disabilityDetail.coordinator_date,
                physician_deliver: disabilityDetail.physician_deliver,
                physician_received: disabilityDetail.physician_received,
                admin_date: disabilityDetail.admin_date,
                notify_date: disabilityDetail.notify_date,
                deliver_date: disabilityDetail.deliver_date,
                disability_start: disabilityDetail.disability_start,
                disability_end: disabilityDetail.disability_end,
            })
            setNotesData({
                coordinator_notes: disabilityDetail.coordinator_notes,
                physician_notes: disabilityDetail.physician_notes,
                archive_notes: disabilityDetail.archive_notes,
                member_note: disabilityDetail.member_note,
            })
        }
        catch (ex){
            if (ex.response && ex.response.status === 404)
            history.replace("/not-found")
        }
    }
    useEffect(() => {
        populateDisability()
    },[])

const SaveButton = ({field, disabled}) => {
    return (
        <InputAdornment position="end">
            <IconButton color="primary" disabled={disabled}
                        aria-label={`Guardar ${field === 'Observaciones' || field === 'Medico que Incapacita' ? field:`fecha de ${field}`}`}
                        onClick={() => handleSave(field)}
            >
                <Save/>
            </IconButton>
        </InputAdornment>
    )
}

const setPhysician = (physician) =>{
  if(physician !== null){
      let _data = {...disability}
      _data['responsible_physician'] = physician.id
      _data['responsible_physician_name'] = ''
      setDisability(_data)
  }
}

const addDiagnosis = (diagnosis) =>{
  if(diagnosis){
    console.log(diagnosis, 'here')
      let _data = {...disability}
      _data['diagnosis_code'] = diagnosis
      _data['diagnosis_code_name'] = ''
      setDisability(_data)
  }
}
    return (
        <div className="container__content-body">
            <Grid container spacing={3}>
                <Grid item xs={1}>
                    <Button variant="contained" color="default" onClick={handleCancel}>
                        Regresar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <h1>Detalle del seguimiento</h1>
                </Grid>
                <Grid item xs={4}>
                    <b>Fecha</b><br/>
                    <div className="form-control">{disability.date_started}</div>
                </Grid>
                <Grid item xs={4}>
                    <b>Nombre del Miembro</b><br/>
                    <div className="form-control">{disability.patient_name}</div>
                </Grid>
                <Grid item xs={4}>
                    <b>Credencial</b><br/>
                    <div className="form-control">{disability.member_id}</div>
                </Grid>
                <Grid item xs={4}>
                    <b>Tipo de Formato</b><br/>
                    <div className="form-control">{disability.format_type}</div>
                </Grid>
                <Grid item xs={4}>
                    <b>Médico Tratante</b><br/>
                    <div className="form-control">{disability.physician_name}</div>
                </Grid>
                <Grid item xs={4}>
                    <b>Coordinador que Recibe</b><br/>
                    <div className="form-control">{disability.coordinator_name}</div>
                </Grid>
            </Grid>

            {action === 'delete' && (<div>
                    <h1>Seguro que deseas borrar este registro?</h1>
                    <Grid container spacing={0}>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary" onClick={handleConfirm}>
                                Continuar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}
            {action === 'detail' && disability.date_difference !== undefined && (
                <div>
                    <h3 className="title">Historial
                        <IconButton variant="outlined" color="primary" title="Ver Historial de Cambios"
                                onClick={() => setViewHistory(true)} size='small'>
                           <History />
                        </IconButton>
                    </h3>
            <h3 className="department">Departamento de Servicio al Miembro</h3>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <b>Fecha de Entrega a Coordinador</b><br/>
                    <TextField
                        label="" type="date" name="coordinator_date"
                        className="form-control" disabled={!has_perm(user, 'disabilities.member_services_field_edit')}
                        onChange={handleDate} value={disability.coordinator_date === null ? '':disability.coordinator_date}
                        InputProps={{
                            endAdornment: (datesData.coordinator_date !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="coordinador" disabled={datesDisabled.coordinator_date} />),
                            inputProps: {
                                min: disability.date_started
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en entregar</b><br/>
                    <div className="form-control">
                        {disability.date_difference.coordinador === 0 ? '-':disability.date_difference.coordinador}
                        </div>
                </Grid>
                <Grid item xs={4}>
                    <b>Observaciones</b><br/>
                    <TextField
                    multiline
                    name="coordinator_notes"
                    label=""
                    value={disability.coordinator_notes === null ? '':disability.coordinator_notes }
                    minRows={6}
                    onChange={handleNote}
                    disabled={!has_perm(user, 'disabilities.member_services_field_edit')}
                    InputProps={{
                        endAdornment: (disability.coordinator_notes !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="Observaciones" />)
                    }}
                    />
                </Grid>
            </Grid>
            <h3 className="department">Departamento de Consulta Externa</h3>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <b>Fecha de entrega a Médico Tratante</b><br/>
                    <TextField
                        label="" type="date" name="physician_deliver"
                        className="form-control" disabled={!has_perm(user, 'disabilities.external_consultation_field_edit') || disability.coordinator_date === null}
                        onChange={handleDate} value={disability.physician_deliver === null ? '':disability.physician_deliver}
                        InputProps={{
                            endAdornment: (datesData.physician_deliver !== null && has_perm(user, 'disabilities.external_consultation_field_edit')) && (<SaveButton field="entrega" disabled={datesDisabled.physician_deliver} />),
                            inputProps: {
                                min: datesData.coordinator_date
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en entregar</b><br/>
                    <div className="form-control">
                        {disability.date_difference.physician_deliver === 0 ? '-':disability.date_difference.physician_deliver}
                        </div>
                </Grid>
                <Grid item xs={4}>
                    <b>Observaciones</b><br/>
                    <TextField
                    multiline
                    name="physician_notes"
                    label=""
                    value={disability.physician_notes === null ? '':disability.physician_notes }
                    minRows={6}
                    onChange={handleNote}
                    disabled={!has_perm(user, 'disabilities.external_consultation_field_edit')}
                    InputProps={{
                        endAdornment: (disability.physician_notes !== null && has_perm(user, 'disabilities.external_consultation_field_edit')) && (<SaveButton field="Observaciones" />)
                    }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Fecha de recepción por Médico Tratante</b><br/>
                    <TextField
                        label="" type="date" name="physician_received"
                        className="form-control" disabled={disability.physician_deliver === null || !has_perm(user, 'disabilities.physicians_field_edit')}
                        onChange={handleDate} value={disability.physician_received === null ? '':disability.physician_received}
                        InputProps={{
                            endAdornment: (datesData.physician_received !== null && has_perm(user, 'disabilities.physicians_field_edit')) && (<SaveButton field="recepcion" disabled={datesDisabled.physician_received} />),
                            inputProps: {
                                min: datesData.physician_deliver
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en recibir</b><br/>
                    <div className="form-control">
                        {disability.date_difference.physician_received === 0 ? '-':disability.date_difference.physician_received}
                        </div>
                </Grid>
            </Grid>
            <h3 className="department">Departamento de Archivo</h3>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <b>Fecha de Entrega a Archivo</b><br/>
                    <TextField
                        label="" type="date" name="admin_date"
                        className="form-control" disabled={disability.physician_received === null || !has_perm(user, 'disabilities.clinical_archive_field_edit')}
                        onChange={handleDate} value={disability.admin_date === null ? '':disability.admin_date}
                        InputProps={{
                            endAdornment: (datesData.admin_date !== null && has_perm(user, 'disabilities.clinical_archive_field_edit')) && (<SaveButton field="archivo" disabled={datesDisabled.admin_date} />),
                            inputProps: {
                                min: datesData.physician_received
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en entregar</b><br/>
                    <div className="form-control">
                        {disability.date_difference.archivo === 0 ? '-':disability.date_difference.archivo}
                        </div>
                </Grid>
                <Grid item xs={3}>
                    <b>Observaciones</b><br/>
                    <TextField
                    multiline
                    name="archive_notes"
                    label=""
                    value={disability.archive_notes === null ? '':disability.archive_notes }
                    minRows={6}
                    onChange={handleNote}
                    disabled={!has_perm(user, 'disabilities.clinical_archive_field_edit')}
                    InputProps={{
                        endAdornment: (disability.archive_notes !== null && has_perm(user, 'disabilities.clinical_archive_field_edit')) && (<SaveButton field="Observaciones" />)
                    }}
                    />
                </Grid>
                {disability.id && has_perm(user, 'disabilities.clinical_archive_field_edit') && (
                    <Grid item xs={4}>
                        <b>Cargar Archivo de Incapacidad</b>
                        <a href={disability.attachment_url} target="_blank">
                            <Tooltip title="Ver Archivos"> 
                                <Badge badgeContent={disability.attachment_count} color="primary">
                                    <AttachFile color="action" />
                                </Badge>
                            </Tooltip>
                        </a>
                        <br />
                        <DisabilityFileForm disability_id={disability.id} onSave={populateDisability}></DisabilityFileForm>
                    </Grid>
                )}
                
            </Grid>
            <h3 className="department">Departamento de Servicio al Miembro</h3>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <b>Fecha de Aviso a miembro</b><br/>
                    <TextField
                        label="" type="date" name="notify_date"
                        className="form-control" disabled={disability.admin_date === null || !has_perm(user, 'disabilities.member_services_field_edit')}
                        onChange={handleDate} value={disability.notify_date === null ? '':disability.notify_date}
                        InputProps={{
                            endAdornment: (datesData.notify_date !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="aviso" disabled={datesDisabled.notify_date} />),
                            inputProps: {
                                min: datesData.admin_date
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en avisar</b><br/>
                    <div className="form-control">
                        {disability.date_difference.aviso === 0 ? '-':disability.date_difference.aviso}
                        </div>
                </Grid>
                <Grid item xs={2}>
                    <b>Fecha de Entrega a Miembro</b><br/>
                    <TextField
                        label="" type="date" name="deliver_date"
                        className="form-control" disabled={disability.notify_date === null || !has_perm(user, 'disabilities.member_services_field_edit')}
                        onChange={handleDate} value={disability.deliver_date === null ? '':disability.deliver_date}
                        InputProps={{
                            endAdornment: (datesData.deliver_date !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="entrega" disabled={datesDisabled.deliver_date} />),
                            inputProps: {
                                min: datesData.notify_date
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Días que tardo en entregar</b><br/>
                    <div className="form-control">
                        {disability.date_difference.entrega === 0 ? '-':disability.date_difference.entrega}
                        </div>
                </Grid>
                <Grid item xs={4}>
                    <b>Observaciones</b><br/>
                    <TextField
                    multiline
                    name="member_note"
                    label=""
                    value={disability.member_note === null ? '':disability.member_note }
                    minRows={6}
                    onChange={handleNote}
                    disabled={!has_perm(user, 'disabilities.member_services_field_edit')}
                    InputProps={{
                        endAdornment: (disability.member_note !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="Observaciones" />)
                    }}
                    />
                </Grid>
            </Grid>
{/* New section for follow up */}

<h3 className="department">Datos de Seguimiento</h3>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <b>Día de inicio</b><br/>
                    <TextField
                        label="" type="date" name="disability_start"
                        className="form-control" disabled={!has_perm(user, 'disabilities.member_services_field_edit')}
                        onChange={handleDate} value={disability.disability_start === null ? '':disability.disability_start}
                        InputProps={{
                            endAdornment: (datesData.disability_start !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="aviso" disabled={datesDisabled.disability_start} />),
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Día de terminación</b><br/>
                    <TextField
                        label="" type="date" name="disability_end"
                        className="form-control" disabled={disability.disability_start === null || !has_perm(user, 'disabilities.member_services_field_edit')}
                        onChange={handleDate} value={disability.disability_end === null ? '':disability.disability_end}
                        InputProps={{
                            endAdornment: (datesData.disability_end !== null && has_perm(user, 'disabilities.member_services_field_edit')) && (<SaveButton field="entrega" disabled={datesDisabled.disability_end} />),
                            inputProps: {
                                min: datesData.disability_start
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <b>Tiempo de Incapacidad</b><br/>
                    <div className="form-control">
                        {disability.date_difference.disability === 0 ? '-':disability.date_difference.disability}
                        </div>
                </Grid>
                <Grid item xs={3} style={{position: 'relative'}}>
                        <PhysicianSearch label={'Medico Que incapacita'}  onChangePhysician={setPhysician}
                                     search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                     empty_label={'No se encontraron resultados'}
                                     initial_text={ disability.responsible_physician === '' ? disability.responsible_physician_name:'' }
                        />
                        <div style={{position: 'absolute', right: '0.3em', top: '3em'}}>
                        {(disability.responsible_physician !== null && has_perm(user, 'disabilities.member_services_field_edit')) && <SaveButton field="Medico que Incapacita" disabled={disability.responsible_physician === ''} />}
                        </div>
                </Grid>
                <Grid item xs={3} style={{position: 'relative'}}>
                    <DiagnosisSearch label={'Diagnostico ICD-10'}  onChangeDiagnosis={addDiagnosis}
                                    search_label={'Escribir al menos 3 letras y presionar ENTER para buscar'}
                                    empty_label={'No se encontraron resultados'}
                                    initial={disability.diagnosis_code}
                                    initial_text={ disability.diagnosis_code === '' ? disability.diagnosis_code.map(item => {return `${item.code} ${item.description.substr(0,10)}`}):'' }
                    />
                    <div style={{position: 'absolute', right: '0.3em', top: '3em'}}>
                    {(disability.diagnosis_code !== null && has_perm(user, 'disabilities.member_services_field_edit')) && <SaveButton field="Diagnostico" disabled={disability.diagnosis_code.length === 0} />}
                    </div>
                </Grid>
            </Grid>

            </div>
            )}
            <div className={classes.footer}>
                <b>Usuario que reporta: </b> {disability.saved_name}
            </div>
            <Dialog fullWidth maxWidth="xl" open={viewHistory} onClose={()=>setViewHistory(false)}
                    aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">Historial de Cambios
                    <Close onClick={()=>setViewHistory(false)} />
                </DialogTitle>
                <DialogContent style={{height: '450px'}}>
                    <DisabilityTrackHistoryTable history={disability.history} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default DisabilityTrackDetail;