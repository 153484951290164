/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {downloadDisabilities, getDisabilities} from '../../../data_services/disabilities/disability_service';
import {DataGrid} from '@mui/x-data-grid';
import {CloudDownload, Delete, Edit, Search} from "@material-ui/icons";
import {Button, ButtonGroup, Grid, TextField} from "@material-ui/core";
import IconButton from "../../../../node_modules/@material-ui/core/IconButton/IconButton";

let DisabilityTrackTable = ({user}) => {
    let columns = [
        {
            field: 'id',
            headerName: 'Folio',
            renderCell: instance => <Link
                to={`/disabilities/tracking/${instance.row.id}/detail`}>{instance.row.id}</Link>,
            flex: 1
        },
        {
            field: 'date_started',
            headerName: 'Fecha',
            flex: 1
        },
        {
            field: 'patient_name',
            headerName: 'Miembro',
            flex: 2
        },
        {
            field: 'member_id',
            headerName: 'ID',
            flex: 1
        },
        {
            field: 'format_type',
            headerName: 'Tipo de formato',
            flex: 1.5
        },
        {
            field: 'physician_name',
            headerName: 'Medico tratante',
            flex: 2
        },
        {
            field: 'coordinator_name',
            headerName: 'Coordinador que recibe',
            flex: 2
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 2
        },
        {
            field: '',
            headerName: 'Acciones',
            renderCell: instance => parseInt(instance.row.saved_by) === parseInt(user) && <span>
                <Link to={`/disabilities/tracking/${instance.row.id}`}><IconButton size="small"
                    color="primary"><Edit/></IconButton></Link>
                <Link to={`/disabilities/tracking/${instance.row.id}/delete`}>
                <IconButton size="small" color="secondary"><Delete/></IconButton>
            </Link>
            </span>,
            flex: 1
        },
    ];
    const [loadingTable, setLoading] = useState(false)
    const [data, setData] = useState([{id: 1}]);
    const [filters, setFilters] = useState({
        startDate: sessionStorage.getItem("startDate") !== null ? sessionStorage.getItem("startDate"):new Date().toISOString().substr(0, 10),
        endDate: sessionStorage.getItem("endDate") !== null ? sessionStorage.getItem("endDate"):new Date().toISOString().substr(0, 10),
        patient: sessionStorage.getItem("patient") !== null ? sessionStorage.getItem("patient"):''
    })

    async function fetchData() {
        setLoading(true)
        const {data: disablities} = await getDisabilities(filters);
        setData(disablities);
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])
    const handleChange = ({target: input}) => {
        const data = {...filters};
        data[input.name] = input.value;
        setFilters(data);
        sessionStorage.setItem(input.name, input.value)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <TextField id="startDate" label="Fecha Inicial" type="date" name="startDate"
                               value={filters.startDate}
                               className="form-control" onChange={handleChange}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField id="endDate" label="Fecha Final" type="date" name="endDate" value={filters.endDate}
                               className="form-control" onChange={handleChange}
                               inputProps={{
                                   min: filters.startDate
                               }}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField id="patient" label="Nombre del Paciente" name="patient" value={filters.patient}
                               className="form-control" onChange={handleChange}
                    />
                </Grid>
                <Grid item md={3}>
                    <ButtonGroup variant="contained" color="primary" aria-label="Filtrar o Descargar">
                        <Button onClick={fetchData}><Search/> &nbsp;Filtrar</Button>
                        <Button onClick={() => downloadDisabilities(filters)}><CloudDownload/> &nbsp;Descargar</Button>
                    </ButtonGroup>
                </Grid>
            </Grid><br />
            <DataGrid rows={data} columns={columns} pageSize={10} getRowId={(row) => row.id}
                      rowsPerPageOptions={[10, 25, 50, 100]} loading={loadingTable}/>

        </>
    )
};

export default DisabilityTrackTable;