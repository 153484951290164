import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "../../common/Input";
import Select from "../../common/Select";
import axiosClient from "../../../config/axios";

const Title = styled.h1`
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto
  width: 100%;
`;

const Col12 = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;


const CreatePermission = () => {
  const [department, setDepartment] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosClient.get("/tracking/departamentos/");
      setDepartment(result.data);
    };
    fetchData();
  }, []);

  const categories = [
    { id: "1", name: "Incapacidad" },
    { id: "2", name: "Muerte de familiar" },
    { id: "3", name: "Académico" },
    { id: "4", name: "Paternidad" },
    { id: "5", name: "Maternidad" },
    { id: "6", name: "Cumpleaños" },
    { id: "7", name: "Vacaciones" },
    { id: "8", name: "Personal" },
    { id: "9", name: "Urgencia" },
    { id: "10", name: "Otro" },
  ];

  const handleChange = (e) => {
  };

  return (
    <div className="container__content-body">
      <Title>Crear Permiso Medico</Title>
      <form>
        <Row>
          <Col12>
            <Input
              autoFocus={true}
              name="username"
              label="Elaboro"
              placeholder="Nombre de quien elaboro"
              type="text"
            />
          </Col12>
          <Col12>
            <Input
              autoFocus={false}
              name="created"
              label="Fecha"
              type="datetime-local"
            />
          </Col12>
          <Col12>
            <Input
              autoFocus={false}
              name="folio"
              label="Folio de Permiso"
              type="text"
            />
          </Col12>
          <Col12>
            <Select
              name="department"
              label="Departamento"
              options={department}
              onChange={handleChange}
            />
          </Col12>
          <Col12>
            <Select
              name="category"
              label="Categoria"
              options={categories}
              onChange={handleChange}
            />
          </Col12>
        </Row>
      </form>
    </div>
  );
};

export default CreatePermission;
