import React, { useState, useEffect, useContext } from "react";
import Select from "../../../common/Select";
import Checkbox from "../../../common/Checkbox";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const FrecueciaConsumoIndividual = ({ product, addProducts }) => {
  const notaState = useContext(notaContext);
  const { nota_detalle } = notaState;

  const [frecuecia_consumo, guardarFrecueciaConsumo] = useState({
    product_name: product.product_name || product.diet_product_equivalents,
    id: product.id,
    never_or_almost_never: product.never_or_almost_never,
    up_to_date: product.up_to_date,
    a_week: product.a_week,
    a_month: product.a_month,
  });

  const handleChange = (e) => {
    guardarFrecueciaConsumo({
      ...frecuecia_consumo,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangecheckbox = (e) => {
    guardarFrecueciaConsumo({
      ...frecuecia_consumo,
      [e.target.name]: e.target.checked,
    });
    if (e.target.checked === true) {
      clear();
    }
  };

  const clear = () => {
    guardarFrecueciaConsumo({
      ...frecuecia_consumo,
      name: product.product_name,
      never_or_almost_never: true,
      up_to_date: 0,
      a_week: 0,
      a_month: 0,
    });
  };

  useEffect(() => {
    addProducts(frecuecia_consumo);
  }, [frecuecia_consumo]);

  const al_mes = [
    {
      id: 0,
      name: "-",
    },
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
      id: 3,
      name: 3,
    },
  ];

  const al_semana = [
    {
      id: 0,
      name: "-",
    },
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
      id: 3,
      name: 3,
    },
    {
      id: 4,
      name: 4,
    },
    {
      id: 5,
      name: 5,
    },
    {
      id: 6,
      name: 6,
    },
    {
      id: 7,
      name: 7,
    },
  ];

  const al_dia = [
    {
      id: 0,
      name: "-",
    },
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
      id: 3,
      name: 3,
    },
    {
      id: 4,
      name: 4,
    },
    {
      id: 5,
      name: 5,
    },
  ];


  let { never_or_almost_never, up_to_date, a_week, a_month, product_name } =
    frecuecia_consumo;

    
  return (
    <div className="frecuecia-consumo-individual">
      <div>
        <div>
          <label>{product_name}</label>
          <Checkbox
            type="checkbox"
            name="never_or_almost_never"
            onChange={handleChangecheckbox}
            value={never_or_almost_never}
            id="mi-select option"
            checked={never_or_almost_never ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
          <Select
            type="select"
            name="up_to_date"
            onChange={handleChange}
            value={up_to_date}
            options={al_dia}
            disabled={
              frecuecia_consumo.never_or_almost_never || nota_detalle
                ? true
                : false
            }
            id="mi-select option"
          />
          <Select
            type="select"
            name="a_week"
            onChange={handleChange}
            value={a_week}
            options={al_semana}
            disabled={
              frecuecia_consumo.never_or_almost_never || nota_detalle
                ? true
                : false
            }
            id="mi-select"
          />
          <Select
            type="select"
            name="a_month"
            onChange={handleChange}
            value={a_month}
            options={al_mes}
            disabled={
              frecuecia_consumo.never_or_almost_never || nota_detalle
                ? true
                : false
            }
            id="mi-select"
          />
        </div>
      </div>
    </div>
  );
};

export default FrecueciaConsumoIndividual;
