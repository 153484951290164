import React, { useContext, useEffect, useState } from "react";
import NotaInicial from "./NotaInicial";
import Diagnostico from "./Diagnostico";
import MotivoConsulta from "./MotivoConsulta";
import Antropometricos from "./Antropometricos";
import IndicadoresBioquimicos from "./IndicadoresBioquimicos";
import IndicadoresClinicos from "./IndicadoresClinicos";
import AntecedentesHeredoFamiliares from "./AntecedentesHeredoFamiliares";
import IndicadoresDieteticos from "./IndicadoresDieteticos";
import EstiloVida from "./EstiloVida";
import DiagnosticoNutricional from "./DiagnosticoNutricional";
import PlanNutricionalNotas from "./PlanNutricionalNota";
import PlanTratamiento from "./PlanTratamiento";
//import PlanNutricional from "./PlanNutricional";
//import PlanNutricional2 from "./PlanNutricional2";
//import PlanNutricional3 from "./PlanNutricional3";
//import PlanNutricional4 from "./PlanNutricional4";
import DatosDieteticos from "./DatosDieteticos";
import "../../../../styles/notasnutricion.css";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import axiosClient from "../../../../config/axios.js";
import { FaSpinner } from "react-icons/fa";

/* ************************ */
import pdfContext from "../../../../context/pdf/pdfContext";
import NotaNutricionalPdf from "./NotaNutricionPdf";
import { useHistory, Link } from "react-router-dom";
/* ************************ */

function Index() {
  /* ************************ */
  const pdfState = useContext(pdfContext);
  const { setPDFData } = pdfState;
  const history = useHistory();
  /* ************************ */

  const notaState = useContext(notaContext);
  const {
    nota_inicial,
    nota_diagnostico,
    nota_recordatorio24,
    nota_dieta_habitual,
    nota_frecuencia_consumo,
    nota_datos_dieteticos,
    nota_frecuencia_consumo_observaciones,
    nota_diagnostico_nutricional,
    nota_plan_nutricional,
    nota_plan_nutricional_parte2,
    nota_nutricional_formulas,
    nota_plan_nutricional_nota,
    nota_plan_tratamiento,
    nota_plan_nutricional_parte3,
    nota_plan_nutricional_parte4,
    nota_motivo_consulta,
    nota_antropometricos,
    nota_indicadores_bioquimicos,
    nota_indicadores_clinicos,
    nota_antecedentes_familiares,
    nota_estilo_vida,
    nota_indicadores_dieteticos,
    nota_detalle,
    setDetalle,
    setNotaNutricionalError,
    nota_nutricional_error,
    setLoadDetalleNota,
    resetData,
    fetch_data,
    cie_result
  } = notaState;

  const extra_data = JSON.parse(localStorage.getItem('extra_data'))
  const patient_id = Object.values(extra_data)[0]
  const appointment_id = Object.values(extra_data)[1]
  const physician_id = Object.values(extra_data)[2]

  const porcentaje = (tipo) => {
    let porcentaje = 0;
    nota_plan_nutricional_parte2.forEach((item) => {
      if (item.product === tipo) {
        porcentaje = item.porcentaje_vet;
      }
    });
    return porcentaje;
  };

  const Post = async () => {
    let patient = null;
    let appointmentSchedule = null;
    let physician = null;
    let dietary_data = null;
    let frecuncy_of_24hrs = null;
    let nutritional_plan = null;

    let data_nota_recordatorio24
    let data_nota_dieta_habitual

    // Recordatorio 24
    if (nota_datos_dieteticos.recordatorio24hrs) {
      data_nota_recordatorio24 = { ...nota_recordatorio24 };
      data_nota_recordatorio24.features = data_nota_recordatorio24.features.map(
        (item) => {
          return item.value;
        }
      );
      data_nota_recordatorio24.diet_type = 0;      
    }

    // Dieta habitual

    if (nota_datos_dieteticos.dieta_habitual) {
      data_nota_dieta_habitual = { ...nota_dieta_habitual };
      data_nota_dieta_habitual.features = data_nota_dieta_habitual.features.map(
        
        (item) => {
          return item.value;
        }
      );

      data_nota_dieta_habitual.diet_type = 1;
    }
    let data_frecuencia_consumo_24hrs
    let data_frecuencia_consumo_24hrs_ids = [];
    //frecuencia de cosumo 24 hrs
    if (nota_datos_dieteticos.frecuencia_consumo) {
      console.log('enter')
      data_frecuencia_consumo_24hrs = [...nota_frecuencia_consumo];
      data_frecuencia_consumo_24hrs.forEach((item) => {
        item.observations = nota_frecuencia_consumo_observaciones;
        item.diet_product = item.id;
        if (!item.never_or_almost_never) {
          item.never_or_almost_never=false;
        }
      });
    }

    /* //Plan nutricional 1 y 2

    let activity = {
      1.0: 0,
      1.1: 1,
      1.2: 2,
      1.3: 3,
      1.4: 4,
      1.5: 5,
      1.6: 6,
    };

    let formula_options = {
      requerimientoMifflinstJeor: 0,
      harrisBennedict: 1,
      pediatricaInstituteMedicine: 2,
      formulaPredictiva1: 3,
      formulaPredictiva2: 4,
      formulaPredictiva3: 5,
      formulaPredictiva4: 6,
    };

    let data_datos_plannutrucional = {
      gender: nota_plan_nutricional.gender === "femenino" ? 0 : 1,
      formula: formula_options[nota_plan_nutricional.formula],
      physical_activity: activity[nota_plan_nutricional.physical_activity],
      other_kcal:
        nota_plan_nutricional.requirement_type === "kcal_otro"
          ? nota_plan_nutricional.requirement_other
          : null,
      percentage_protein: porcentaje("Proteina"),
      percentage_hydratescr: porcentaje("Hidratos de carbono"),
      percentage_lipids: porcentaje("Lipidos"),
    };

    await axiosClient
      .post("api/nutritional_plan/", data_datos_plannutrucional)
      .then((response) => {
        nutritional_plan = response.data.id;
      })
      .catch((err) => {
        console.log(err);
      }); */

    /* //plan nutricional 3
    let data_nota_plan_nutricional_ids = [];
    await nota_plan_nutricional_parte3.forEach((item) => {
      if (item.amount) {
        let data_nutrcional_plan3 = {
        amount: null,
        equivalents: null,
      };
      data_nutrcional_plan3.amount = item.amount;
      data_nutrcional_plan3.equivalents = item.index;
      
      axiosClient
        .post("api/nutritional_plan_food/", data_nutrcional_plan3)
        .then((response) => {
          data_nota_plan_nutricional_ids.push(response.data.id);
        })
        .catch((err) => {
          console.log(err);
        });
      }
      
    }); */

    /* //plan nutricional 4
    let data_plan_nutricional4_ids = [];
    await nota_plan_nutricional_parte4.forEach((item) => {
      let data_plan_nutricional4 = {
        equivalents_food: item.equivalents,
        amount: item.amount,
        breakfast: item.breakfast,
        morning_snack: item.morning_snack,
        food: item.food,
        evening_snack: item.evening_snack,
        dinner: item.dinner,
        night_collation: item.night_collation,
      };

      axiosClient
        .post("api/nutritional_plan_quantity/", data_plan_nutricional4)
        .then((response) => {
          data_plan_nutricional4_ids.push(response.data.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }); */
    
    const cie_ids = cie_result.map(
      (item) => {
        return item.id;
      }
    );

    let nota_data = {};
    await Object.assign(
      nota_data,
      nota_inicial,
      nota_diagnostico,
      nota_motivo_consulta,
      nota_antropometricos,
      nota_indicadores_bioquimicos,
      nota_indicadores_clinicos,
      nota_antecedentes_familiares,
      nota_estilo_vida,
      nota_indicadores_dieteticos,
      nota_diagnostico_nutricional,
      nota_plan_nutricional,
      nota_plan_nutricional_parte2
      
    );
    nota_data.nutritional_plan_note_comments = nota_plan_nutricional_nota;
    nota_data.treatment_plan = nota_plan_tratamiento;
    nota_data.activity = nota_estilo_vida.activity;
    nota_data.patient = patient_id;
    nota_data.physician = physician_id;
    nota_data.appointmentSchedule = appointment_id;
    nota_data.dietary_data = dietary_data;
    nota_data.frecuncy_of_24hrs = frecuncy_of_24hrs;
    //nota_data.nutritional_plan = nutritional_plan;
    nota_data.diagnostic = String(nota_diagnostico.observations);

    nota_data.cie = cie_ids;
    nota_data.frequency_food_consumption = data_frecuencia_consumo_24hrs_ids;
    //nota_data.nutritional_plan_food = data_nota_plan_nutricional_ids;
    //nota_data.nutritional_plan_quantity = data_plan_nutricional4_ids;
    if (nota_data.hasOwnProperty('observations'))
      delete nota_data['observations']
    if (nota_data.hasOwnProperty('person_id'))
      delete nota_data['person_id']
    if (nota_data.hasOwnProperty('physician_id'))
      delete nota_data['physician_id']
    if (nota_data.hasOwnProperty('date'))
      delete nota_data['date']
    if (nota_data.hasOwnProperty('age'))
      delete nota_data['age']
    if (nota_data.hasOwnProperty('birth_date'))
      delete nota_data['birth_date']

    let todo = {'nota_data': nota_data, 'data_nota_recordatorio24': data_nota_recordatorio24, 'data_nota_dieta_habitual': data_nota_dieta_habitual,'data_frecuencia_consumo_24hrs': data_frecuencia_consumo_24hrs}  

    /* Sending a POST request to the server to save the data. */
    setTimeout(() => {
      axiosClient
        .post("/save/nota/nutricion/", todo)
        .then((response) => {
          let base_url = null;
          switch (document.location.host) {
            case 'react.excel.network':
              base_url = 'https://excel.network';
              break;
            case 'rsb1.excel.network':
              base_url = 'http://sandbox.excel.network';
              break;
            case 'rsb2.excel.network':
              base_url = 'http://sb2.excel.network';
              break;
            case 'localhost:3001':
              base_url = 'http://localhost:8001';
              break;
            default:
              base_url = 'http://localhost:8000';
              break;
          }
          if(response.data.success == true){
            window.location.href = `${base_url}/plan_de_alta/consulta_externa/${patient_id}/${appointment_id}`
          }
          else{
            alert('No se pudo guardar la nota, Valide que no incluya caracteres especiales')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);
    // setPDFData({title:'Notas Nutriologia', content:<NotaNutricionalPdf titulo='hola'/>})
    // history.push('/nota-nutriologia/pdf')
  };

  const [error, setError] = useState(true);

  /* Checking if there is an error in the form. */
  useEffect(() => {
    setError(false);
    Object.keys(nota_nutricional_error).forEach((e) => {
      if (nota_nutricional_error[e]) {
        setError(true);
      }
    });
  }, [nota_nutricional_error]);

  useEffect(() => {
    const nota = localStorage.getItem("nota");
    const current_url = window.location.pathname;

    if (current_url === "/nota-nutriologia/crear") {
      localStorage.removeItem("nota");
    }
    if (current_url === "/nota-nutriologia/detalle" && !nota) {
      history.push("/nota-nutriologia");
      resetData();
    }

    // const nota = localStorage.getItem("nota");
    // const current_url = window.location.pathname;
    if (current_url === "/nota-nutriologia/detalle" && nota) {
      setLoadDetalleNota(nota);
    }
  }, []);

  
  if (fetch_data){
    return (
      <p>Cargando <FaSpinner/></p>
      
    )
  }
  return (
    <div className="container__content-body">
      <NotaInicial />
      <Diagnostico />
      <MotivoConsulta />
      <Antropometricos />
      <IndicadoresBioquimicos />
      <IndicadoresClinicos />
      <AntecedentesHeredoFamiliares />
      <EstiloVida />
      <IndicadoresDieteticos />
      <DatosDieteticos />
      <DiagnosticoNutricional />
      <PlanNutricionalNotas/>
      {/*<PlanTratamiento/>*/}
      {/* <PlanNutricional /> */}
      {/* <PlanNutricional2 /> */}
      {/* <PlanNutricional3 /> */}
      {/* <PlanNutricional4 /> */}
      {!nota_detalle && (
        <button disabled={error} className="nota-button" onClick={Post}>
          Guardar
        </button>
      )}
    </div>
  );
}

export default Index;
