export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const SET_BREADCUMBS = "SET_BREADCUMBS";

export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const SUCCESSFULL_LOGIN = "SUCCESSFULL_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_EXTRADATA = "SET_EXTRADATA";

//TEMPORAL
export const UPDATE_USER = "UPDATE_USER";
export const SET_NOTA_DATOS_DIETETICOS = " SET_NOTA_DATOS_DIETETICOS";
export const GET_NOTA_DATOS_DIETETICOS = " GET_NOTA_DATOS_DIETETICOS";
export const SET_NOTA_INICIAL = "SET_NOTA_INICIAL";
export const GET_NOTA_INICIAL = "GET_NOTA_INICIAL";
export const SET_NOTA_DIAGNOSTICO = "SET_NOTA_DIAGNOSTICO";
export const GET_NOTA_DIAGNOSTICO = "GET_NOTA_DIAGNOSTICO";
export const SET_NOTA_MOTIVO_CONSULTA = "SET_NOTA_MOTIVO_CONSULTA";
export const GET_NOTA_MOTIVO_CONSULTA = "GET_NOTA_MOTIVO_CONSULTA";
export const SET_NOTA_ANTROPOMETRICOS = "SET_NOTA_ANTROPOMETRICOS";
export const GET_NOTA_ANTROPOMETRICOS = "GET_NOTA_ANTROPOMETRICOS";
export const SET_NOTA_INDICADORES_BIOQUIMICOS =
  "SET_NOTA_INDICADORES_BIOQUIMICOS";
export const GET_NOTA_INDICADORES_BIOQUIMICOS =
  "GET_NOTA_INDICADORES_BIOQUIMICOS";
export const SET_NOTA_PLAN_NUTRICIONAL3_DETALLE =
  "SET_NOTA_PLAN_NUTRICIONAL3_DETALLE";
export const SET_NOTA_PLAN_NUTRICIONAL4_DETALLE =
  "SET_NOTA_PLAN_NUTRICIONAL4_DETALLE";
export const SET_NOTA_PLAN_NUTRICIONAL_DETALLE =
  "SET_NOTA_PLAN_NUTRICIONAL_DETALLE";
export const SET_NOTA_RECORDATORIO_24_DETALLE =
  "SET_NOTA_RECORDATORIO_24_DETALLE";
export const SET_NOTA_PLAN_NUTRICIONAL2_DETALLE =
  "SET_NOTA_PLAN_NUTRICIONAL2_DETALLE";

export const SET_NOTA_INDICADORES_CLINICOS = "SET_NOTA_INDICADORES_CLINICOS";
export const SET_NOTA_ANTECEDENTES_FAMILIARES =
  "SET_NOTA_ANTECEDENTES_FAMILIARES";
export const SET_NOTA_ESTILO_VIDA = "SET_NOTA_ESTILO_VIDA";
export const SET_NOTA_INDICADORES_DIETETICOS =
  "SET_NOTA_INDICADORES_DIETETICOS";
export const SET_NOTA_RECORDATORIO_24 = "SET_NOTA_RECORDATORIO_24";
export const SET_NOTA_DIETA_HABITUAL = "SET_NOTA_DIETA_HABITUAL";
export const SET_NOTA_FRECUENCIA_CONSUMO = "SET_NOTA_FRECUENCIA_CONSUMO";
export const SET_NOTA_DIAGNOSTICO_NUTRICIONAL =
  "SET_NOTA_DIAGNOSTICO_NUTRICIONAL";
export const SET_NOTA_PLAN_NUTRICIONAL_NOTA = "SET_NOTA_PLAN_NUTRICIONAL_NOTA";
export const SET_NOTA_PLAN_TRATAMIENTO = "SET_NOTA_PLAN_TRATAMIENTO";
export const SET_NOTA_PLAN_NUTRICIONAL = "SET_NOTA_PLAN_NUTRICIONAL";
export const SET_NOTA_PLAN_NUTRICIONAL2 = "SET_NOTA_PLAN_NUTRICIONAL2";
export const SET_NOTA_PLAN_NUTRICIONAL3 = "SET_NOTA_PLAN_NUTRICIONAL3";
export const SET_NOTA_PLAN_NUTRICIONAL4 = "SET_NOTA_PLAN_NUTRICIONAL4";
export const SET_FORMULAS = "SET_FORMULAS";
export const SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES =
  "SET_NOTA_FRECUENCIA_CONSUMO_OBSERVACIONES";
export const SET_ID_NOTA = "SET_ID_NOTA";
export const SET_DETALLE_NOTA = "SET_DETALLE_NOTA";
export const SET_NOTA_DIETA_HABITUAL_DETALLE =
  "SET_NOTA_DIETA_HABITUAL_DETALLE";
export const SET_NOTA_FRECUENCIA_CONSUMO_DETALLE =
  "SET_NOTA_FRECUENCIA_CONSUMO_DETALLE";
export const SET_NOTA_ERROR = " SET_NOTA_ERROR";
export const SET_NOTA_PLAN_NUTRICIONAL_FORMULAS =
  "SET_NOTA_PLAN_NUTRICIONAL_FORMULAS";

export const GET_NOTA_INDICADORES_CLINICOS = "GET_NOTA_INDICADORES_CLINICOS";
export const GET_NOTA_ANTECEDENTES_FAMILIARES =
  "GET_NOTA_ANTECEDENTES_FAMILIARES";
export const GET_NOTA_ESTILO_VIDA = "GET_NOTA_ESTILO_VIDA";
export const GET_NOTA_INDICADORES_DIETETICOS =
  "GET_NOTA_INDICADORES_DIETETICOS";
export const GET_NOTA_RECORDATORIO_24 = "GET_NOTA_RECORDATORIO_24";
export const GET_NOTA_DIETA_HABITUAL = "GET_NOTA_DIETA_HABITUAL";
export const GET_NOTA_FRECUENCIA_CONSUMO = "GET_NOTA_FRECUENCIA_CONSUMO";
export const GET_NOTA_DIAGNOSTICO_NUTRICIONAL =
  "GET_NOTA_DIAGNOSTICO_NUTRICIONAL";
export const SET_NOTA_DIAGONOSTICO_CIE = "SET_NOTA_DIAGONOSTICO_CIE";
export const GET_NOTA_PLAN_NUTRICIONAL = "GET_NOTA_PLAN_NUTRICIONAL";
export const GET_NOTA_PLAN_NUTRICIONAL2 = "GET_NOTA_PLAN_NUTRICIONAL2";
export const GET_NOTA_PLAN_NUTRICIONAL3 = "GET_NOTA_PLAN_NUTRICIONAL3";
export const GET_NOTA_PLAN_NUTRICIONAL4 = "GET_NOTA_PLAN_NUTRICIONAL4";
export const GET_FORMULAS = "GET_FORMULAS";
export const GET_ID_NOTA = "GET_ID_NOTA";

export const GET_NOTA_PLAN_NUTRICIONAL_DETALLE =
  "GET_NOTA_PLAN_NUTRICIONAL_DETALLE";
export const GET_NOTA_RECORDATORIO_24_DETALLE =
  "GET_NOTA_RECORDATORIO_24_DETALLE";
export const GET_NOTA_DIETA_HABITUAL_DETALLE =
  "GET_NOTA_DIETA_HABITUAL_DETALLE";
export const GET_NOTA_FRECUENCIA_CONSUMO_DETALLE =
  "GET_NOTA_FRECUENCIA_CONSUMO_DETALLE";
export const GET_NOTA_ERROR = "GET_NOTA_ERROR";
export const GET_NOTA_PLAN_NUTRICIONAL2_DETALLE =
  "GET_NOTA_PLAN_NUTRICIONAL2_DETALLE";

export const SET_PDF = "SET_PDF";

export const SET_FETCH_DATA = "SET_FETCH_DATA";

// user creation
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_FORM_DATA_ERROR = "SET_FORM_DATA_ERROR";
export const SET_POST_STATUS = "SET_POST_STATUS";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_PASSWORD_DATA = "SET_PASSWORD_DATA";
export const SET_PASSWORD_ERRORS = "SET_PASSWORD_ERRORS";
export const SET_PATCH_STATUS = "SET_PATCH_STATUS";
export const SET_PDF_DATA = "SET_PDF_DATA";
export const SET_USER_LIST_PAGES = "SET_USER_LIST_PAGES";
