import { useState, useEffect } from "react";
import axiosClient from "../config/axios";

const useFetch = () => {
  const [data, setData] = useState({
    slug: "",
    results: [],
  });

  useEffect(() => {
    if (data.slug !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            const res = await axiosClient.get(
              `/cie10/api/cie_10/?search=${data.slug}`
            );
            setData({ ...data, results: res.data });
            return res.data;
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [data.slug]);

  return { data, setData };
};

export default useFetch;
