import React, { Fragment, useState, useEffect, useContext } from "react";
import axiosClient from "../../../../config/axios.js";
import Input from "../../../common/Input";
import notaContext from "../../../../context/notas_nutriologia/notaContext";
import authContext from "../../../../context/auth/authContext";

const NotaInicial = () => {
  const notaState = useContext(notaContext);
  const { nota_detalle, nota_inicial, setNotaInicial } = notaState;

  const authState = useContext(authContext);
  const { user, extra_data } = authState;

  const [physician_data, setPhysicianData] = useState({
    physician: "",
    physician_id: "",
  });

  const [patient_data, setPatientData] = useState({
    patient: "",
    person_id: "",
    age: "",
    birth_date: "",
  });

  const [date, setDate] = useState(new Date().toLocaleDateString());
  const data_patient = JSON.parse(localStorage.getItem('extra_data'))
  const patient_id = Object.values(data_patient)[0]

  const consultarApiPaciente = async () => {
    if (!data_patient || !patient_id) return null;
    const api_paciente = await axiosClient.get(
      `/patients/api/patients/${patient_id}/`
    );
    const tmp_data = await api_paciente.data;
    console.log('tmp',tmp_data);

    setPatientData({
      ...patient_data,
      patient: tmp_data.person.person,
      person_id: tmp_data.id,
      age: tmp_data.person.age,
      birth_date: tmp_data.person.birth_date,
    });
  };

  const consultarApiMedico = async () => {
    //const api = await axiosClient.get(`/physicians/api/physician/${user.user.id}/`);
    //const tmp_data = await api.data;
    setPhysicianData({
      ...physician_data,
      physician: user.name.toUpperCase(),
      physician_id: user.id, //FALTA PHYSICIAN ID
    });

    //await setMedico(api.data);
  };

  //cargar una frase
  useEffect(() => {
    consultarApiMedico();
    consultarApiPaciente();
  }, []);

  useEffect(() => {
    const data = Object.assign(patient_data, physician_data);
    setNotaInicial(data);
  }, [physician_data, patient_data]);


  const { physician } = physician_data;
  const { patient, age, birth_date } = patient_data;

  return (
    <div className="nota-inicial">
      <h1>Nota Nutricional</h1>
      <div>
        <div>
          <Input
            type="text"
            name="physician"
            placeholder="nombre medico"
            value={physician}
            label="Medico"
            disabled={nota_detalle ? true : false}
            readOnly
          />

          <Input
            type="text"
            name="created_at"
            value={date}
            label="Fecha"
            disabled={nota_detalle ? true : false}
            readOnly
          />
        </div>
        <div>
          <Input
            type="text"
            name="patient"
            value={patient}
            label="Nombre Paciente"
            disabled={nota_detalle ? true : false}
            readOnly
          />

          <Input
            type="text"
            name="age"
            value={age}
            label="Edad"
            disabled={nota_detalle ? true : false}
            readOnly
          />

          <Input
            type="date"
            name="birth_date"
            value={birth_date}
            label="Fecha de nacimiento"
            disabled={nota_detalle ? true : false}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default NotaInicial;
