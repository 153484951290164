import React, { useReducer } from 'react';
import authContext from './authContext.jsx';
import authReducer from './authReducer.jsx';
import {
    GET_USER,
    ERROR_LOGIN,
    LOGOUT,
    SET_USER,
    SET_EXTRADATA
} from '../../types';

//import axios from 'axios';
import axiosClient from '../../config/axios.js';
import tokenAuth from '../../config/tokenAuth.js';

const AuthProvider = props => {

    const initialState = {
        token: localStorage.getItem('token'),
        authenticated:null,
        user:null,
        msg:null,
        loading:true,
        extra_data:{}
    }

    const [state, dispatch] = useReducer(authReducer, initialState);


    const getUser = async () => {
        const token = localStorage.getItem('token');
        //if (token){
        tokenAuth(token);
        //}

        await axiosClient.get('/api/auth').then( res => {
            dispatch({
                type: GET_USER,
                payload: res.data.user
            })
        }).catch( err => {
            console.log(err)
            dispatch({
                type:ERROR_LOGIN
            })
        })
    }

    
    const userLogin = async data => {
        await axiosClient.post('/gateway/users/auth/login/', data).then( res => {
            console.log(res)
            /* dispatch({
                type: SUCCESSFULL_LOGIN,
                payload: res.data
            })
            getUser(); */
        }).catch (err => {
            console.log(err)
            /* const alert = {
                msg: err.response.data.msg,
                category: 'alerta-error'
            }
            dispatch({
                type: ERROR_LOGIN,
                payload: alert
            }) */
        })
    }

    const userLogout = async () => {
        dispatch({
            type:LOGOUT
        })
    }

    const setUser = async data => {
        await dispatch({
            type: SET_USER,
            payload: data
        })
       
    }

    const setExtraData = async data => {
        await dispatch({
            type: SET_EXTRADATA,
            payload: data
        })
       
    }

    return (
        <authContext.Provider
            value={{
                token:state.token,
                authenticated:state.authenticated,
                user:state.user,
                msg:state.msg,
                loading:state.loading,
                extra_data:state.extra_data,
                userLogin,
                getUser,
                userLogout,
                setUser,
                setExtraData
            }}
        >
            {props.children}
        </authContext.Provider>
    )
}

export default AuthProvider;