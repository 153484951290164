import React, { useState } from "react";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};





const useSelect = () => {
 

    const [show, setShow] = useState(false);

    const [innerValue, setinnerValue] = useState([]);

    const handleChange = (e) => {
        const {
        target: { value },
        } = e;
        setinnerValue(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

 

    const innerSelect = ({ name, className, label, options, error, ...rest }) => {
    error ? setShow(true) : setShow(false);
    const getText = (value) => {
        const selected = options.filter((option) => option.id === value);
        return selected.map((option) => option.name).join(', ');
    }
    return(
      <div className={`${className} form-group`}>
        <label htmlFor={name}>{label}</label>
        <Select
            name={name}
            id={name}
            {...rest}
            className="form-control"
            multiple
            value={innerValue}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box >
                {selected.map((value) => (
                    <Chip size="small" color="primary" key={value} label={getText(value)} />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
          {options.map((option, index) => (
            <MenuItem
                
              key={index}
              value={option.id}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {show && (
            <div className="alert" style={{ marginTop: "5px" }}>
                {error}
            </div>
        )}
    </div>
  )};

  return [innerValue, innerSelect];
}

export default useSelect;
