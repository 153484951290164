import React, { useEffect, useState } from 'react';

import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "../../../node_modules/@material-ui/core/InputLabel/InputLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
        fontSize: 13,
        padding: "9px",
        border: "solid 1px #ccbcbc",
        borderRadius: "6px",
        '&:hover,&:focus': {
            borderColor: '#1f1c1c',
        },
    }
}));

export const ControlledSelect = ({ name, value, options, label, disabled=false, onFocus, onChange, onBlur }) => {
    const [localValue, setLocalValue] = useState(value ?? '');  // we want to keep value locally
    useEffect(() => setLocalValue(value ?? ''), [value]);       // we want to update local value on prop value change
    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setLocalValue(value);
        if (onChange) {
            onChange(e);
        }
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e.target.value);
        }
    };
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <InputLabel>{label}</InputLabel>
            <Select disabled={disabled}
                name={name}
                value={localValue}      // we want to work in controlled mode
                onFocus={handleFocus}
                onChange={handleChange} // we want to work in controlled mode
                onBlur={handleBlur}
                style={{width: '100%'}}
            >
                {options?.map((item) => {
                    return (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label ?? item.value}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export default ControlledSelect;