import React, { Fragment } from 'react';
import styled from "styled-components";
import { device }from '../common/ScreenSizes'
import { Link } from "react-router-dom";

export const Item = styled.div`
    height:100%;
    border:1px solid #ccc;
    display:flex;
    justify-content:center;
    align-items:center;
    width:200px;
    &:hover{
        cursor:pointer;
        border:1px solid #777;

        & > div {
            display:inline;
        }
    }
`;

export const Dropdown = styled.div`
    display: none;
    position: fixed;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top:120px;

    
`;

export const DropdownLink = styled(Link)`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    
    &:hover {
        background-color: #ddd;
    }

`;

export const Icon = styled.span`
    display:inline;
    ${device.min_laptop}{
        display:none;
    }
`;

export const Text = styled.span`
    display:none;
    ${device.min_laptop}{
        display:inline;
    }
`;

const MenuItem = ({item}) => {

    return ( 
        <Fragment>
            <Item>
                <Icon>
                    {item.icon}
                </Icon>
                <Text>
                    {item.title}
                </Text>
                
                <Dropdown>
                    {
                        item.subNav.map((link, index) => {
                            return <DropdownLink key={index} to={link.path}>{link.title}</DropdownLink>
                        })
                    }
                 
                </Dropdown>
                
            </Item>
           
        </Fragment>
        
     );
}
 
export default MenuItem;