/**
 * Modal component for displaying a modal.
 * @param title: Title of the modal.
 * @param content: Content of the modal.
 * @param actions: Actions of the modal.
 * @param post_finallized: Function to be called after the modal is closed.
 * @param size: Size of the modal ('xs', 'sm', 'md, 'lg', 'xl).
 * @param show_save: Show the save button.
 * @param ref: Reference to the modal.
 * returns: React component.
 */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const Modal = forwardRef(
  (
    { title, content, action, post_finallized, size = "sm", show_save = true },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      /**
       * sets the state of the open variable to true
       * true
       */
      handleClickOpen() {
        setOpen(true);
      },
      /**
       * sets the state of the open variable to false
       */
      handleClickClose() {
        setOpen(false);
      },
    }));

    /**
     A hook that is called after post_finallized is changed to determine if the modal should be open.
    */
    useEffect(() => {
      if (post_finallized) {
        setOpen(false);
      }
    }, [post_finallized]);

    return (
      <>
        <Dialog open={open} className="dialog" maxWidth={size}>
          <DialogTitle id="responsive-dialog-title" className="dialog-title">
            {title}
          </DialogTitle>
          <DialogContent className="dialog-content">{content}</DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            {show_save && <Button onClick={action}>Guardar</Button>}
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default Modal;
