import axiosClient from './axios.js';

const tokenAuth = token => {
    if (token){
        axiosClient.defaults.headers.common['Authorization'] = `Token ${token}`;
        //axiosClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    }else{
        delete axiosClient.defaults.headers.common['Authorization'];
    }
}



export default tokenAuth;