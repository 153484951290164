import React, { useState, useEffect, useContext } from "react";
import Checkbox from "../../../common/Checkbox";
import notaContext from "../../../../context/notas_nutriologia/notaContext";

const AntecedentesHeredoFamiliares = () => {
  const notaState = useContext(notaContext);
  const {
    nota_detalle,
    nota_antecedentes_familiares,
    setNotaAntecedentesFamiliares,
  } = notaState;

  const handleChangecheckbox = (e) => {
    setNotaAntecedentesFamiliares({
      ...nota_antecedentes_familiares,
      [e.target.name]: e.target.checked,
    });
  };

  let {
    obesity,
    diabetes,
    aht,
    cancer,
    hypercolesterolemia,
    hypertriglyceridaemia,
    cardiopatia_isquemica,
  } = nota_antecedentes_familiares;

  return (
    <div className="antecedentes-heredo-familiares">
      <h1>ANTECEDENTES HEREDO FAMILIARES </h1>
      <div>
        <div>
          <Checkbox
            type="checkbox"
            name="obesity"
            onChange={handleChangecheckbox}
            value={obesity}
            label="Obesidad"
            checked={obesity ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
          <Checkbox
            type="checkbox"
            name="diabetes"
            onChange={handleChangecheckbox}
            value={diabetes}
            label="Diabetes"
            checked={diabetes ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="aht"
            onChange={handleChangecheckbox}
            value={aht}
            label="HTA"
            checked={aht ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="cancer"
            onChange={handleChangecheckbox}
            value={cancer}
            label="Cáncer"
            checked={cancer ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
        </div>

        <div>
          <Checkbox
            type="checkbox"
            name="hypercolesterolemia"
            onChange={handleChangecheckbox}
            value={hypercolesterolemia}
            label="Hipercolesterolemia"
            checked={hypercolesterolemia ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="hypertriglyceridaemia"
            onChange={handleChangecheckbox}
            value={hypertriglyceridaemia}
            label="Hipertrigliceridemia"
            checked={hypertriglyceridaemia ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />

          <Checkbox
            type="checkbox"
            name="cardiopatia_isquemica"
            onChange={handleChangecheckbox}
            value={cardiopatia_isquemica}
            label="Cardiopatía Isquémica "
            checked={cardiopatia_isquemica ? "checked" : ""}
            disabled={nota_detalle ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default AntecedentesHeredoFamiliares;
